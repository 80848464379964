import { Icon, Link, LinkProps } from '@chakra-ui/react'
import { ExternalLinkIcon } from 'lucide-react'
import { PropsWithChildren } from 'react'

type ExternalLinkProps = PropsWithChildren<Omit<LinkProps, 'isExternal'>>

export function ExternalLink({ children, ...props }: ExternalLinkProps) {
  return (
    <Link {...props} isExternal>
      {children}
      <Icon
        as={ExternalLinkIcon}
        w={3}
        h={3}
        ml={0.5}
        display="inline-block"
        data-testid="external-link-icon"
      />
    </Link>
  )
}
