<template>
  <v-snackbar
    v-for="item in snackbarStore.items"
    :key="item.id"
    v-model="item.visible"
    @update:model-value="item.updateHandler"
    :transition="item.transition"
    :timeout="item.timeout"
    v-bind="item.attrs"
    :color="item.type.color"
    elevation="24"
    app=""
    class="mb-8"
  >
    {{ item.message }}

    <template #actions>
      <v-btn
        v-if="item.dismissible"
        icon="mdi-close"
        @click="item.visible = false"
      />
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { useSnackbarStore } from '@/store/snackbarStore'

export default defineComponent({
  name: 'SnackbarNotifications',
  setup() {
    const snackbarStore = useSnackbarStore()
    return { snackbarStore }
  },
})
</script>
