import { computed, reactive, Ref, toRefs } from 'vue'
import { useRoute } from 'vue-router'

import { CustomRouteMeta } from '@/@types/routeMeta.types'
import { DeepPartial } from '@/@types/utils.types'

export function usePageSettings() {
  const { meta } = toRefs(useRoute())
  const routeMeta = meta as Ref<DeepPartial<CustomRouteMeta>>

  const hasNavigationDrawer = computed(() => {
    return !routeMeta.value.settings?.hideNavigationDrawer
  })

  return reactive({
    hasNavigationDrawer,
  })
}
