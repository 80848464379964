export const errorCodes = {
  USER_ALREADY_EXISTS: {
    code: 'USER_ALREADY_EXISTS',
    description: 'This username already exists.',
  },
  CUSTOM_URL_ALREADY_EXISTS: {
    code: 'CUSTOM_URL_ALREADY_EXISTS',
    description: 'This custom url already exists.',
  },
  CUSTOM_URL_ALREADY_CHANGED_WITHIN_24_HOURS: {
    code: 'CUSTOM_URL_ALREADY_CHANGED_WITHIN_24_HOURS',
    description:
      'Your custom cluster url was changed less than 24 hours ago. You can only change your URL once every 24 hours. Please try again later',
  },
  ACCOUNT_NOT_FOUND: {
    code: 'ACCOUNT_NOT_FOUND',
    description: 'Account not found.',
  },
  CLUSTER_NOT_FOUND: {
    code: 'CLUSTER_NOT_FOUND',
    description: 'Cluster not found.',
  },
  CLUSTER_TYPE_NOT_FOUND: {
    code: 'CLUSTER_TYPE_NOT_FOUND',
    description: 'Cluster type not found.',
  },
  DELETE_CUSTOM_URL_FAILED: {
    code: 'DELETE_CUSTOM_URL_FAILED',
    description: 'Delete custom url failed.',
  },
}

export function getErrorCode(errorCode) {
  let $errorCode = errorCodes[`${errorCode}`]
  if ($errorCode) {
    return $errorCode.description
  }
  return 'undefined'
}

export const stripeErrorCodes = {
  FORM_VALIDATION_ERROR: {
    phone: 'The phone number you entered is invalid.',
  },
}

export function getStripeErrorCode(stripeException) {
  let data = stripeException?.response?.data?.errors?.[0]

  if (!data) {
    return
  }

  let errorCode = data.code
  let errorType = data.title
  let $errorCode = this.stripeErrorCodes[`${errorCode}`]
  if ($errorCode) {
    let errorTypes = Object.keys($errorCode)
    let errorValues = Object.values($errorCode)
    for (let i = 0; i < errorTypes.length; i++) {
      if (errorTypes[`${i}`] === errorType) {
        return errorValues[`${i}`]
      }
    }
  }
}
