import {
  OnboardingEvents,
  useOnboardingEvents,
} from '@app/hooks/useOnboardingEvents'
import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed, nextTick, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import { useClusterStore } from '@/store/clusterStore'

import { ClusterPackageConfigType } from '@/utils/packages'

import { ClusterState } from '../@types/consoleApi.types'
import { sleep } from '../tests/utils/timers'

import routeNames from '@/router/routeNames'

export enum OnboardingStoreEvents {
  SELECT_STARTER_PLAN = 'SELECT_STARTER_PLAN',
  VIEW_ALL_PLANS = 'VIEW_ALL_PLANS',
  CONTINUE_TO_STRIPE = 'CONTINUE_TO_STRIPE',
  GENERATE_CREDENTIALS = 'GENERATE_CREDENTIALS',
  ADD_CREDENTIALS = 'ADD_CREDENTIALS',
  USE_WEBCLIENT = 'USE_WEBCLIENT',
  USE_REAL_DEVICE = 'USE_REAL_DEVICE',
  PUBLISH_TEST_MESSAGE = 'PUBLISH_TEST_MESSAGE',
  MANAGE_CLUSTER = 'MANAGE_CLUSTER',
  BACK_TO_CLUSTER_LIST = 'BACK_TO_CLUSTER_LIST',
  GO_TO_ACCESS_MANAGEMENT = 'GO_TO_ACCESS_MANAGEMENT',
}

type OnboardingSessions = {
  currentStep: number
  currentSuccessfulSteps: number[]
  currentClusterCreateStep: number
}

type ClusterTarget = string | 'new'

const LAST_STEP = 4

export const NEW_ONBOARDING_TARGET_NAME: ClusterTarget = 'new'

export const useOnboardingStore = defineStore(`onboardingStore`, () => {
  const router = useRouter()
  const { publish, subscribe } = useOnboardingEvents()
  const clusterStore = useClusterStore()

  const onboardingState = useLocalStorage('hivemq-cloud:onboarding', {
    isOpen: false,
    clusterTargetName: NEW_ONBOARDING_TARGET_NAME,
    sessions: {} as Record<ClusterTarget, OnboardingSessions>,
  })

  const credentials = ref({
    username: '',
    password: '',
  })

  watch(
    () => onboardingState.value.clusterTargetName,
    () => {
      credentials.value = {
        username: '',
        password: '',
      }
    }
  )

  subscribe(
    OnboardingEvents.CREDENTIALS_ADDED,
    (data: { username: string; password: string }) => {
      credentials.value = {
        ...data,
      }
    }
  )

  const clusterSession = computed<OnboardingSessions>(() => {
    if (
      !onboardingState.value.clusterTargetName ||
      !onboardingState.value.sessions[onboardingState.value.clusterTargetName]
    ) {
      return {
        currentStep: 0,
        currentSuccessfulSteps: [],
        currentClusterCreateStep: 0,
      }
    }

    return onboardingState.value.sessions[
      onboardingState.value.clusterTargetName
    ]
  })

  const isOpen = computed(() => onboardingState.value.isOpen)
  const clusterTargetName = computed(
    () => onboardingState.value.clusterTargetName
  )
  const clusterTarget = computed(() =>
    clusterStore.visibleClusters.find(
      (cluster) => cluster.name === clusterTargetName.value
    )
  )

  const isClusterTargetReady = computed(() => {
    if (!clusterTarget.value) {
      return false
    }

    return clusterTarget.value.state === ClusterState.DONE
  })

  const clusterTargetParameters = computed(() => ({
    clusterUuid: clusterTarget.value?.uuid,
    clusterType: clusterTarget.value?.type.toLowerCase(),
  }))

  async function onOpen(
    clusterTarget: ClusterTarget = NEW_ONBOARDING_TARGET_NAME
  ) {
    if (isOpen.value) {
      onClose()
      // eslint-disable-next-line no-magic-numbers
      await sleep(300)
    }

    if (!onboardingState.value.sessions[clusterTarget]) {
      onboardingState.value.sessions[clusterTarget] = {
        currentStep: 0,
        currentSuccessfulSteps: [],
        currentClusterCreateStep: 0,
      }
    }

    onboardingState.value.clusterTargetName = clusterTarget

    await nextTick()

    onboardingState.value.isOpen = true
  }

  function onClose() {
    onboardingState.value.isOpen = false
    return
  }

  function setStep(step: number) {
    clusterSession.value.currentStep = step

    if (step === 0) {
      clusterSession.value.currentClusterCreateStep = 0
    }

    publish(OnboardingEvents.__STEP_CHANGE)
  }

  /**
   * Moves step if it has not yet been defined,
   * for example if the functionality was not available before the cluster got created
   */
  function setStepIfNotDefined(step: number) {
    if (clusterSession.value.currentStep < step) {
      setSuccessfulStep(0)
      setStep(step)
    }
  }

  function setSuccessfulStep(step: number) {
    if (!clusterSession.value.currentSuccessfulSteps.includes(step)) {
      clusterSession.value.currentSuccessfulSteps.push(step)
    }
  }

  function isSuccessfulStep(step: number) {
    return clusterSession.value.currentSuccessfulSteps.includes(step)
  }

  /**
   * Interacts with the reactivity, only useful for the cluster creation
   * step before sending the customer to the stripe checkout page
   * This will allow the localStorage to store the scoped cluster once the customer comes back
   * @param clusterTarget
   *
   * @example
   *
   * onboardingStore.__dangerouslySetNewClusterTarget(YOUR_NAME)
   * await sleep(0) // await the next event loop
   * // your next implementation
   */
  function __dangerouslySetNewClusterTarget(clusterTarget: ClusterTarget) {
    onboardingState.value.clusterTargetName = clusterTarget

    onboardingState.value.sessions[clusterTarget] =
      onboardingState.value.sessions[NEW_ONBOARDING_TARGET_NAME]
  }

  function isFinished(clusterTargetName: ClusterTarget) {
    if (!onboardingState.value.sessions[clusterTargetName]) {
      return false
    }

    return (
      onboardingState.value.sessions[clusterTargetName].currentSuccessfulSteps
        .length === LAST_STEP
    )
  }

  function handleEvent(eventType: OnboardingStoreEvents) {
    switch (eventType) {
      case OnboardingStoreEvents.SELECT_STARTER_PLAN:
        router.push({
          name: routeNames.authenticated.clusters.createConfigure,
          params: {
            clusterType: ClusterPackageConfigType.STARTER,
          },
        })
        break

      case OnboardingStoreEvents.VIEW_ALL_PLANS:
        router.push({
          name: routeNames.authenticated.clusters.create,
        })
        break

      case OnboardingStoreEvents.CONTINUE_TO_STRIPE:
        if (
          router.currentRoute.value.name !==
          routeNames.authenticated.clusters.createConfigure
        ) {
          router.push({
            name: routeNames.authenticated.clusters.createConfigure,
            params: {
              clusterType: ClusterPackageConfigType.STARTER,
            },
          })

          return
        }

        publish(OnboardingEvents.CREATE_CLUSTER_CONFIGURATION_SUBMIT)
        break

      case OnboardingStoreEvents.GO_TO_ACCESS_MANAGEMENT:
        router.push({
          name: routeNames.authenticated.clusters.details.accessManagement,
          params: clusterTargetParameters.value,
        })
        break

      case OnboardingStoreEvents.GENERATE_CREDENTIALS:
        if (
          router.currentRoute.value.name !==
          routeNames.authenticated.clusters.details.accessManagement
        ) {
          router
            .push({
              name: routeNames.authenticated.clusters.details.accessManagement,
              params: clusterTargetParameters.value,
            })
            .then(() => publish(OnboardingEvents.GENERATE_ACCESS_CREDENTIALS))
        } else {
          publish(OnboardingEvents.GENERATE_ACCESS_CREDENTIALS)
        }

        break

      case OnboardingStoreEvents.ADD_CREDENTIALS:
        router
          .push({
            name: routeNames.authenticated.clusters.details.accessManagement,
            params: clusterTargetParameters.value,
          })
          .then(() => publish(OnboardingEvents.ADD_ACCESS_CREDENTIALS))

        break

      case OnboardingStoreEvents.USE_WEBCLIENT:
        router.push({
          name: routeNames.authenticated.clusters.details.webClient,
          params: clusterTargetParameters.value,
        })

        break

      case OnboardingStoreEvents.USE_REAL_DEVICE:
        break

      case OnboardingStoreEvents.PUBLISH_TEST_MESSAGE:
        publish(OnboardingEvents.PUBLISH_TEST_MESSAGE)
        break

      case OnboardingStoreEvents.MANAGE_CLUSTER:
        router.push({
          name: routeNames.authenticated.clusters.details.index,
          params: clusterTargetParameters.value,
        })
        onClose()

        break

      case OnboardingStoreEvents.BACK_TO_CLUSTER_LIST:
        router.push({
          name: routeNames.authenticated.clusters.index,
        })
        onClose()

        break
    }
  }

  watch(
    () => clusterTarget.value?.state!,
    (state) => {
      if (state === ClusterState.DONE) {
        publish(OnboardingEvents.CLUSTER_CREATION_SUCCESSFUL)
      }
    }
  )

  async function cleanUp() {
    if (clusterTarget.value) {
      return
    }

    // Here we end up in a state where the customer has stopped on the
    // Stripe checkout page. We need to set the target to the previous best
    // possible state. In our case it's cluster configuration

    onboardingState.value.clusterTargetName = NEW_ONBOARDING_TARGET_NAME
    await nextTick()
    clusterSession.value.currentStep = 0
    clusterSession.value.currentSuccessfulSteps = [0]
  }

  cleanUp()

  return {
    isOpen,
    onOpen,
    onClose,
    handleEvent,
    setStep,
    setSuccessfulStep,
    isSuccessfulStep,
    isFinished,
    clusterTargetName,
    clusterSession,
    setStepIfNotDefined,
    isClusterTargetReady,
    credentials,
    __dangerouslySetNewClusterTarget,
  }
})
