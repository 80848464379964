import { themeColorMap, ThemeColorName } from '@/@types/colors.types'

export type MessageConfig = {
  color: ThemeColorName
}

export type MessageTypes = 'success' | 'info' | 'warning' | 'error'

export const messageConfigs: Record<MessageTypes, MessageConfig> = {
  success: {
    color: themeColorMap.success,
  },
  info: {
    color: themeColorMap.secondary,
  },
  warning: {
    color: themeColorMap.warning,
  },
  error: {
    color: themeColorMap.error,
  },
} as const
