import { useLocalStorage } from '@vueuse/core'
import { merge } from 'lodash'
import { createRouter, createWebHistory } from 'vue-router'

import { useAccountStore } from '@/store/accountStore'
import { useClusterStore } from '@/store/clusterStore'
import { useFeatureFlagStore } from '@/store/featureFlagStore'
import { useNavigationStore } from '@/store/navigationStore'

import {
  UTMParameter,
  flushUTMParameters,
  getUTMParameter,
} from '@/utils/localStorage/utmStorage'
import { routerLogger } from '@/utils/logger/logger'
import { ClusterPackageConfigType } from '@/utils/packages'
import { trackPageView } from '@/utils/tracking/trackPageView'

import routeNames from './routeNames'

import { clusterFeatureList } from '@/modules/cluster/utils/featureList'
import { isAuth0UserAuthenticated, redirectToAuth0Login } from '@/plugins/auth0'

export const layouts = {
  default: 'default',
  dashboard: 'dashboard',
  landingPage: 'landingPage',
}

const EXTERNAL_BILLING_KEY = 'billing-external'

const clusterNavigation = (withTroubleShooting) => {
  const options = {
    items: [
      {
        title: 'Overview',
        to: { name: routeNames.authenticated.clusters.details.index },
      },
      {
        title: 'Access Management',
        to: {
          name: routeNames.authenticated.clusters.details.accessManagement,
        },
      },
      {
        title: 'Integrations',
        to: { name: routeNames.authenticated.clusters.details.integrations },
      },
      {
        title: 'Web Client',
        to: { name: routeNames.authenticated.clusters.details.webClient },
      },
      {
        title: 'Getting Started',
        to: { name: routeNames.authenticated.clusters.details.gettingStarted },
      },
      {
        title: 'API Access',
        to: { name: routeNames.authenticated.clusters.details.apiToken.index },
      },
    ],
  }

  if (withTroubleShooting) {
    options.items.push({
      title: 'Troubleshooting',
      to: { name: routeNames.authenticated.clusters.details.troubleshooting },
    })
  }

  return options
}

/*
 * Optional route meta properties
 * ---
 * title: string
 * |> Allows the developer to set the window.document.title property
 * ---
 * settings: Object
 * |> contains multiple configuration options to change the application design. E.g.: hide the navigation drawer
 * ---
 * layout: string | #FE_REF2
 * |> set the layout of the route. Current options: default, dashboard
 * ---
 * isPublicRoute: boolean
 */
const routes = () => {
  const featureFlagStore = useFeatureFlagStore()
  const withTroubleShooting = featureFlagStore.isTroubleShootingLogsEnabled

  const allRoutes = [
    {
      path: '/:lang(en-US)?', // extend this when adding new language support e.g. '/:lang(en | de)?'
      children: [
        {
          path: '/verify-email',
          name: routeNames.authenticated.verifyEmail,
          meta: {
            title: 'Verify email address',
            settings: {
              hideNavigationDrawer: true,
            },
            isPublicRoute: true,
            layout: layouts.landingPage,
          },
          component: () => import('../views/VerifyEmail.vue'),
        },
        {
          path: '/orgs',
          name: routeNames.authenticated.organisation.index,
          meta: {
            title: 'Organizations',
          },
          component: () => import('../views/Organizations.vue'),
          children: [
            {
              path: 'settings',
              name: routeNames.authenticated.organisation.settings,
              component: () =>
                import('../views/OrganizationSettingsWrapper.vue'),
            },
            {
              path: 'members',
              name: routeNames.authenticated.organisation.members,
              component: () =>
                import('../views/OrganizationMembersWrapper.vue'),
            },
          ],
        },
        {
          path: '/release',
          name: routeNames.authenticated.release,
          meta: {
            title: 'Release Notes',
          },
          component: () =>
            import('../../app/pages/ReleaseNotes/ReleaseNotes.tsx'),
        },
        {
          path: '/profile',
          name: routeNames.authenticated.profile.index,
          meta: { title: 'Profile' },
          component: () => import('../views/Profile.vue'),
        },
        {
          path: '/',
          alias: '/clusters',
          name: routeNames.authenticated.clusters.index,
          meta: { title: 'Your Clusters' },
          component: () => import('@app/pages/Clusters/Clusters'),
        },
        {
          path: '/api-token',
          name: routeNames.authenticated.apiToken.index,
          meta: { title: 'Create API Token' },
          component: () => import('../views/api-token/ApiTokenIndex.vue'),
        },
        {
          path: '/clusters/:clusterType/:clusterUuid/api-token',
          name: routeNames.authenticated.clusters.details.apiToken.index,
          meta: {
            title: 'Create API Token',
            navigation: { ...clusterNavigation(withTroubleShooting) },
          },
          component: () =>
            import('../views/cluster/api-token/ApiTokenIndex.vue'),
        },
        {
          path: '/clusters/:clusterType/:clusterUuid/api-token/create-new',
          name: routeNames.authenticated.clusters.details.apiToken.new,
          meta: {
            title: 'Create new API token',
            navigation: { ...clusterNavigation(withTroubleShooting) },
          },
          component: () => import('../views/cluster/api-token/ApiTokenNew.vue'),
        },
        {
          path: '/clusters/:clusterType/:clusterUuid/api-token/:keyName',
          name: routeNames.authenticated.clusters.details.apiToken.details,
          meta: {
            title: 'Update API token',
            navigation: { ...clusterNavigation(withTroubleShooting) },
          },
          component: () =>
            import('../views/cluster/api-token/ApiTokenDetails.vue'),
        },
        {
          path: '/clusters/:clusterType/:clusterUuid/access-management',
          alias: '/clusters/:clusterUuid/access-management',
          name: routeNames.authenticated.clusters.details.accessManagement,
          meta: {
            title: 'Access Management',
            navigation: { ...clusterNavigation(withTroubleShooting) },
          },
          component: () => import('../views/cluster/AccessManagement.vue'),
        },
        {
          path: '/clusters/:clusterType/:clusterUuid/integrations',
          alias: '/clusters/:clusterUuid/integrations',
          name: routeNames.authenticated.clusters.details.integrations,
          meta: {
            title: 'Integrations',
            navigation: { ...clusterNavigation(withTroubleShooting) },
          },
          component: () => import('../views/cluster/Integrations.vue'),
        },
        {
          path: '/clusters/:clusterType/:clusterUuid/integrations/kafka',
          alias: '/clusters/:clusterUuid/integrations/kafka',
          name: 'cluster-details-integrations-kafka-custom',
          meta: {
            title: 'Kafka Streaming Configuration',
            navigation: { ...clusterNavigation(withTroubleShooting) },
          },
          component: () =>
            import('../views/cluster/integrations/KafkaCustom.vue'),
        },
        {
          path: '/clusters/:clusterType/:clusterUuid/integrations/kafka-confluent',
          alias: '/clusters/:clusterUuid/integrations/kafka-confluent',
          name: routeNames.authenticated.clusters.integrations.kafka.confluent,
          meta: {
            title: 'Confluent Cloud Streaming Configuration',
            navigation: { ...clusterNavigation(withTroubleShooting) },
          },
          component: () => import('../views/cluster/integrations/Kafka.vue'),
        },
        {
          path: '/clusters/:clusterType/:clusterUuid/integrations/kafka-aiven',
          alias: '/clusters/:clusterUuid/integrations/kafka-aiven',
          name: 'cluster-details-integrations-kafka-aiven',
          meta: {
            title: 'Aiven Kafka Streaming Configuration',
            navigation: { ...clusterNavigation(withTroubleShooting) },
          },
          component: () =>
            import('../views/cluster/integrations/KafkaAiven.vue'),
        },
        {
          path: '/clusters/:clusterType/:clusterUuid/integrations/kafka-generic',
          alias: '/clusters/:clusterUuid/integrations/kafka-generic',
          name: 'cluster-details-integrations-kafka-generic',
          meta: {
            title: 'Kafka Integration Configuration',
            navigation: { ...clusterNavigation(withTroubleShooting) },
          },
          component: () =>
            import(
              '@app/pages/GenericKafkaIntegration/GenericKafkaIntegration.tsx'
            ),
        },
        {
          path: '/clusters/:clusterType/:clusterUuid/integrations/amazon-kinesis',
          name: routeNames.authenticated.clusters.integrations.amazonKinesis
            .default,
          meta: {
            title: 'Amazon Kinesis',
            navigation: { ...clusterNavigation(withTroubleShooting) },
          },
          component: () =>
            import('../views/cluster/integrations/AmazonKinesis.vue'),
        },
        {
          path: '/complete-profile',
          name: routeNames.authenticated.profiles.complete,
          meta: {
            title: 'Complete Profile',
            settings: {
              hideNavigationDrawer: true,
            },
          },
          component: () => import('../views/CompleteProfile.vue'),
        },
        {
          path: '/clusters/create',
          name: routeNames.authenticated.clusters.create,
          meta: {
            title: 'Create Cluster',
          },
          component: () => import('@app/pages/ClusterCreate/ClusterCreate'),
        },
        {
          path: '/clusters/create/:clusterType',
          name: routeNames.authenticated.clusters.createConfigure,
          meta: {
            title: 'Configure Cluster',
          },
          component: () =>
            import('@app/pages/ClusterCreateConfigure/ClusterCreateConfigure'),
        },
        {
          path: '/billingExternal',
          name: routeNames.authenticated.billingExternal,
          component: () => import('../views/payment/BillingExternal.vue'),
        },
        {
          path: '/help',
          name: routeNames.authenticated.help,
          meta: { title: 'Help' },
          component: () => import('@app/pages/Help/Help'),
        },
        {
          path: '/clusters/:clusterType/:clusterUuid',
          alias: '/clusters/:clusterUuid',
          name: routeNames.authenticated.clusters.details.index,
          meta: {
            title: 'Cluster Details',
            navigation: { ...clusterNavigation(withTroubleShooting) },
          },
          component: () => import('../views/cluster/Overview.vue'),
        },
        {
          path: '/clusters/:clusterType/:clusterUuid/custom-dns',
          alias: '/clusters/:clusterUuid',
          name: routeNames.authenticated.clusters.details.dns,
          meta: {
            title: 'Cluster Details',
            navigation: { ...clusterNavigation(withTroubleShooting) },
          },
          component: () => import('../views/CustomDomainWrapper.vue'),
        },
        {
          path: '/clusters/:clusterType/:clusterUuid/getting-started',
          alias: '/clusters/:clusterUuid/getting-started',
          name: routeNames.authenticated.clusters.details.gettingStarted,
          meta: {
            title: 'Getting Started',
            navigation: { ...clusterNavigation(withTroubleShooting) },
          },
          component: () => import('../views/cluster/GettingStarted.vue'),
        },
        {
          path: '/clusters/:clusterType/:clusterUuid/web-client',
          alias: '/clusters/:clusterUuid/web-client',
          name: routeNames.authenticated.clusters.details.webClient,
          meta: {
            title: 'Web Client',
            navigation: { ...clusterNavigation(withTroubleShooting) },
          },
          component: () => import('@app/pages/WebClient/WebClient'),
        },
        {
          path: '/clients/java-hivemq',
          name: routeNames.authenticated.clients.javaHivemq,
          meta: { title: 'Getting started with MQTT with Java' },
          component: () => import('../../app/pages/Guides/JavaGuide.tsx'),
        },
        {
          path: '/clients/mqtt-cli',
          name: routeNames.authenticated.clients.mqtt,
          meta: { title: 'Getting started with MQTT-CLI' },
          component: () => import('../../app/pages/Guides/MqttCLIGuide.tsx'),
        },
        {
          path: '/clients/mosquitto-cli',
          name: routeNames.authenticated.clients.mosquitto,
          meta: { title: 'Getting started with mosquitto CLI' },
          component: () => import('../../app/pages/Guides/MosquittoGuide.tsx'),
        },
        {
          path: '/clients/mqttfx',
          name: routeNames.authenticated.clients.mqttfx,
          meta: { title: 'Getting started with MQTT.fx' },
          component: () => import('../../app/pages/Guides/MqttFXGuide.tsx'),
        },
        {
          path: '/clients/mqtt-js',
          name: routeNames.authenticated.clients.mqttJs,
          meta: { title: 'Getting started with MQTT.js' },
          component: () => import('../../app/pages/Guides/MqttJsGuide.tsx'),
        },
        {
          path: '/clients/python-paho',
          name: routeNames.authenticated.clients.pythonPaho,
          meta: { title: 'Getting started with Paho Python' },
          component: () => import('../../app/pages/Guides/PahoPythonGuide.tsx'),
        },
        {
          path: '/clients/websocket-web',
          name: routeNames.authenticated.clients.websocketWeb,
          meta: {
            title: 'Getting started with HiveMQ Websocket Client',
          },
          component: () => import('../../app/pages/Guides/WebClientGuide.tsx'),
        },
        {
          path: '/clients/websocket-java',
          name: routeNames.authenticated.clients.websocketJava,
          meta: {
            title: 'Getting started with HiveMQ Websocket Java Client',
          },
          component: () => import('../../app/pages/Guides/WebsocketGuide.tsx'),
        },
        {
          path: '/clients/paho-c',
          name: routeNames.authenticated.clients.pahoC,
          meta: { title: 'Getting started with Paho C' },
          component: () => import('../../app/pages/Guides/PahoCGuide.tsx'),
        },
        {
          path: '/clients/paho-go',
          name: routeNames.authenticated.clients.pahoGo,
          meta: { title: 'Getting started with Paho Go' },
          component: () => import('../../app/pages/Guides/PahoGoGuide.tsx'),
        },
        {
          path: '/clients/mqtt-dart',
          name: routeNames.authenticated.clients.mqttDart,
          meta: { title: 'Getting started with MQTT.Dart' },
          component: () => import('../../app/pages/Guides/MqttDartGuide.tsx'),
        },
        {
          path: '/clients/arduino-esp8266',
          name: routeNames.authenticated.clients.arduinoEsp8266,
          meta: {
            title: 'Getting started with Arduino ESP8266',
          },
          component: () => import('../../app/pages/Guides/ArduinoGuide.tsx'),
        },
        // will match everything and put it under `$route.params.pathMatch`
        {
          path: '/:pathMatch(.*)*',
          name: routeNames.authenticated.notFound,
          component: () => import('../views/PageNotFound.vue'),
        },
      ],
    },
  ]

  if (withTroubleShooting) {
    allRoutes[0].children.push({
      path: '/clusters/:clusterType/:clusterUuid/troubleshoot',
      name: routeNames.authenticated.clusters.details.troubleshooting,
      meta: {
        title: 'Troubleshooting',
        navigation: { ...clusterNavigation(withTroubleShooting) },
      },
      component: () => import('../views/TroubleShooting.vue'),
    })
  }

  return allRoutes
}

export function setupRouter() {
  const allRoutes = routes()
  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: allRoutes,
  })

  router.beforeEach(setupAuthentication)
  router.afterEach(updateNavigation)
  router.afterEach((to) => trackPageView(to.fullPath))

  return router
}

async function setupAuthentication(to, from, next) {
  routerLogger(`authenticated handling - from: ${from.path} -> to: ${to.path}`)

  /**
   * If the route is a public route, then no authentication is needed.
   * E.g. verify-email route
   */
  if (to.meta.isPublicRoute) {
    routerLogger('route is public', { isPublicRoute: to.meta.isPublicRoute })
    next()
    return
  }

  const isAuthenticated = await isAuth0UserAuthenticated()
  routerLogger('is authenticated', { isAuthenticated })

  // !important: if the customer is not authenticated, then redirect to the login page or to the verify email page.
  if (!isAuthenticated) {
    const routeData = useLocalStorage(EXTERNAL_BILLING_KEY, '')

    if (routeData.value === '') {
      routeData.value = window.location.pathname
    }

    const urlParams = new URLSearchParams(window.location.search)
    const errorMessage = urlParams.get('error_description')

    if (errorMessage === 'EMAIL_NOT_VERIFIED') {
      next({ name: routeNames.authenticated.verifyEmail })
      return
    }

    routerLogger('redirect to auth0 login')
    await redirectToAuth0Login({
      appState: { targetUrl: to.fullPath },
    })

    return
  }

  // Process further the customer is authenticated
  const accountStore = useAccountStore()
  routerLogger('has account', { hasAccount: accountStore.hasAccount })

  if (!accountStore.hasAccount) {
    redirectToCompleteProfile(to, from, next)
    return
  }

  hasAccountRedirect(to, from, next)
}

/**
 * This function should get triggered when a user is authenticated and has an account.
 */
function hasAccountRedirect(to, _from, next) {
  const routeData = useLocalStorage(EXTERNAL_BILLING_KEY, '')

  const utmContent = getUTMParameter(UTMParameter.CONTENT)
  const isClusterCreateConfigureTarget =
    to.name === routeNames.authenticated.clusters.createConfigure

  /**
   * If UTM parameters have been set, then go straight to /clusters/create.
   */
  if (
    utmContent &&
    utmContent === 'starter' &&
    !isClusterCreateConfigureTarget
  ) {
    routerLogger('push to router create')
    flushUTMParameters()
    next({
      name: routeNames.authenticated.clusters.createConfigure,
      params: { clusterType: ClusterPackageConfigType.STARTER },
    })

    return
  }

  /**
   * If the user is coming from the billing page, redirect to the previous page the came from.
   * This is usually the case if a customer clicks an Email link to the billing page.
   */
  if (routeData.value) {
    const toRoute = routeData.value.toString()
    routeData.value = ''
    next(toRoute)

    return
  }

  /**
   * Redirect to clusters overview, since customer has already an account.
   */
  if (to.name === routeNames.authenticated.profiles.complete) {
    next({ name: routeNames.authenticated.clusters.index })

    return
  }

  routerLogger('continue to route')
  next()
}

function redirectToCompleteProfile(to, _from, next) {
  if (to.name !== routeNames.authenticated.profiles.complete) {
    routerLogger('redirect to complete profile')
    next({ name: routeNames.authenticated.profiles.complete })

    return
  }

  next()
}

function updateNavigation(to, from) {
  const defaultTitle = 'HiveMQ Cloud'
  const navigationStore = useNavigationStore()

  const title = to.meta.title
  document.title = title ? `${title} - ${defaultTitle}` : defaultTitle

  let navigationTitle = to.meta.navigation?.title || title || defaultTitle
  navigationStore.setTitle(navigationTitle)

  let navigationItems = window.structuredClone(to.meta?.navigation?.items) || []

  if (navigationItems.length) {
    const selectedClusterType = to.params['clusterType']

    const {
      canVisitIntegrationsPage,
      canVisitWebClientPage,
      canVisitAccessManagementPage,
      canVisitGettingStartedPage,
      canVisitApiAccessPage,
      canVisitTroubleShoot,
    } = clusterFeatureList[selectedClusterType]

    // for Starter, add link to Control Center if the URL is available
    if (to.params.clusterType === ClusterPackageConfigType.STARTER) {
      const clusterStore = useClusterStore()

      const clusterDetails = clusterStore.clusters.find(
        (c) => c.uuid === to.params.clusterUuid
      )
      const controlCenterUrl = clusterDetails?.urls?.controlCenter

      const accessManagementIndex = navigationItems.findIndex(
        (navigationItem) =>
          navigationItem.to.name ===
          routeNames.authenticated.clusters.details.accessManagement
      )

      // insert link after Access Management
      if (controlCenterUrl && accessManagementIndex > -1) {
        const newLink = {
          to: '/', // parameter is needed but it will be ignored in TopBarNavigation.vue because we're providing an external link
          meta: {
            externalLink: controlCenterUrl,
            controlCenter: true, // specify that the external link is for Control Center
          },
        }
        navigationItems.splice(accessManagementIndex + 1, 0, newLink)
      }
    }

    navigationItems = navigationItems.filter((item) => {
      if (
        !canVisitIntegrationsPage &&
        item.to.name === routeNames.authenticated.clusters.details.integrations
      ) {
        return false
      }

      if (
        !canVisitWebClientPage &&
        item.to.name === routeNames.authenticated.clusters.details.webClient
      ) {
        return false
      }

      if (
        !canVisitAccessManagementPage &&
        item.to.name ===
          routeNames.authenticated.clusters.details.credentials
            .accessCredentials
      ) {
        return false
      }

      if (
        !canVisitGettingStartedPage &&
        item.to.name ===
          routeNames.authenticated.clusters.details.gettingStarted
      ) {
        return false
      }

      if (
        !canVisitApiAccessPage &&
        item.to.name ===
          routeNames.authenticated.clusters.details.apiToken.index
      ) {
        return false
      }

      if (
        !canVisitTroubleShoot &&
        item.to.name ===
          routeNames.authenticated.clusters.details.troubleshooting
      ) {
        return false
      }

      return true
    })

    /**
     * Add route parameters to all navigation items.
     * Since the navigation definition is defined in the router configuration.
     * With that we don't need to apply the params in other components.
     */

    const enrichedDynamicItems = navigationItems.map((item) =>
      merge(item, { to: { params: to.params } })
    )

    navigationStore.setMenuItems(enrichedDynamicItems)
  } else {
    navigationStore.resetMenuItems()
  }
}

export default setupRouter
