import cluster from 'cluster'

import { Fn, MaybeRef, useEventListener, useIntervalFn } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed, ref, unref, watch } from 'vue'

import { REFRESH_DATA_TIMEOUT_EXTRA_SHORT_IN_MILLISECONDS } from '@/utils/constants/timeConstants'
import { handleResponse } from '@/utils/http/httpResponseUtils'
import {
  getPackageForType,
  isFreePackage,
  isPayAsYouGoPackage,
  isStarting,
  isStopping,
  isUpdating,
  starterPackage,
} from '@/utils/packages'

import { useAccountStore } from './accountStore'

import { Cluster } from '@/@types/consoleApi.types'
import { useDeleteCluster } from '@/modules/cluster/hooks/useDeleteCluster'
import { useGetClusters } from '@/modules/cluster/hooks/useGetClusters'
import {
  ClusterCreateConfig,
  usePostCluster,
} from '@/modules/cluster/hooks/usePostCluster'
import { useUpdateCluster } from '@/modules/cluster/hooks/useUpdateCluster'
import { useTracking } from '@/modules/tracking/hooks/useTracking'

const nonVisibleClusterStates = [
  'FAILED',
  'DESTROYED',
  'DESTROY_REQUESTED',
  'PENDING_DESTROY',
  'PLANNING_DESTROY',
  'DESTROYED_AFTER_FAILURE',
]

const nonActiveClusterStates = [...nonVisibleClusterStates, 'DESTROYING']

export const useClusterStore = defineStore('clusters', () => {
  const { trackEvent, events } = useTracking()

  const MAX_SERVERLESS_CLUSTERS = 2

  const clusters = ref<Cluster[]>([])

  const getClustersAction = useGetClusters(clusters)
  const accountStore = useAccountStore()

  const refreshInterval = useIntervalFn(
    getClusters,
    REFRESH_DATA_TIMEOUT_EXTRA_SHORT_IN_MILLISECONDS,
    {
      immediate: false,
    }
  )

  async function getClusters(): Promise<void> {
    await getClustersAction.execute()
  }

  function findCluster(clusterUuid: MaybeRef<string>): Cluster | null {
    return (
      clusters.value.find((cluster) => cluster.uuid === unref(clusterUuid)) ||
      null
    )
  }

  handleResponse({
    ...getClustersAction,
    handleSuccess({ data }) {
      clusters.value = data.items
    },
  })

  const hasClusters = computed(() => {
    return clusters.value.length !== 0
  })

  const hasClustersLoaded = computed(() => {
    return getClustersAction.isReady.value
  })

  const serverlessClusters = computed(() => {
    return clusters.value.filter((cluster) => {
      return (
        (isFreePackage(cluster.type) || isPayAsYouGoPackage(cluster.type)) &&
        !nonVisibleClusterStates.includes(cluster.state)
      )
    })
  })

  const starterClusters = computed(() =>
    visibleClusters.value.filter(
      (cluster) => cluster.type.toLowerCase() === starterPackage.name
    )
  )

  const canCreateServerlessCluster = computed(() => {
    return serverlessClusters.value.length < MAX_SERVERLESS_CLUSTERS
  })

  const visibleClusters = computed(() => {
    if (!hasClusters.value) {
      return []
    }

    if (accountStore.accountData.isOrgOwner) {
      return clusters.value.filter(
        ({ state }) => !nonVisibleClusterStates.includes(state)
      )
    }

    return clusters.value.filter(
      ({ state, ...rest }) =>
        !nonVisibleClusterStates.includes(state) && !isFreePackage(rest.type)
    )
  })

  const activeClusters = computed(() => {
    if (!hasClusters.value) {
      return []
    }

    return clusters.value.filter(
      ({ state }) => !nonActiveClusterStates.includes(state)
    )
  })

  const hasVisibleClusters = computed(() => {
    return hasClusters.value && visibleClusters.value.length !== 0
  })

  const hasStartingClusters = computed(() =>
    visibleClusters.value.some((cluster) => isStarting(cluster.state))
  )

  const hasStoppingClusters = computed(() =>
    visibleClusters.value.some((cluster) => isStopping(cluster.state))
  )

  const hasUpdatingClusters = computed(() =>
    visibleClusters.value.some((cluster) => isUpdating(cluster.state))
  )

  const hasStartingUpdatingOrStoppingClusters = computed(() => {
    return (
      hasStartingClusters.value ||
      hasUpdatingClusters.value ||
      hasStoppingClusters.value
    )
  })

  const clusterNames = computed(() =>
    visibleClusters.value.map((cluster) => cluster.name)
  )

  function createCluster(
    cluster: ClusterCreateConfig,
    options?: { onSuccess?: Fn; onError?: Fn; onReady?: Fn }
  ) {
    handleResponse({
      ...usePostCluster(cluster),
      handleSuccess({ data: newCluster }) {
        const clusterIsInListAlready = clusters.value.some(
          ({ uuid }) => newCluster.uuid === uuid
        )

        if (clusterIsInListAlready) {
          clusters.value
            .filter(({ uuid }) => newCluster.uuid !== uuid)
            .push(newCluster)
        } else {
          clusters.value.push(newCluster)
        }

        if (newCluster.state === 'NEW') {
          getClusters()
        }

        trackEvent(events.hiveMQCloud.cluster.created, {
          clusterType: getPackageForType(cluster.clusterCreate.type)
            .displayName,
        })
        options?.onSuccess?.()
      },
      handleError({ statusText }) {
        options?.onError?.()
      },
      handleReady() {
        options?.onReady?.()
      },
    })
  }

  type UpdateCluster = {
    clusterUuid: MaybeRef<string>
    clusterType: MaybeRef<string>
  }

  function updateCluster(
    { clusterUuid, clusterType }: UpdateCluster,
    options?: { onSuccess?: Fn; onError?: Fn; onReady?: Fn }
  ) {
    handleResponse({
      ...useUpdateCluster(clusterUuid, clusterType),
      handleSuccess() {
        //FIXME: Currently the endpoint returns the initial (free) cluster instead of the updated one. So we need to re-fetch the store.
        getClusters()
        trackEvent(events.hiveMQCloud.cluster.upgraded, {
        clusterType: clusterType.toString(),
          clusterUuid: clusterUuid.toString(),
        })
        options?.onSuccess?.()
      },
      handleError({ statusText }) {
        options?.onError?.()
      },
      handleReady() {
        options?.onReady?.()
      },
    })
  }

  function deleteCluster(
    clusterUuid: MaybeRef<string>,
    options?: { onSuccess?: Fn; onError?: Fn; onReady?: Fn }
  ) {
    handleResponse({
      ...useDeleteCluster(clusterUuid),
      handleSuccess() {
        clusters.value = clusters.value.filter(
          ({ uuid }) => clusterUuid !== uuid
        )
        trackEvent(events.hiveMQCloud.cluster.deleted, {
          clusterUuid: clusterUuid.toString(),
        })
        options?.onSuccess?.()
      },
      handleError({ statusText }) {
        options?.onError?.()
      },
      handleReady() {
        options?.onReady?.()
      },
    })
  }

  watch(hasStartingUpdatingOrStoppingClusters, () => {
    if (hasStartingUpdatingOrStoppingClusters.value) {
      refreshInterval.resume()
      return
    }

    refreshInterval.pause()
  })

  useEventListener(document, 'visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      getClusters()
    }
  })

  return {
    clusters,
    visibleClusters,
    activeClusters,
    hasClusters,
    hasClustersLoaded,
    hasVisibleClusters,
    hasStartingClusters,
    clusterNames,
    serverlessClusters,
    starterClusters,
    canCreateServerlessCluster,
    getClusters,
    findCluster,
    createCluster,
    updateCluster,
    deleteCluster,
  }
})
