export const transitionMap = {
  fab: 'fab-transition',
  fade: 'fade-transition',
  expand: 'expand-transition',
  scale: 'scale-transition',
  scrollX: 'scroll-x-transition',
  scrollXReverse: 'scroll-x-reverse-transition',
  scrollY: 'scroll-y-transition',
  scrollTReverse: 'scroll-y-reverse-transition',
  slideX: 'slide-x-transition',
  slideXReverse: 'slide-x-reverse-transition',
  slideY: 'slide-y-transition',
  slideYReverse: 'slide-y-reverse-transition',
  undefined: 'fade-transition',
} as const

export type Transitions = typeof transitionMap
export type TransitionName = keyof typeof transitionMap
export type Transition = Transitions[TransitionName]
