import { AxiosRequestConfig } from 'axios'

export type HttpHookOptions = {
  noCache: boolean
}

export const DEFAULT_HTTP_HOOK_OPTIONS: HttpHookOptions = {
  noCache: false,
}

export const httpNoCacheHeaderConfig: AxiosRequestConfig = {
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
}
