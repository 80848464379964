import { gte as compareVersions } from 'semver'

import {
  ClusterTypeName,
  ClusterType,
  ClusterState,
} from '@/@types/consoleApi.types'

export enum ClusterPackageConfigType {
  FREE = 'free',
  PAYG = 'payg',
  // TODO rename to the real product, because it's customer facing
  STARTER = 'starter',
  /**
   * @deprecated - Standard is an old package that was removed but has still a couple of clients left
   */
  STANDARD = 'standard',
  /**
   * @deprecated - Business was an old package that got replaced by the `standard` package
   */
  BUSINESS = 'business',
  /**
   * Unknown data type that might pop up if values are being loaded via lazy load.
   * We don't want the application to break that's why mediate the issue by placeholding the computed dataset.
   */
  PROFESSIONAL = 'professional',
  DEDICATED = 'enterprise',
  UNKNOWN = 'unknown',
}

/**
 * @deprecated - Use ClusterPackageConfigType instead
 */
export enum ClusterPackageName {
  SERVERLESS = 'Serverless',
  STARTER = 'Starter',
  PROFESSIONAL = 'Professional',
  DEDICATED = 'Enterprise',
  BUSINESS = 'Business',
  STANDARD = 'Standard',
}

type ClusterPackageConfig = {
  routeParams: {
    clusterType: ClusterPackageConfigType
  }
  name: string
  displayName: string
  clusterType: ClusterPackageConfigType
  planName: string
  description: string
  styleClass: string
}

export const freePackage: ClusterPackageConfig = {
  routeParams: {
    clusterType: ClusterPackageConfigType.FREE,
  },
  name: 'shared_multi_tenant',
  displayName: 'Serverless',
  clusterType: ClusterPackageConfigType.FREE,
  description: 'FREE',
  planName: 'serverless',
  styleClass: 'free-cluster',
} as const

export const payAsYouGoPackage: ClusterPackageConfig = {
  routeParams: {
    clusterType: ClusterPackageConfigType.PAYG,
  },
  name: 'shared_multi_tenant_paid',
  displayName: 'Serverless',
  clusterType: ClusterPackageConfigType.PAYG,
  description: 'Pay As You Go',
  planName: 'serverless',
  styleClass: 'pay-as-you-go-cluster',
} as const

export const starterPackage: ClusterPackageConfig = {
  routeParams: {
    clusterType: ClusterPackageConfigType.STARTER,
  },
  name: 'starter',
  displayName: 'Starter',
  clusterType: ClusterPackageConfigType.STARTER,
  description: 'Production S',
  planName: 'starter',
  styleClass: 'starter-cluster',
} as const

/**
 * First starter like package
 * @deprecated - no longer a selectable option
 */
export const standardPackage: ClusterPackageConfig = {
  routeParams: {
    clusterType: ClusterPackageConfigType.STANDARD,
  },
  name: 'ec2_single',
  displayName: 'Standard',
  clusterType: ClusterPackageConfigType.STANDARD,
  description: 'Designed for critical use cases',
  planName: '',
  styleClass: 'small-cluster',
} as const

/**
 * First enterprise like package
 * @deprecated - no longer a selectable option
 */
export const businessPackage: ClusterPackageConfig = {
  routeParams: {
    clusterType: ClusterPackageConfigType.BUSINESS,
  },
  name: 'ec2_3node_cluster_ha',
  displayName: 'Business Critical',
  clusterType: ClusterPackageConfigType.BUSINESS,
  description: 'Perfect for production',
  planName: 'business',
  styleClass: 'big-cluster',
} as const

export const dedicatedPackage: ClusterPackageConfig = {
  routeParams: {
    clusterType: ClusterPackageConfigType.DEDICATED,
  },
  name: 'dedicated',
  displayName: 'Enterprise',
  clusterType: ClusterPackageConfigType.DEDICATED,
  description: 'Designed for critical use cases',
  planName: '',
  styleClass: 'big-cluster',
} as const

export const fallbackPackage: ClusterPackageConfig = {
  routeParams: {
    clusterType: ClusterPackageConfigType.UNKNOWN,
  },
  name: '',
  displayName: '',
  clusterType: ClusterPackageConfigType.UNKNOWN,
  description: '',
  planName: '',
  styleClass: '',
} as const

export type ClusterPackage =
  | typeof freePackage
  | typeof payAsYouGoPackage
  | typeof standardPackage
  | typeof businessPackage
  | typeof starterPackage
  | typeof dedicatedPackage
  | typeof fallbackPackage

export type InternalClusterPackage = {
  name: ClusterType
  displayName: ClusterTypeName
  amount: number
  currency: string
  trafficCostCent: number
  freeTrafficGb: number
}

export const clusterPackages: ClusterPackage[] = [
  standardPackage,
  freePackage,
  payAsYouGoPackage,
  starterPackage,
  businessPackage,
  dedicatedPackage,
]

export function getPackageForType(type?: string): ClusterPackage {
  const clusterPackage = clusterPackages.find(
    (clusterPackage) => clusterPackage.name === type?.toLowerCase()
  )

  return clusterPackage || fallbackPackage
}

export function isFreePackage(typeName: string): boolean {
  return getPackageForType(typeName) === freePackage
}

export function isPayAsYouGoPackage(typeName: string): boolean {
  return getPackageForType(typeName) === payAsYouGoPackage
}

export function isStandardPackage(typeName: string): boolean {
  return getPackageForType(typeName) === standardPackage
}

export function isStarterPackage(typeName: string): boolean {
  return getPackageForType(typeName) === starterPackage
}

export function isEnterprisePackage(typename: string): boolean {
  return getPackageForType(typename) === dedicatedPackage
}

export function isBusinessPackage(typeName: string): boolean {
  return getPackageForType(typeName) === businessPackage
}

export function getDescriptionForType(typeName: string): string {
  return getPackageForType(typeName).description
}

export function getStyleForType(typeName: string): string {
  return getPackageForType(typeName).styleClass
}

export function getDisplayNameForType(typeName: string): string {
  return getPackageForType(typeName).displayName
}

export function isStarting(state: string): boolean {
  return (
    state === ClusterState.NEW ||
    state === ClusterState.PENDING ||
    state === ClusterState.PLANNING ||
    state === ClusterState.CREATING ||
    state === ClusterState.INITIALIZING
  )
}

export function isCreating(state: string): boolean {
  return state === ClusterState.CREATING
}

export function isRunning(state: string): boolean {
  return state === ClusterState.DONE
}

export function isError(state: string): boolean {
  return state === ClusterState.ERROR
}

export function isFailed(state: string): boolean {
  return state === ClusterState.FAILED
}

export function isNew(state: string): boolean {
  return state === ClusterState.NEW
}

export function isUpdating(state: string): boolean {
  return state === ClusterState.LOCKED
}

export function isStopping(state: string): boolean {
  return (
    state === ClusterState.PENDING_DESTROY ||
    state === ClusterState.PLANNING_DESTROY ||
    state === ClusterState.DESTROYED ||
    state === ClusterState.DESTROYING
  )
}

export function isStopped(state: string): boolean {
  return (
    !isRunning(state) &&
    !isStarting(state) &&
    !isFailed(state) &&
    !isStopping(state)
  )
}

/**
 * Check if the given version supports confluent cloud
 *
 * Required version: `1.1.0`
 *
 * @param version
 * @returns is the version supported
 * @example
 * versionSupportsConfluentCloud('0.1.1') // false
 * versionSupportsConfluentCloud('1.1.0') // true
 * versionSupportsConfluentCloud('2.4.0') // true
 */
export function versionSupportsConfluentCloud(version: string): boolean {
  const sanitizedVersion = version.replace(/^(.+,)(.+)/, '$2')

  return compareVersions(sanitizedVersion, '1.1.0')
}
