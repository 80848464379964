type SetupTimerOptions = {
  openAfter: number
  retryAfter: number
  onOpen?(): void
  onRetry?(): void
}

export enum OrbitalDialog {
  ClusterCreateDialog = 'PokB0as4DYOp',
  SpecialOfferPromotion = 'NwBq5szNvBAe',
}

export function useOrbital() {
  function identifyUser(id: string, email: string) {
    // @ts-expect-error - orbital types are not set to window
    // eslint-disable-next-line no-undef
    orbital('identify', id, {
      email,
    })
  }

  function reset() {
    // @ts-expect-error - orbital types are not set to window
    // eslint-disable-next-line no-undef
    orbital('reset')
  }

  function showOrbitalDialog() {
    // @ts-expect-error - orbital types are not set to window
    // eslint-disable-next-line no-undef
    orbital('trigger', OrbitalDialog.ClusterCreateDialog)
  }

  function showClusterCreateDialog() {
    // @ts-expect-error - orbital types are not set to window
    // eslint-disable-next-line no-undef
    orbital('trigger', OrbitalDialog.ClusterCreateDialog, {
      force: true,
      skipInitialPrompt: true,
    })
  }

  function showSpecialOfferDialog() {
    // @ts-expect-error - orbital types are not set to window
    // eslint-disable-next-line no-undef
    orbital('trigger', OrbitalDialog.SpecialOfferPromotion, {
      force: true,
    })
  }

  /**
   * Setup timer to display the orbital dialog after the given time has passed
   * ---
   * !Important
   * If the user is located on a denied page the dialog will not be shown
   * but a second timer with the `retryAfter` parameter will be triggered.
   * Once the customer navigates to a page that is NOT a denied page the Orbital
   * dialog will be called, after the `retryAfter` has passed.
   * @param options
   */
  function setupTimer(options: SetupTimerOptions) {
    const disallowedUrls = ['/getting-started', '/welcome', '/complete-profile']

    function isDeniedUrl() {
      return disallowedUrls.some((url) => window.location.href.includes(url))
    }

    function timerHandler() {
      if (isDeniedUrl()) {
        options.onRetry?.()
        createTimer(options.retryAfter)

        return
      }

      options.onOpen?.()
      showOrbitalDialog()
    }

    function createTimer(duration: number) {
      window.setTimeout(timerHandler, duration)
    }

    createTimer(options.openAfter)
  }

  return {
    identifyUser,
    reset,
    showClusterCreateDialog,
    setupTimer,
    showSpecialOfferDialog,
  }
}
