import { useAsyncState } from '@vueuse/core'
import { components } from 'src/generated/openapi/apiary'

import { $httpV2 } from '@/clients/consoleAxiosClient'

export function useGetOrgId() {
  return useAsyncState<components['schemas']['Organisation'][]>(async () => {
    return $httpV2.get('orgs').then((response) => response.data)
  }, [] as components['schemas']['Organisation'][])
}
