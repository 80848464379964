export function useRefiner() {
  function setProject() {
    refinerPush(['setProject', window.hmqcConfig.refiner_project_id])
  }

  function identifyUser(id: string, name: string) {
    refinerPush([
      'identifyUser',
      {
        id: id,
        name: name,
      },
    ])
  }

  function showFeedbackForm() {
    refinerPush(['showForm', 'feedback'])
  }

  function suggestIntegration() {
    refinerPush(['showForm', '868137a0-c966-11ed-97f1-0918b47469b6'])
  }

  function showClusterDeleteFeatureResearchForm() {
    refinerPush(['showForm', 'cluster-delete-product-feature-research'])
  }

  function showClusterCredentialsFeatureResearchForm() {
    refinerPush(['showForm', 'cluster-credentials-product-feature-research'])
  }

  function showClusterKafkaIntegrationFeatureResearchForm() {
    refinerPush([
      'showForm',
      'cluster-product-integration-kafka-feature-research',
    ])
  }

  function showBetaProductHandraiser(): void {
    refinerPush(['showForm', '054896c0-ea59-11ed-98f7-971c58aad356'])
  }

  return {
    setProject,
    identifyUser,
    showFeedbackForm,
    suggestIntegration,
    showClusterDeleteFeatureResearchForm,
    showClusterCredentialsFeatureResearchForm,
    showClusterKafkaIntegrationFeatureResearchForm,
    showBetaProductHandraiser,
  }
}

function attachRefinerToWindow() {
  if (!window._refinerQueue) {
    window._refinerQueue = window._refinerQueue || []
  }
}

function refinerPush(actions: any[]) {
  attachRefinerToWindow()
  window._refinerQueue.push(actions)
}
