import { ApiVersion, HTTPMethod, useFetchCall } from '@app/helpers/useFetchCall'
import * as React from 'react'

import { components } from '@/generated/openapi/apiary'

import { OrganizationContext } from '../organization/organization'

import { Status } from '@/@types'
import { Account, AccountCreate } from '@/modules/billing/@types/payment.types'

type StoreState = {
  status: Status
  account: Account | null
  accountData: {
    activeOrgId: null | string
    isOrgOwner: boolean
  }
}

type CostAndBilling = components['schemas']['CustomerPortalSessionResponse']

type AccountStore = {
  createAccount: (_account: AccountCreate) => Promise<void>
  storeState: StoreState
  costAndBilling: () => Promise<CostAndBilling>
}

const OWNER = 'owner'
const initialState = {
  status: Status.Rest,
  account: null,
  accountData: { activeOrgId: null, isOrgOwner: false },
}

export const AccountContext = React.createContext<AccountStore>({
  storeState: initialState,
} as AccountStore)

export function AccountProvider({ children }: React.PropsWithChildren) {
  const { fetchCall } = useFetchCall()
  const { storeState: orgState } = React.useContext(OrganizationContext)

  const [storeState, setStoreState] = React.useState<StoreState>({
    status: Status.Rest,
    account: null,
    accountData: { activeOrgId: null, isOrgOwner: false },
  })

  React.useEffect(() => {
    if (storeState.account) {
      const isOrgOwner = !!orgState.members?.list.find(
        (member) =>
          member.email === storeState.account?.email &&
          member.role?.toLowerCase() === OWNER
      )

      setStoreState((state) => ({
        ...state,
        accountData: {
          activeOrgId: orgState.activeOrg?.data?.id,
          isOrgOwner,
        },
      }))
    } else {
      getAccount()
    }
  }, [storeState.account, orgState.activeOrg, orgState.members])

  async function getAccount() {
    try {
      setStoreState((state) => ({ ...state, status: Status.Loading }))
      const account = await fetchCall({
        path: 'account',
        version: ApiVersion.V1,
      })
      setStoreState((state) => ({ ...state, account, status: Status.Success }))
    } catch (error) {
      setStoreState((state) => ({ ...state, status: Status.Error }))
    }
  }

  async function createAccount(payload: AccountCreate) {
    try {
      setStoreState((state) => ({ ...state, status: Status.Loading }))
      await fetchCall({
        path: 'account',
        version: ApiVersion.V1,
        method: HTTPMethod.POST,
        payload,
      })

      await getAccount()
    } catch (error) {
      setStoreState((state) => ({
        ...state,
        account: null,
        status: Status.Error,
      }))
    }
  }

  async function costAndBilling() {
    const data = (await fetchCall({
      path: `orgs/${storeState.accountData.activeOrgId}/subscriptions/createCustomerPortalSession`,
      method: HTTPMethod.POST,
    })) as unknown as CostAndBilling

    return data
  }

  const value = React.useMemo(
    () => ({ createAccount, storeState, costAndBilling }),
    [storeState]
  )
  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  )
}
