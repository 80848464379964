import { MaybeRef } from '@vueuse/core'
import { computed, unref } from 'vue'

import { useAccountStore } from '@/store/accountStore'

import {
  ClusterPackageConfigType,
  isBusinessPackage as getIsBusinessPackage,
  isFreePackage as getIsFreePackage,
  isPayAsYouGoPackage as getIsPayAsYouGoPackage,
  isStandardPackage as getIsStandardPackage,
  isStarterPackage as getIsStarterPackage,
} from '@/utils/packages'

export function useIsPackageType(clusterType: MaybeRef<string>) {
  const isFreePackage = computed(() => {
    return getIsFreePackage(unref(clusterType))
  })

  const isPayAsYouGoPackage = computed(() => {
    return getIsPayAsYouGoPackage(unref(clusterType))
  })

  const isStandardPackage = computed(() => {
    return getIsStandardPackage(unref(clusterType))
  })

  const isStarterPackage = computed(() => {
    return getIsStarterPackage(unref(clusterType))
  })

  const isBusinessPackage = computed(() => {
    return getIsBusinessPackage(unref(clusterType))
  })

  return {
    isFreePackage,
    isPayAsYouGoPackage,
    isBusinessPackage,
    isStarterPackage,
    isStandardPackage,
  }
}

export function useGetClusterRequestData(): {
  isStarter: boolean
  isDedicated: boolean
  accountOrgName: string
} {
  // we need to refactor this implementation as it was just the source of a bug
  // also to avoid making multiple unnecessary api calls, All api calls should be made through
  // the state and not directly from the component where possible
  // NOTE: To be clear, this worked for a large part of the application, but since it's an hack places like tutorial guides were failing
  const accountStore = useAccountStore()
  const pathnameMatch = window.location.pathname.match('^/clusters/([^/]+)/.+$')

  if (!pathnameMatch) {
    return {
      isStarter: false,
      isDedicated: false,
      accountOrgName: accountStore.accountOrgId!,
    }
  }

  const isStarter = pathnameMatch[1] === ClusterPackageConfigType.STARTER
  const isDedicated = pathnameMatch[1] === ClusterPackageConfigType.DEDICATED

  return {
    isStarter,
    isDedicated,
    accountOrgName: accountStore.accountOrgId!,
  }
}
