/**
 * Wait in test for a certain amount of time.
 * @example
 * Wait manually started events that otherwise would be triggered by default
 * See: useApiStream -> immediate
 * @example
 * await sleep(2000) // waits for two (2) seconds
 * @param durationInMilliseconds - the duration in milliseconds that should be waited
 */
export function sleep(durationInMilliseconds: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, durationInMilliseconds))
}

/**
 * Time travel to the next tick of the event loop.
 * Very useful when working with update cycles in Vue and React.
 * Example: Changes in state within a context provider are not immediately available.
 *
 * @example
 * await waitForNextUpdate()
 */
export function waitForNextUpdate() {
  return sleep(0)
}
