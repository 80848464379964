import { MaybeRef, whenever } from '@vueuse/core'
import { AxiosError, AxiosResponse } from 'axios'
import { Ref, unref } from 'vue'

import {
  httpReasonPhrase,
  httpStatusCode,
  isHttpSuccess,
} from './httpStatusCodes'

export type HmqListResponseData<T> = { items: T[] }

export type HmqResponse<T> = {
  data: T
  status: number
  statusText: string
}

export type HmqErrorResponse<T> = {
  data: T
  status: number
  statusText: string
  error: {
    name: string
    message: string
  }
}

export function toHmqResponse<T>(response: AxiosResponse<T>): HmqResponse<T> {
  const { data, status, statusText } = response

  return {
    data,
    status,
    statusText,
  } as const
}

export function toHmqResponseError<T>(
  error: AxiosError,
  initialState: MaybeRef<T>
): HmqErrorResponse<T> {
  return {
    ...toHmqResponse({
      ...error.response!,
      data: unref(initialState),
    }),
    error: {
      name: error.name,
      message: error.message,
    },
  }
}

export function toInitialHmqResponseState<T>(
  initialState: MaybeRef<T>
): HmqResponse<T> {
  return toHmqResponse({
    data: unref(initialState),
    status: httpStatusCode.OK,
    statusText: httpReasonPhrase.OK,
  } as AxiosResponse<T>)
}

export function isHmqErrorResponse<T>(
  item: HmqResponse<T> | HmqErrorResponse<T>
): item is HmqErrorResponse<T> {
  return 'error' in item
}

type HandleResponseParams<T> = {
  isReady: Ref<boolean>
  state: Ref<HmqResponse<T>> | Ref<HmqErrorResponse<T>>
  // eslint-disable-next-line no-unused-vars
  handleSuccess?: (state: HmqResponse<T>) => void
  // eslint-disable-next-line no-unused-vars
  handleError?: (state: HmqErrorResponse<T>) => void
  // eslint-disable-next-line no-unused-vars
  handleReady?: (state: HmqResponse<T> | HmqErrorResponse<T>) => void
}

export function handleResponse<T>({
  isReady,
  state,
  handleSuccess,
  handleError,
  handleReady,
}: HandleResponseParams<T>) {
  whenever(isReady, () => {
    const _response = unref(state)

    if (isHttpSuccess(_response)) {
      handleSuccess?.(_response)
    }

    if (isHmqErrorResponse(_response)) {
      handleError?.(_response)
    }

    handleReady?.(_response)
  })
}
