import { computed, reactive, Ref, toRefs } from 'vue'
import { useRoute } from 'vue-router'

import { CustomRouteMeta } from '@/@types/routeMeta.types'
import { DeepPartial } from '@/@types/utils.types'
import { layouts } from '@/router'

export function usePageLayout() {
  const { meta } = toRefs(useRoute())
  const routeMeta = meta as Ref<DeepPartial<CustomRouteMeta>>

  const isDefault = computed(() => {
    return !routeMeta.value.layout || routeMeta.value.layout === layouts.default
  })

  const isDashboard = computed(() => {
    return routeMeta.value.layout === layouts.dashboard
  })

  const isLandingPage = computed(() => {
    return routeMeta.value.layout === layouts.landingPage
  })

  return reactive({
    isDefault,
    isDashboard,
    isLandingPage,
  })
}
