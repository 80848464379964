{
  "common": {
    "comingSoon": "Coming Soon"
  },
  "cluster": {
    "createNew": "Create New Cluster",
    "title": "Select the HiveMQ Cloud plan you need",
    "comparePlans": "Compare Features",
    "planComparison": "Plan comparison",
    "pricing": {
      "startsFrom": "Starts from",
      "getStarted": "Get started"
    },
    "beta": "Beta Version",
    "comingSoon": "@:common.comingSoon",
    "forProductionTiers": "for Production Tiers",
    "dedicatedSolutionBanner": {
      "title": "For larger setups, we also have dedicated solutions!",
      "subTitle": "Let's talk and explore how we can help",
      "salesButton": "Talk to sales"
    },
    "mapping": {
      "single": "Single Mapping included",
      "multi": "Multi Mapping included"
    },
    "saasTerms": "SaaS Terms",
    "saasTermsText": "By selecting {action} you agree to our current {terms}.",
    "state": {
      "starting": "Your HiveMQ cluster is starting right now.\nThe view will update when the startup process has completed.",
      "stopping": "The cluster is shutting down. It will disappear once it has been shut down completely.",
      "error": "An unexpected error occurred. Please check the status of your cluster in a couple of minutes again. We are working on resolving this issue."
    },
    "overview": {
      "states": {
        "creating": {
          "headline": "Your Cluster is currently starting",
          "subtitle": "Please be patient this process might take 1 - 2 minutes."
        }
      },
      "details": {
        "url": "URL",
        "port": "Port (TLS)",
        "status": "Status",
        "startedAt": "Started"
      },
      "actions": {
        "controlCenter": "HiveMQ Control Center",
        "manageCluster": "Manage Cluster"
      }
    },
    "usage": {
      "titles": {
        "hourlyUsage": "Hourly usage",
        "hoursRunning": "Hours running",
        "normalizedMessages": "Normalized messages",
        "mqttClientSessions": "MQTT Client Sessions",
        "traffic": "Data Traffic"
      },
      "subLine": {
        "usageMightVary": "Actual usage can vary slightly from the value shown.",
        "updateEveryFiveMinutes": "Updated every 5 minutes.",
        "updateEveryHour": "Updated every hour."
      },
      "description": {
        "updateEveryFiveMinutes": "The usage metrics are updated every 5 minutes on the server.",
        "lastUpdate": "Last update check {lastUpdatedAt}",
        "billingPeriod": "The displayed numbers are for the current billing period between {currentInvoiceStartedAt} and {currentInvoiceEndsAt}."
      },
      "prices": {
        "lastUpdate": "Last update",
        "millionMessages": "Price per million messages",
        "sessions": "Price per session",
        "hourly": "Price per hour",
        "monthly": "Price per month",
        "GB": "Price per GB"
      }
    },
    "createFailedDialog": {
      "header": "HiveMQ Cluster Creation Failed",
      "body": "You reached the limit of running clusters and cannot create another one. You can delete one of the running cluster and create a new one. To delete a cluster you need to go to the Manage Cluster page and then click the delete button on the bottom of the page. If you need to increase the limit of running clusters, please open a support request with us."
    },
    "create": {
      "dedicatedExpert": "Dedicated HiveMQ Expert",
      "hours": "{count} hours | {count} hour | {count} hours",
      "p1reactionTime": "Priority 1 reaction time",
      "sla": "No Uptime SLA | {count}% Uptime SLA",
      "support": {
        "basic": "Basic Support",
        "24/7": "24/7 Support"
      }
    },
    "dns": {
      "banner": {
        "info": {
          "header": "Introducing Set Your Own Domain Name \uD83C\uDF89",
          "subtext1": "Exciting update: Now you can make your DNS name uniquely yours!",
          "subtext2": "Just a quick heads up - tweaking it means current clients might need a quick reconnect. Have fun personalizing.",
          "button": "Customize my Domain Name"
        },
        "warning": {
          "header": "Introducing Set Your Own Domain Name \uD83C\uDF89",
          "subtext1": "Exciting update: Now you can make your Domain name uniquely yours!",
          "subtext2": "Just a quick heads up - tweaking it means current clients might need a quick reconnect. Have fun personalizing.",
          "button": "Customize my Domain Name"
        },
        "success": {
          "header": "Introducing Set Your Own Domain Name \uD83C\uDF89",
          "subtext1": "Exciting update: Now you can make your Domain name uniquely yours!",
          "subtext2": "Just a quick heads up - tweaking it means current clients might need a quick reconnect. Have fun personalizing.",
          "button": "Customize my Domain Name"
        }
      },
      "steps": {
        "scope": {
          "alias": "Alias",
          "domainName": "Domain Name",
          "next": "Next",
          "close": "Close",
          "verificationProgress": "Verification Progress"
        },
        "dnsCustomizeStep": {
          "body": "Enter the domain you wish to set for your cluster.",
          "bannerContentTitle": "Important Note",
          "bannerContentBody": "If you manage CAA (Certification Authority Authorization) records within your DNS settings, please ensure that \"letsencrypt.org\" is included as an authorized certificate issuer. This step is crucial for the successful issuance and renewal of your domain's security certificate."
        },
        "cnameRecordStep": {
          "header": "Establishing your domain requires the creation of two distinct CNAME records.",
          "subtext1": "Consider these records akin to affixing two labels to your residence: one serves as a validation point for postal services to confirm its authenticity, and the other warmly invites guests and visitors to your domain.",
          "subtitle": "ACME Challenge CNAME Record",
          "subtext2": "The ACME Challenge CNAME Record is akin to a verification process. By integrating this record into your domain's settings, you grant us the requisite permissions to manage the security certificates on your behalf. This procedure is comparable to a postman verifying the correct address before handing over a significant package.",
          "actualAlias": "Actual Alias CNAME Record",
          "actualAliasBody": "The Actual Alias CNAME Record serves as the principal identifier for your domain, facilitating ease of access for others. It's like the official nameplate outside your residence that acquaintances might look for."
        },
        "dnsVerificationStep": {
          "header": "First and foremost, it is time to verify your custom domain. We will register it and proceed with a swift verification to ensure all details align correctly. This initial step guarantees that we are interacting with the appropriate domain.",
          "banner": {
            "header": "Congratulations! Your custom domain has been verified. \uD83C\uDF89\n",
            "body": "We have verified these domains. However, please note they have not been enabled on our side yet."
          },
          "verifying": "Verifying",
          "verifyCustomDomain": "Verify my custom domain"
        },
        "dnsApplyChangesStep": {
          "header": "Upon successful verification, we will then craft a specialized certificate exclusively for your domain. With this certificate securely\n        in place, your clients can confidently connect with your cluster, assured of a protected environment.",
          "warningBanner": {
            "header": "Heads up: Your clients might get disconnected",
            "body1": "Once we have finished applying your custom domain, there's a small chance your clients may experience a disconnect.",
            "body2": "While they can still reach the cluster using the old name, the security certificate now exclusively ties to your new custom domain. To avoid any future reconnection hiccups due to mismatched certificates, please ensure that all your clients connect to your new custom domain name {customDomain}."
          },
          "successBanner": {
            "header": "Congratulations! Your custom domain has been enabled. \uD83C\uDF89\n",
            "body1": "While your clients might still be able to reach the cluster using the old name, the security certificate now exclusively ties to your new custom domain.",
            "body2": "To avoid any future reconnection hiccups due to mismatched certificates, please ensure that all your clients connect to your new custom domain name {customDomain}"
          }
        }
      }
    }
  },
  "AWS": {
    "name": {
      "short": "AWS",
      "long": "Amazon Web Services"
    }
  },
  "AZURE": {
    "name": {
      "short": "AZURE",
      "long": "Microsoft Azure"
    }
  },
  "clusterConfig": {
    "title": "Configure your HiveMQ Cloud plan",
    "pricing": {
      "title": "Your Selection",
      "hint": "Your total cost is based on the number of hours used and the number of messages processed.",
      "estimateExample": "estimated monthly price for a 24/7 active cluster and 5.3M messages/month (2 messages/sec): $254.24",
      "freeCredits": "$100 Free credit with your first Cloud Starter plan",
      "perStartedHour": "/started hour",
      "perMillionMessages": "/million messages",
      "tooltip": "You have reached the limit of Serverless clusters in your account (maximum is 2 clusters). Please remove one of your existing Serverless clusters or create a Starter cluster instead."
    },
    "options": {
      "selectCloudProvider": "Select your cloud service provider",
      "selectTier": "Select your tier",
      "selectRegion": "Select your region",
      "enterClusterName": "Name your cluster",
      "chooseSubscription": "Choose your subscription",
      "cloudSelectionOnlyInPro": "Cloud selection is only available in our Professional Plan",
      "clusterNamePlaceholder": "My Starter Cluster",
      "noRegion": "There are no available regions at the moment, please try again later"
    }
  },
  "welcome": {
    "title": "Hi {name}, Welcome to HiveMQ Cloud 👋",
    "description": "Just a few seconds and you have access to your first HiveMQ Cloud cluster.\n Our Getting Started Guide will help you to quickly connect your IoT devices."
  },
  "completeProfile": {
    "title": "Thanks for signing up!",
    "description": "We'll just need a few more details and you'll be ready to go",
    "error": {
      "unknown": "An unknown error happened and we are sorry but the account could not be created. Please try to logout and login again or contact our support."
    },
    "jobTitle": {
      "itArchitect": "IT Architect",
      "softwareEngineer": "Software Engineer",
      "siteReliabilityEngineer": "SRE",
      "consultant": "Consultant",
      "productManager": "Product Manager",
      "cLevelExecutive": "C-Level Executive",
      "student": "Student",
      "other": "Other"
    },
    "input": {
      "label": {
        "jobTitle": "Job title",
        "company": "Company"
      }
    }
  },
  "gettingStarted": {
    "headerSubtitle": "Get started in just a couple of minutes using our getting started guides. We'll help you get started with hands-on tutorials, guides, videos, and code samples to quickly connect your first MQTT client with your HiveMQ Cloud cluster.",
    "createCredentialStart": "Define the credentials that your MQTT clients can use to connect to your HiveMQ Cloud cluster. Please visit the ",
    "createCredentialLink": "HiveMQ documentation ",
    "createCredentialEnd": "for examples on how to use the credentials to connect an MQTT client.",
    "guideSection": "Choose a getting started guide and follow the step by step instructions to connect your device or tool to your HiveMQ Cloud cluster.",
    "integrationSection": "Enable an integration that allows you to stream data between your HiveMQ Cloud cluster and a 3rd party service for further processing.",
    "mqttEssentialsSection": "To learn more about MQTT choose one of our MQTT Essentials posts.  ",
    "connectionSettingsTitle": "Your Connection Settings",
    "connectionSettingsDescription": "Your cluster is already up and running! Use these connection setting to connect to your cluster.",
    "successfulCredentialCreation": "You've successfully created your first mqtt credential pair.",
    "createCredentialText": "Create your first credential pair",
    "learningMaterials": {
      "part1": {
        "title": "MQTT Essentials: Part 1",
        "body": "Welcome to MQTT Essentials: A ten-part blog series on the core features and concepts of the MQTT protocol. This post outlines what we plan to cover in the blog, gives you a quick introduction to MQTT, and provides some interesting background material on the protocol."
      },
      "part2": {
        "title": "MQTT Essentials: Part 2",
        "body": "Welcome to the second edition of MQTT Essentials. A ten-part blog series on the core features and concepts of the MQTT protocol. In the first post of this series, we introduced MQTT and talked about the origin and history of the protocol. If you missed part one, you should definitely check it out. To complement your knowledge we have a video about the Publish Subscribe Pattern at the end of this blog post."
      },
      "part3": {
        "title": "MQTT Essentials: Part 3",
        "body": "Welcome to the third edition of MQTT Essentials - a ten-part blog series on the core features and concepts of the MQTT protocol. In this post, we will discuss the roles of the MQTT client and broker, the parameters and options that are available when you connect to a MQTT broker, and explain MQTT server and connection establishment. At the end of this blog post, we have a video that complements this blog post. We recommend you to read the blog post and watch the video for further information."
      },
      "part4": {
        "title": "MQTT Essentials: Part 4",
        "body": "Welcome to the fourth part of MQTT Essentials. A ten-part blog series on the core features and concepts of the MQTT protocol. In this post, we focus on publish, subscribe, and unsubscribe in MQTT. Earlier in this series, we covered the basics of the publish/subscribe model. In this post we delve into the specifics of publish/subscribe in the MQTT protocol. If you haven’t read about the basics of the publish/subscribe pattern yet, we strongly encourage you to read that post first."
      },
      "part5": {
        "title": "MQTT Essentials: Part 5",
        "body": "Welcome to the fifth part of MQTT Essentials. A ten-part blog series on the core features and concepts of the MQTT protocol. In this post, we focus on MQTT topics and best practices. As we have already mentioned, the MQTT broker uses the topic of a message to decide which client receives which message. We also look at SYS-topics, which are special topics that reveal information about the broker itself. Be sure to watch our video about MQTT topics at the end of this blog post. Let’s get started."
      },
      "part6": {
        "title": "MQTT Essentials: Part 6",
        "body": "Welcome to part 6 of MQTT Essentials. A ten-part blog series on the core features and concepts of the MQTT protocol. In this post, we explain the different Quality of Service levels in MQTT. The term “quality of service” has come up a few times in previous posts, let’s look into exactly what this term means. Be sure to watch our video on the same topic at the end of this blog post."
      },
      "part7": {
        "title": "MQTT Essentials: Part 7",
        "body": "Welcome to the seventh part of the MQTT Essentials. A ten-part blog series on the core features and concepts of the MQTT protocol. In this post we talk about persistent sessions and message queueing in MQTT. Although MQTT is not a message queue by definition, it can queue messages for clients. We’ll show you how. To complement your knowledge be sure to watch our video on the same topic at the end of this blog post."
      },
      "part8": {
        "title": "MQTT Essentials: Part 8",
        "body": "Welcome to the eighth part of MQTT Essentials. A ten-part blog series on the core features and concepts of the MQTT protocol. This post introduces retained messages. At the end of this blog post you will find a video on the same topic. In MQTT, the client that publishes a message has no guarantee that a subscribing client actually receives the message. The publishing client can only make sure that the message gets delivered safely to the broker. Basically, the same is true for a subscribing client. The client that connects and subscribes to topics has no guarantee on when the publishing client will publish a message in one of their topics of interest. It can take a few seconds, minutes, or hours for the publisher to send a new message in one of the subscribed topics. Until the next message is published, the subscribing client is totally in the dark about the current status of the topic. This situation is where retained messages come into play."
      },
      "part9": {
        "title": "MQTT Essentials: Part 9",
        "body": "Welcome to the ninth part of MQTT Essentials. A ten-part blog series on the core features and concepts of the MQTT protocol. In this post we explain the Last Will and Testament feature of MQTT. At the end of this blog post you will find a video on the same topic. Because MQTT is often used in scenarios that include unreliable networks, it’s reasonable to assume that some of the MQTT clients in these scenarios will occasionally disconnect ungracefully. An ungraceful disconnect can occur due to loss of connection, empty batteries, or many other reasons. Knowing whether a client disconnected gracefully (with an MQTT DISCONNECT message) or ungracefully (without a disconnect message), helps you respond correctly. The Last Will and Testament feature provides a way for clients to respond to ungraceful disconnects in an appropriate way."
      },
      "part10": {
        "title": "MQTT Essentials: Part 10",
        "body": "Welcome to the tenth part of MQTT Essentials, a blog series about the core features and concepts in the MQTT protocol. In this post we talk about the Keep Alive feature of MQTT and why this feature is especially important for mobile networks. This last part of our MQTT Essentials series is also available as video which can be found at the end of this blog post."
      }
    },
    "edgeBanner": {
      "text": "HiveMQ Edge is an open source software gateway designed to address some of the key technical challenges organizations face when connecting industrial devices at the edge, which can easily be bridged to HiveMQ Cloud.",
      "repositoryButtonText": "HiveMQ Edge Github repository",
      "documentationButtonText": "Learn more about HiveMQ Edge"
    }
  },
  "integration": {
    "whatsNew": "What's New",
    "configure": "Configure",
    "comingSoon": "%{name} is a feature that is currently under construction and not yet publicly available. \n We are happy to inform you as soon as this feature is available.",
    "okay": "Okay",
    "search": "Search",
    "summary": "HiveMQ Cloud provides out-of-the-box, fully managed integrations that let you instantly connect your MQTT broker to popular services. With a simple configuration, these integrations let you stream your data effortlessly without ongoing operational burden.",
    "suggest": "can't find an integration you need? Suggest",
    "kafka": {
      "beta": {
        "title": "Beta Test Confluent Cloud Integration Free Until End of August 2023",
        "description": "Confluent Cloud integration is accessible at zero cost in beta phase until August 31st, 2023. After this period, it will shift to a paid model requiring completion of the checkout process for continued use. Upon expiry of the free usage period, we will communicate the required steps via email. If the integration is not checked out, the data flow between the services will be halted. By clicking {enable}, you are consenting to these conditions and agreeing to comply with our present {terms}.",
        "sideEffects": "After selecting Enable, the configuration will be applied which forces a cluster restart. This will lead to reconnects with your MQTT clients.",
        "terms": "terms of service"
      },
      "bootstrapServers": {
        "label": "Bootstrap servers",
        "description": "Insert the list of bootstrap server URLs of the Kafka cluster you want to connect your HiveMQ broker to.",
        "hint": "You can add a single server URL in the format {sample} or add multiple server URLs"
      }
    },
    "options": {
      "confluentKafka": {
        "description": "Stream data between your HiveMQ Cloud broker and your Confluent Cloud Kafka cluster with this fully managed Kafka streaming integration."
      },
      "aivenKafka": {
        "description": "Stream data between your HiveMQ Cloud broker and your Aiven Kafka cluster with this fully managed Kafka streaming integration."
      },
      "customKafka": {
        "description": "Stream data between your HiveMQ Cloud broker and your Kafka cluster with this fully managed Kafka streaming integration."
      },
      "amazonKinesis": {
        "description": "This extension supports communication between IoT devices to Amazon Kinesis Data Streams."
      }
    }
  },
  "acl": {
    "accessCredential": {
      "title": "Credentials",
      "description": "Currently you have not created any credentials. Fill out the following form to create an access credentails pair and limit access to your HiveMQ Cloud MQTT instance. \n To learn more",
      "descriptionAfterCreation": "Define one or more sets of credentials that allow MQTT clients to connect to your HiveMQ Cloud cluster. \n To learn more",
      "urlText": "check out our Security Fundamentals guide.",
      "table": {
        "username": "Username",
        "permissionType": "Permission type",
        "actions": "Actions",
        "emptyTable": "You do not have any credentials at the moment"
      },
      "defaultPermissions": {
        "subscribeOnly": {
          "name": "Subscribe Only",
          "description": "Clients that authenticate with credentials that have this permission assigned are allowed to subscribe to the entire topic tree but are not allow to publish any message."
        },
        "publishOnly": {
          "name": "Publish Only",
          "description": "Clients that authenticate with credentials that have this permission assigned are allowed to publish with any topic but are not allow to subscribe to any topic."
        },
        "publishAndSubscribe": {
          "name": "Publish and Subscribe",
          "description": "Clients that authenticate with credentials that have this permission assigned are allowed to publish and subscribe with any topic. They have full access to all topics."
        }
      }
    },
    "customPermissions": {
      "title": "Permissions",
      "description": "Define a custom permission that restricts the access of an MQTT client at the cluster. Assign a descriptive name and a description to the permission so you remember about the specifics of this permission later on. The name will be used as identifier. \n You can create a permission that explicitly allows or denies a specific action at the broker.",
      "table": {
        "permission": "Permission",
        "description": "Description",
        "permissionType": "Permission",
        "topicFilter": "Topic Filter",
        "actions": "Actions"
      },
      "publish": {
        "key": "Publish",
        "value": "Only allows the client to publish"
      },
      "subscribe": {
        "key": "Subscribe",
        "value": "Only allows the client to subscribe"
      },
      "pubSub": {
        "key": "Publish and Subscribe",
        "value": "Allows the client to publish and subscribe"
      },
      "topicFilter": {
        "key": "Topic Filter",
        "value": "The permission is applied only to the topics defined in this field. You can use a topic filter (including wildcards) to include subtrees into this permission."
      }
    },
    "actions": {
      "createPermission": "Create Permission",
      "delete": "Delete",
      "createCredentials": "Create Credential"
    },
    "modal": {
      "permissionDelete": "Delete Permission",
      "allowPermissionDelete": "You are about to permanently delete {name}. \nThis action cannot be undone. \nAre your sure you want to delete this permission?",
      "rejectPermissionDelete": "Custom permissions can only be deleted if they are no longer assigned to any credential. \n{name} is currently used by some credentials. \nPlease delete or update all credentials using this permission first.",
      "credentialDelete": "Delete Credential",
      "allowCredentialDelete": "You are about to permanently delete {name}. \nClient(s) using this credential would no longer be able to use them to connect to your server \nThis action cannot be undone. \nAre your sure you want to delete this credential?",
      "rejectPermissionCreation": "Custom Permission Creation Failed",
      "rejectCredentialCreation": "Credential Creation Failed"
    }
  },
  "accessManagement": {
    "credentials": {
      "removeDialog": {
        "body": "Removing these credentials will prevent all your clients using them to\n access your cluster.",
        "yes": "Yes, remove credentials",
        "areYouSure": "Are you sure?"
      },
      "title": "Access Credentials",
      "body": {
        "collapsed": "Use username and password for your clients to establish connections with your cluster.",
        "expanded": "Define one or more sets of credentials for MQTT clients to establish connections with your cluster. These credentials serve as authentication information for client authentication. Additionally, you can assign roles to these credentials, specifying the level of access and permissions for the MQTT clients."
      },
      "emptyState": {
        "title": "Let's Get You Logged In",
        "body": "It seems like you havn't set up your username and password yet. No worries, we'll guide you through the process step by step. Setting up the credentials is quick and easy."
      },
      "username": "Username",
      "password": "Password",
      "confirmPassword": "Confirm Password",
      "roles": "Roles",
      "addNew": "Add new credentials",
      "add": "Add credentials"
    },
    "certificate": {
      "title": "Client Certificate",
      "body": {
        "collapsed": "Allow your clients to prove their identity and gain access to your cluster using digitally-signed certificates.",
        "expanded": "Manage client access to your cluster through digitally-signed certificates. Here, what we refer to as 'client certificate' is essentially your public key certificate, identifying your root Certificate Authority (CA). This allows clients to robustly prove their identity. You can easily add new certificates or remove existing ones to ensure secure access."
      },
      "emptyState": {
        "title": "Secure Your Account with a Client Certificate?",
        "body": "You haven't added a client certificate yet, but that's okay! Client certificates add an extra layer of security to your account. Let's\n          walk through how to set one up to keep your account safe and secure."
      },
      "removeDialog": {
        "body": "Removing the {identifier} credential will prevent all your clients using it to access your cluster.",
        "yes": "Yes, remove certificate",
        "areYouSure": "Are you sure?"
      },
      "bannerText": "Your current Authentication method is Certificate Authentication, to use Access Credentials, please delete your current Certificate",
      "page": {
        "role": "Role",
        "description": "Add your public key certificate identifying your root certificate authority (CA).",
        "dragHint": "Attach certificate by dragging & dropping here, or click here to upload a certificate.",
        "error": "There was a problem creating the Certificate Authentication, please confirm that the certificate is a supported type or try again later",
        "noCertificate": "You don’t have a certificate yet",
        "notSupported": "Roles and permissions using Certificate Authentication are not supported yet.",
        "certificateName": "Certificate Name",
        "certificatePlaceholder": "Paste or Upload an X509 Public Certificate or Chain Certificate here. Usually beginning with  ‘-----BEGIN TRUSTED CERTIFICATE-----’ or `-----BEGIN CERTIFICATE-----`",
        "namePlaceholder": "Enter a name for your certificate",
        "name": "Name",
        "key": "Key"
      },
      "actions": {
        "addCertificate": "Add Certificate"
      },
      "modal": {
        "deleteCertificateDescription": "You are about to permanently delete {name}. \nClient(s) using this certificate will lose connectivity. \nAre your sure you want to delete this certificate?",
        "deleteCertificate": "Delete Certificate",
        "deleteError": "There was an error deleting, please try again later"
      }
    },
    "jwt": {
      "title": "JSON Web Token",
      "body": {
        "collapsed": "Allow your clients to verify their identity and access your cluster using compact, URL-safe tokens.",
        "expanded": "Enhance the security and manageability of your cluster access with JWT (JSON Web Tokens). These compact, URL-safe tokens are used by your clients to verify identity and gain access. In this section, you'll be able to provide a JWKS (JSON Web Key Set) endpoint for token validation and manage a list of claims that define the parameters of access. Streamline your security process with this robust, flexible method."
      },
      "emptyState": {
        "title": "Ready to Use JSON Web Token for Secure Authentication?",
        "body": "Looks like you haven't configured a JSON Web Token (JWT) yet. JWTs are great for securely managing sessions and we're here to help you implement it. Let’s get started!"
      },
      "removeDialog": {
        "body": "Removing the JWT configuration will prevent all your clients using it to access your cluster.",
        "yes": "Yes, remove JWT configuration",
        "areYouSure": "Are you sure?"
      },
      "bannerText": "Your current Authentication method is JWT Authentication, to use Access Credentials, please delete your current JWT Configuration",
      "page": {
        "description": "The JWKS endpoint provides the JWT provider's public keys. HiveMQ Cloud fetches the public keys from this endpoint and uses them to verify the signature of the received token.",
        "descriptionSubtext": "You can create a checklist of specific details (e.g. issuer), called claims. When your devices try to access using a JWT, we'll double-check that their JWT matches the details you've listed. If it doesn't, they won't get access.",
        "hint": "The MQTT client should insert the JWT into the password field of the CONNECT message for authentication.",
        "form": {
          "jwksEndpoint": {
            "label": "JWKS Endpoint",
            "hint": "You need to specify a JWKS endpoint",
            "placeholder": "Please enter your JWKS endpoint URL"
          }
        },
        "saveButton": "Save JWT",
        "dangerZone": {
          "header": "Permanently remove your JWT authentication configuration.",
          "modal": {
            "header": "Delete JWT authentication config",
            "body": "This action removes your JWT authentication config and switches your authentication method back to Credentials, are you sure you want to delete it?\n\n This action {cannot} be undone."
          }
        }
      },
      "table": {
        "header": "Claims",
        "key": {
          "name": "Key",
          "placeholder": "Assign a claim"
        },
        "value": {
          "name": "Value",
          "placeholder": "Enter a value for your claim"
        },
        "empty": "You don't have any key / value pair yet.",
        "add": "Add Claim",
        "update": "Update Claim",
        "delete": "Delete Claim"
      }
    },
    "changeAuthentication": {
      "title": "Change Authentication",
      "createDescription": "Currently, only one authentication method is supported at a time. \nThese methods cannot be used simultaneously. \nYour MQTT clients currently using {previous} would lose connectivity. \nDo you want to proceed?",
      "deleteDescription": "Deleting this certificate will result in Access Credentials becoming your active authentication method. \nDo you want to proceed?"
    },
    "roles": {
      "title": "Roles",
      "body": {
        "collapsed": "Categorize clients based on their designated responsibilities and access levels within the cluster, streamlining the assignment of permissions.",
        "expanded": "Roles streamline access management by grouping clients according to their responsibilities and required access levels. This simplifies assigning specific permissions to each role, ensuring clients have the necessary access to perform their duties, thereby improving efficiency and security."
      },
      "addNew": "Create new role",
      "removeDialog": {
        "body": "Removing this role will remove permissions from your credentials.",
        "yes": "Yes, remove role",
        "areYouSure": "Are you sure?"
      }
    },
    "permissions": {
      "title": "Permissions",
      "body": {
        "collapsed": "Allow or deny specific actions on MQTT topics a role can access or modify, ensuring precise control over cluster interactions.",
        "expanded": "Permissions enable granular control over MQTT topics by defining what actions (publish, subscribe) clients can perform, directly influencing cluster security. This ensures clients have access only to what they need, preventing unauthorized operations and enhancing data security."
      },
      "addNew": "Create new permission",
      "removeDialog": {
        "body": "Removing this permission will remove your defined permission set for future roles.",
        "yes": "Yes, remove permission",
        "areYouSure": "Are you sure?"
      }
    }
  },
  "webclient": {
    "banner": {
      "header": "A quick note on Web Client Access",
      "subtext": "Heads up! At the moment, our web client can only authenticate via {accessCredentials} You'll need to switch methods if you'd like to test with the web client."
    }
  },
  "rbac": {
    "title": "Credentials",
    "bannerText": "Currently, only one authentication method is supported at a time. These methods cannot be used simultaneously.",
    "credentials": {
      "description": "Define one or more sets of credentials for MQTT clients to establish connections with your cluster. These credentials serve as authentication information for client authentication. Additionally, you can assign roles to these credentials, specifying the level of access and permissions for the MQTT clients.",
      "description_zero": "Currently you have not created any credentials. Fill out the following form to create an access credentails pair and limit access to your HiveMQ Cloud MQTT instance. \n To learn more",
      "table": {
        "empty": "You do not have any credential at the moment"
      },
      "actions": {
        "create": "Create Credential"
      },
      "dialog": {
        "title": "Delete Credential",
        "description": "You are about to permanently delete {identifier}.\nClient(s) using this credential would no longer be able to use them to connect to your server."
      },
      "notifications": {
        "created": "Credential has been created",
        "removed": "Credential has been removed"
      }
    },
    "permissions": {
      "title": "Permissions",
      "description": "Define a custom permission that restricts the access of an MQTT client at the cluster. Assign a descriptive name and a description to the permission so you remember about the specifics of this permission later on. The name will be used as identifier. \n You can create a permission that explicitly allows or denies a specific action at the broker.",
      "sidebar": {
        "topicFilter": {
          "title": "Topic filter",
          "description": "The permission is applied only to the topics defined in this field. You can use a topic filter (including wildcards) to include subtrees into this permission."
        },
        "publishSubscribe": {
          "title": "Publish / Subscribe",
          "description": "Specify permissions to control whether a user is allowed to publish and/or subscribe to MQTT topics, granting fine-grained access based on the desired actions and interactions with the topics."
        },
        "qos": {
          "title": "Quality of service",
          "description": "Configure MQTT QoS level permissions to restrict publishers and subscribers from accessing unexpected topics, allowing them to interact only with the expected topics according to their assigned QoS level."
        },
        "retainedMessages": {
          "title": "Retained messages",
          "description": "Persistent messages stored by the broker, delivering the last-known value to late-joining subscribers upon connection."
        },
        "sharedGroup": {
          "title": "Shared group",
          "description": "MQTT shared groups enable multiple clients to collaboratively subscribe, receive, and coordinate communication through a shared topic, promoting efficient and coordinated collaboration among participants."
        }
      },
      "table": {
        "empty": "You do not have created any permissions at the moment"
      },
      "actions": {
        "create": "Create Permission",
        "update": "Update Permission"
      },
      "dialog": {
        "title": "Delete Permission",
        "description": "You are about to permanently delete the permission named {identifier}.\nYou then cannot longer use this permission for new roles.",
        "unmap": "To remove this permission you have to delete all roles that use this permission"
      },
      "notifications": {
        "created": "Permission has been created",
        "removed": "Permission has been removed",
        "updated": "Permission has been updated"
      },
      "httpError": {
        "GONE": "Permission with given name is already taken"
      }
    },
    "roles": {
      "title": "Roles",
      "description": "Create roles with specific permissions, such as read, write, publish, or subscribe, to govern user actions on topics. By assigning roles, access to topics can be effectively managed based on permission responsibilities and requirements.",
      "table": {
        "empty": "You do not have created any roles at the moment"
      },
      "actions": {
        "create": "Create Role",
        "update": "Update role"
      },
      "dialog": {
        "title": "Delete Role",
        "description": "You are about to permanently delete the role named {identifier}.\nYou then cannot longer use this role for new MQTT credentials.",
        "unmap": "Remove all credentials that use this role"
      },
      "notifications": {
        "created": "Role has been created",
        "removed": "Role has been removed",
        "updated": "Role has been updated"
      }
    }
  },
  "profile": {
    "delete": {
      "title": "Delete my HiveMQ Account",
      "description": "This action permanently deletes your HiveMQ account. \n This action cannot be undone. \n Are you sure you want to proceed?",
      "actions": {
        "delete": "Delete Account",
        "logout": "Logout"
      }
    }
  },
  "actions": {
    "loading": "Loading",
    "delete": "Delete",
    "cancel": "Cancel",
    "actions": "Actions",
    "edit": "Edit",
    "confirm": "Confirm",
    "continue": "continue",
    "contactSupport": "Contact support"
  },
  "notification": {
    "wait": "Time for a coffee! ☕",
    "warning": {
      "migration": {
        "title": "HiveMQ Cloud is undergoing scheduled maintenance",
        "description": "We are migrating our service for better performance. We apologize for any inconvenience. For more information, or if you have any questions, please contact us at {0}."
      }
    }
  },
  "meta": {
    "email": {
      "default": "cloud{'@'}hivemq.com"
    },
    "yes": "Yes",
    "no": "No",
    "day": "day | {count} days",
    "cancel": "Cancel",
    "save": "Save",
    "edit": "Edit",
    "hide": "Hide",
    "ok": "ok",
    "logout": "Logout",
    "hour": "hours | hour | hours",
    "message": "messages | message | messages",
    "millionMessages": "million messages",
    "free": "free",
    "legal": {
      "header": "The actual Subscription Fees for SaaS Services will vary depending on your use of the Cloud Services and are calculated as follows:",
      "firstTitle": "Hourly-Based Pricing",
      "firstBody": "You are charged for each started hour the HiveMQ Platform is active: the number of hours the HiveMQ Platform has been running in a month times the hourly base price defined in the Quote.",
      "secondTitle": "Consumption-Based Pricing",
      "secondBody": "You additionally pay a consumption-based fee depending on the number of messages processed by the HiveMQ Platform according to the per-message pricing defined in the Quote. HiveMQ uses a credit card processing service provided by {link} Ltd. to process its payments and as governed by Stripe’s third party terms.",
      "stripeText": "Stripe Payments Europe"
    }
  },
  "validation": {
    "username": {
      "minCharacters": "no min characters | Username must be at least {count} characters"
    }
  },
  "apiTokens": {
    "title": "HiveMQ Cloud API Tokens",
    "description": "Create an API token to access the HiveMQ Cloud API programmatically. Manage MQTT permissions, MQTT roles, and MQTT credentials whenever and wherever you like.",
    "expiryText": "Will expire on {date}",
    "expiryPrefix": "Expires on {date}",
    "indefinite": "Never Expires",
    "input": {
      "hint": {
        "keyName": "Custom key name for you API token",
        "roles": "Set roles to allow or disallow specific access",
        "lifeTime": "Define lifetime of the API token"
      }
    },
    "newToken": {
      "title": "Create new HiveMQ API token",
      "description": "Create an new API token to access the HiveMQ Cloud API programmatically. Manage MQTT permissions, MQTT roles, and MQTT credentials whenever and wherever you like."
    },
    "updateToken": {
      "title": "Update HiveMQ API token",
      "description": "Update an existing API token to access the HiveMQ Cloud API programmatically. Manage MQTT permissions, MQTT roles, and MQTT credentials whenever and wherever you like."
    },
    "newTokenMessage": {
      "title": "New API token \"{keyName}\" created 🎉",
      "description": "Make sure to copy your HiveMQ Cloud API token.",
      "warning": "You will not be able to see it again!"
    },
    "deleteModal": {
      "title": "Delete API Token",
      "subTitle": "Are you sure you want to delete the token: {API_TOKEN_NAME}?  When revoked you will lose access to all HiveMQ Cloud Starter instances.",
      "confirm": "Remove API Token"
    },
    "table": {
      "header": {
        "keyName": "Key name"
      },
      "empty": "You do not have created any API token at the moment"
    },
    "sidebar": {},
    "actions": {
      "create": "Create API token"
    },
    "dialog": {
      "title": "Delete API token",
      "description": "You are about to permanently delete the API token named {identifier}.\nYou then cannot longer use this token to access the HiveMQ Cloud API."
    },
    "notifications": {
      "created": "API token has been created",
      "removed": "API token has been removed",
      "fullAccess": "Setting all permissions would allow full control for this cluster, MQTT roles & permissions"
    },
    "httpError": {
      "GONE": "API token with given name is already taken"
    }
  },
  "topic": {
    "description": {
      "multilevel": "define multi level subscription",
      "singleLevel": "define single level subscription"
    }
  },
  "qos": {
    "description": {
      "QoS0": "QoS 0 (at most once)",
      "QoS1": "QoS 1 (at least once)",
      "QoS2": "QoS 2 (exactly once)"
    }
  },
  "input": {
    "label": {
      "description": "Description",
      "name": "Name",
      "firstName": "First name",
      "lastName": "Last name",
      "password": "Password",
      "passwordRepeat": "Confirm Password",
      "permission": "Permission | Permissions",
      "permissionType": "Permission Type | Permission Types",
      "role": "Role | Roles",
      "lifeTime": "Lifetime",
      "expiration": "Expiration",
      "topic": "Topic",
      "topicFilter": "Topic Filter",
      "username": "Username",
      "sharedGroup": "Shared group topic",
      "allowPublish": "Allow publish",
      "allowSubscribe": "Allow subscribe",
      "allowQoS0": "Allow QoS 0",
      "allowQoS1": "Allow QoS 1",
      "allowQoS2": "Allow QoS 2",
      "allowRetainedMessages": "Allow retained messages",
      "allowSharedSubscription": "Allow shared subscription",
      "confirmInput": "Confirm"
    },
    "hint": {
      "description": "Custom description that explains your concern",
      "name": "At least 5 characters",
      "password": "At least 8 characters, 1 digit, 1 uppercase character",
      "passwordRepeat": "Passwords must match",
      "permission": "Add permissions to limit access",
      "permissionName": "Add a name for your permission",
      "permissionType": "Choose a permission type",
      "permissionTopicFilter": "Assign a topic filter to this permission",
      "permissionDescription": "Add a description of your permission",
      "lifeTime": "How long should the token live",
      "role": "Add roles to limit MQTT access",
      "topic": "Valid MQTT topic",
      "username": "At least 5 characters",
      "confirmInput": "Type in the above text"
    },
    "dangerousAction": {
      "cannotBeUndone": "This action cannot be undone.",
      "confirmText": "Please type {0} into the below field to confirm this action."
    }
  },
  "table": {
    "header": {
      "name": "Name",
      "username": "Username",
      "roles": "Roles",
      "permission": "Permission | Permissions",
      "description": "Description",
      "topicFilter": "Topic Filter",
      "actions": "Actions",
      "id": "ID",
      "createdAt": "Creation date",
      "expireAt": "Expiry date",
      "topic": "Topic",
      "publishAllowed": "Publish",
      "subscribeAllowed": "Subscribe",
      "retainedMessagesAllowed": "Retained messages",
      "sharedGroup": "Shared Group",
      "sharedSubscribeAllowed": "Shared subscribe allowed",
      "qos0Allowed": "QoS 0",
      "qos1Allowed": "QoS 1",
      "qos2Allowed": "QoS 2"
    }
  },
  "httpError": {
    "BAD_REQUEST": "We apologize for the inconvenience. Please ensure that your request is properly formatted and all necessary information is included. Double-check your request and try resending it.",
    "FORBIDDEN": "Access to the requested resource is forbidden. Please ensure you have the necessary permissions to access it. Double-check your credentials and permissions to resolve the issue.",
    "UNKNOWN": "We apologize for the inconvenience, but an unknown error occurred while processing your request. Please retry your request later or contact our support team for further assistance."
  }
}
