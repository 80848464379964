import {
  LogOut,
  Cloud,
  User,
  UserRoundCog,
  CircleHelp,
  BookOpenText,
  MessageSquareQuote,
  PartyPopper,
  Rocket,
} from 'lucide-vue-next'
import { RouteLocationRaw } from 'vue-router'

import routeNames from '@/router/routeNames'

export type NavigationItem = {
  icon?: string | any
  title: string
  to?: {
    name: RouteLocationRaw
  }
  onClickEmitName: `click-${string}`
  onClick?: () => void
  'data-testid'?: string
}

export type ClusterNavigationSubItemData = {
  clusterUuid: string
  clusterType: string
  name?: string
  onClick?: () => void
}

export const navigationClusterItems: ReadonlyArray<NavigationItem> = [
  {
    icon: Cloud,
    title: 'Clusters',
    to: { name: routeNames.authenticated.clusters.index },
    onClickEmitName: 'click-clusters',
    'data-testid': 'sidebar-goto-clusters',
  },
] as const

export const organizationSidebarItems: ReadonlyArray<NavigationItem> = [
  {
    icon: UserRoundCog,
    title: 'Settings',
    to: { name: routeNames.authenticated.organisation.settings },
    onClickEmitName: 'click-orgs',
    'data-testid': 'sidebar-orgs',
  },
]

export const onboardingActionItems: ReadonlyArray<NavigationItem> = [
  {
    icon: Rocket,
    title: 'Onboarding',
    onClickEmitName: 'click-onboarding',
  },
]

export const navigationActionItems: ReadonlyArray<NavigationItem> = [
  {
    icon: CircleHelp,
    title: 'Help',
    to: { name: routeNames.authenticated.help },
    onClickEmitName: 'click-help',
    'data-testid': 'sidebar-goto-help',
  },
  {
    icon: BookOpenText,
    title: 'Documentation',
    onClickEmitName: 'click-documentation',
    'data-testid': 'sidebar-goto-documentation',
  },
  {
    icon: MessageSquareQuote,
    title: 'Feedback',
    onClickEmitName: 'click-feedback',
    'data-testid': 'sidebar-feedback',
  },
  {
    icon: User,
    title: 'Profile',
    onClickEmitName: 'click-account',
    to: {
      name: routeNames.authenticated.profile.index,
    },
    'data-testid': 'sidebar-account',
  },
  {
    icon: LogOut,
    title: 'Logout',
    onClickEmitName: 'click-logout',
    'data-testid': 'sidebar-account',
  },
] as const

export const navigationActionItemsWithRelease: ReadonlyArray<NavigationItem> = [
  {
    icon: PartyPopper,
    title: "What's new",
    to: { name: routeNames.authenticated.release },
    onClickEmitName: 'click-release',
    'data-testid': 'sidebar-goto-help',
  },
  ...navigationActionItems,
]
