import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import { ThemeDefinition, createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Color definition: https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=ffbf00&secondary.color=210b64
const hmqLight: ThemeDefinition = {
  dark: false,
  colors: {
    surface: '#FFFFFF',

    // background: '#fffefc',

    primary: '#ffc000',
    primaryLighten100: '#ffebb2',
    primaryLighten200: '#ffdf81',
    primaryLighten300: '#ffd44d',
    purpleLighten300: '#717fff',
    secondary: '#495057',
    accent: '#9c9bcc',
    error: '#d84646',
    warning: '#f57c00',
    info: '#0288d1',
    'info-text': '#263238',
    success: '#4e9a51',

    brandSecondaryDarkPurple: '#210b64',
    brandSecondaryDarkBlue: '#003e6e',
  },
}

export default createVuetify({
  theme: {
    defaultTheme: 'hmqLight',
    themes: {
      hmqLight,
    },
  },
  display: {
    mobileBreakpoint: 'sm',
    thresholds: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1440,
      xxl: 1920,
    },
  },
  defaults: {
    global: {
      ripple: false,
    },
    VTextField: {
      variant: 'outlined',
    },
    VBtn: {
      flat: true,
      rounded: 'sm',
    },
    VComboBox: {
      variant: 'outlined',
      dense: true,
    },
    VAutocomplete: {
      variant: 'outlined',
      dense: true,
    },
    VTextarea: {
      variant: 'outlined',
      dense: true,
    },
    VSelect: {
      variant: 'outlined',
      dense: true,
    },
    VFileInput: {
      variant: 'outlined',
      dense: true,
    },
    VCheckbox: {
      color: 'info',
      hideDetails: true,
      density: 'compact',
    },
  },
  components,
  directives,
})
