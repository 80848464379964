<template>
  <v-card v-if="isVersionDifferent" class="d-flex flex-column container">
    <v-card-title class="d-flex justify-space-between align-center">
      <span class="d-inline-block">{{ title }}</span>
      <v-btn icon @click="closeModal" class="ml-6">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      {{ content }}
    </v-card-text>
    <v-row class="d-flex align-self-end button-container">
      <v-btn
        color="primary"
        :to="{ name: routeNames.authenticated.release }"
        @click="trackEvent(events.hiveMQCloud.release.learnMoreSelected)"
      >
        learn more
      </v-btn>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import * as Data from '../../../../app/pages/ReleaseNotes/ReleaseTemplate.md'

import {
  isVersionDifferent,
  setToCurrentVersion,
} from '@/modules/release/utils/storage'
import { useTracking } from '@/modules/tracking/hooks/useTracking'
import routeNames from '@/router/routeNames'

export default defineComponent({
  name: 'ReleaseNotification',
  computed: {
    routeNames() {
      return routeNames
    },
  },
  setup() {
    const { trackEvent, events } = useTracking()
    const title = "What's New 🎉"

    // Temporary solution as the current solution can't handle frontmatter
    var data = Data.default.toString()
    // eslint-disable-next-line no-useless-escape
    var data2 = data.split('[\/\/]: \# (LATEST_FEATURE)')

    var content = data2[0].split('\n')[8]

    async function closeModal() {
      trackEvent(events.hiveMQCloud.release.learnMoreClosed)
      setToCurrentVersion()
    }

    return {
      title,
      content,
      events,
      isVersionDifferent,
      trackEvent,
      closeModal,
    }
  },
})
</script>

<style scoped lang="scss">
@keyframes whats-new-fade-in {
  from {
    opacity: 0;
    transform: rotate(0deg);
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 1;
    transform: rotate(2deg);
  }
}

.container {
  opacity: 0;
  margin: 0.5rem;
  padding: 0.4rem;

  animation: whats-new-fade-in 300ms 1s ease-out forwards;

  @media (prefers-reduced-motion) {
    opacity: 1;
    animation: none;
    transform: rotate(2deg);
  }
}

.button-container {
  margin: 0.7rem;
}
</style>
