export default {
  authenticated: {
    apiToken: {
      index: 'api-token',
    },
    billing: 'billing',
    billingExternal: 'billingExternal',
    profile: {
      index: 'profile',
    },
    organisation: {
      index: 'orgs',
      settings: 'settings',
      members: 'members',
    },
    clients: {
      arduinoEsp8266: 'arduino-esp8266',
      javaHivemq: 'java-hivemq',
      mosquitto: 'mosquitto-cli',
      mqtt: 'mqtt-cli',
      mqttDart: 'mqtt-dart',
      mqttfx: 'mqttfx',
      mqttJs: 'mqtt-js',
      pahoC: 'paho-c',
      pahoGo: 'paho-go',
      pythonPaho: 'python-paho',
      websocketJava: 'websocket-java',
      websocketWeb: 'websocket-web',
    },
    clusters: {
      create: 'create-cluster',
      createConfigure: 'create-cluster-configure',
      details: {
        accessManagement: 'access-management',
        credentials: {
          accessCredentials: 'cluster-details-credentials-rbac',
          certificateAuthentication: 'cluster-details-credentials-certificate',
          jwtAuthentication: 'cluster-details-credentials-jwt',
        },
        gettingStarted: 'cluster-details-getting-started',
        index: 'cluster-details',
        integrations: 'cluster-details-integrations',
        upgrade: 'cluster-details-upgrade',
        webClient: 'cluster-details-web-client',
        dns: 'cluster-custom-dns',
        troubleshooting: 'cluster-details-troubleshooting',
        apiToken: {
          index: 'cluster-details-api-token',
          new: 'cluster-details-api-token-new',
          details: 'cluster-details-api-token-details',
        },
      },
      index: 'clusters',
      integrations: {
        kafka: {
          confluent: 'cluster-details-integrations-kafka-confluent',
          aiven: 'cluster-details-integrations-kafka-aiven',
          custom: 'cluster-details-integrations-kafka-custom',
          generic: 'cluster-details-integrations-kafka-generic',
        },
        amazonKinesis: {
          default: 'cluster-details-integrations-amazonKinesis',
        },
      },
    },
    help: 'help',
    notFound: 'NotFound',
    paymentHistory: 'payment history',
    profiles: {
      complete: 'complete-profile',
    },
    release: 'release',
    verifyEmail: 'verify-email',
    welcome: 'welcome',
  },
} as const
