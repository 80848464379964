import EventEmitter from 'events'

const OnboardingEventEmitter = new EventEmitter()

export enum OnboardingEvents {
  CLUSTER_CREATING = 'CLUSTER_CREATING',
  CLUSTER_CREATION_FAILED = 'CLUSTER_CREATION_FAILED',
  CLUSTER_CREATED = 'CLUSTER_CREATED',
  CLUSTER_CREATION_SUCCESSFUL = 'CLUSTER_CREATION_SUCCESSFUL',
  CLUSTER_CREATION_SUCCESSFUL_NEXT = 'CLUSTER_CREATION_SUCCESSFUL_NEXT',
  GENERATE_ACCESS_CREDENTIALS = 'GENERATE_ACCESS_CREDENTIALS',
  ADD_ACCESS_CREDENTIALS = 'ADD_ACCESS_CREDENTIALS',
  CREDENTIALS_ADDED = 'CREDENTIALS_ADDED',
  CREDENTIALS_SUCCESSFUL = 'CREDENTIALS_SUCCESSFUL',
  CONNECT_WEB_CLIENT = 'CONNECT_WEB_CLIENT',
  CONNECT_WEB_CLIENT_SUCCESSFUL = 'CONNECT_WEB_CLIENT_SUCCESSFUL',
  PUBLISH_TEST_MESSAGE = 'PUBLISH_TEST_MESSAGE',
  CREATE_CLUSTER_CONFIGURATION_SUBMIT = 'CREATE_CLUSTER_CONFIGURATION_SUBMIT',
  PUBLISH_TEST_MESSAGE_SUCCESSFUL = 'PUBLISH_TEST_MESSAGE_SUCCESSFUL',
  /**
   * WATCH OUT! Dirty Code! ☣️
   * ---
   * TODO: Remove once we have moved to React only
   * Right now we need a way to communicate between Vue / Pinia and React
   * The only way we saw at the time was to emit an event and react to it in React to
   * enable customers to jump between individual steps
   */
  __STEP_CHANGE = '__STEP_CHANGE',
}

export function useOnboardingEvents() {
  function subscribe(
    eventName: OnboardingEvents,
    listener: (...data: any) => void
  ) {
    return OnboardingEventEmitter.on(eventName, listener)
  }

  function unsubscribe(
    eventName: OnboardingEvents,
    listener: (...data: any) => void
  ) {
    OnboardingEventEmitter.off(eventName, listener)
  }

  function publish(eventName: OnboardingEvents, ...data: any) {
    OnboardingEventEmitter.emit(eventName, ...data)
  }

  return {
    subscribe,
    unsubscribe,
    publish,
  }
}
