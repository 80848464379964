import axios from 'axios'
import { isRef, nextTick } from 'vue'
import {
  Composer,
  createI18n,
  I18n,
  I18nMode,
  I18nOptions,
  Locale,
  VueI18n,
} from 'vue-i18n'

import { datetimeFormats } from '@/utils/i18nFormat/datetimeFormats'
import { numberFormats } from '@/utils/i18nFormat/numberFormats'

import enUS from '@/locales/en-US.json'

export const SUPPORT_LOCALES = ['en-US']

function isComposer(
  instance: VueI18n | Composer,
  mode: I18nMode
): instance is Composer {
  return mode === 'composition' && isRef(instance.locale)
}

export function getLocale(i18n: I18n): string {
  if (isComposer(i18n.global, i18n.mode)) {
    return i18n.global.locale.value
  }
  return i18n.global.locale
}

export function setLocale(i18n: I18n, locale: Locale): void {
  if (isComposer(i18n.global, i18n.mode)) {
    i18n.global.locale.value = locale
  } else {
    i18n.global.locale = locale
  }
}

export function setupI18n(locale: string): I18n {
  const i18n = createI18n({
    legacy: false,
    locale,
    fallbackLocale: 'en-US',
    numberFormats,
    datetimeFormats,
    messages: {
      'en-US': enUS,
    },
  } as I18nOptions)

  setI18nLanguage(i18n, locale)
  return i18n
}

export function setI18nLanguage(i18n: I18n, locale: Locale): void {
  setLocale(i18n, locale)
  axios.defaults.headers.common['Accept-Language'] = locale
  document.querySelector('html')!.setAttribute('lang', locale)
}

export async function loadLocaleMessages(i18n: I18n, locale: Locale) {
  const messages = await import(`./locales/${locale}.json`)
  i18n.global.setLocaleMessage(locale, messages.default || messages)
  return nextTick()
}
