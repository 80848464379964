import { MaybeRef, useAsyncState } from '@vueuse/core'
import { unref } from 'vue'

import {
  HmqErrorResponse,
  HmqResponse,
  toHmqResponse,
  toHmqResponseError,
  toInitialHmqResponseState,
} from '@/utils/http/httpResponseUtils'

import { $http, $httpV2 } from '@/clients/consoleAxiosClient'
import { useGetClusterRequestData } from '@/modules/cluster/hooks/useIsPackageType'

const initialState = {}

export function useDeleteCluster(clusterUuid: MaybeRef<string>) {
  return useAsyncState<HmqResponse<{}> | HmqErrorResponse<{}>>(async () => {
    const { accountOrgName, isStarter } = useGetClusterRequestData()

    if (isStarter) {
      return $httpV2
        .delete(`orgs/${accountOrgName}/clusters/${unref(clusterUuid)}`)
        .then(toHmqResponse)
        .catch((error) => toHmqResponseError(error, initialState))
    }

    return $http
      .delete(`clusters/${unref(clusterUuid)}`)
      .then(toHmqResponse)
      .catch((error) => toHmqResponseError(error, initialState))
  }, toInitialHmqResponseState(initialState))
}
