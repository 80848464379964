import { useAsyncState } from '@vueuse/core'
import { AxiosResponse } from 'axios'

import {
  DEFAULT_HTTP_HOOK_OPTIONS,
  HttpHookOptions,
  httpNoCacheHeaderConfig,
} from '@/utils/http/httpUtils'

import { $http } from '@/clients/consoleAxiosClient'
import {
  Account,
  AccountCreate,
  Address,
} from '@/modules/billing/@types/payment.types'
import { removeSkippedAccountPayload } from '@/modules/billing/deprecated/sanitizeAccountPayload'

export function useAccount() {
  function getAccount() {
    return useAsyncState<Account>(async () => {
      return $http
        .get(`account`)
        .then((response) => removeSkippedAccountPayload(response.data))
    }, {} as Account)
  }

  function postAccount(account: AccountCreate) {
    return useAsyncState<AxiosResponse>(async () => {
      return $http.post(`account`, account).then((response) => response)
    }, {} as AxiosResponse)
  }

  function getAddress(options: HttpHookOptions = DEFAULT_HTTP_HOOK_OPTIONS) {
    return useAsyncState<Address>(async () => {
      return $http
        .get(
          `account/address`,
          options.noCache ? httpNoCacheHeaderConfig : undefined
        )
        .then((response) => removeSkippedAccountPayload(response.data))
    }, {} as Address)
  }

  function postAddress(address: Address) {
    return useAsyncState<AxiosResponse>(async () => {
      return $http
        .post(`account/billing-information`, address)
        .then((response) => response)
    }, {} as AxiosResponse)
  }

  function updateAddress(address: Address) {
    return useAsyncState<AxiosResponse>(async () => {
      return $http
        .put(`account/billing-information`, address)
        .then((response) => response)
    }, {} as AxiosResponse)
  }

  return {
    getAccount,
    postAccount,
    getAddress,
    postAddress,
    updateAddress,
  }
}
