import {
  ChakraProvider,
  extendTheme,
  defineStyle,
  defineStyleConfig,
} from '@chakra-ui/react'
import { colors, components, styles, fonts } from '@hivemq/ui-theme'
import React from 'react'

const DefaultLinkVariant = defineStyle({
  color: '#007da5',
})

const LinkComponent = defineStyleConfig({
  baseStyle: DefaultLinkVariant,
})

const DrawerComponent = defineStyleConfig({
  variants: {
    permanent: {
      parts: ['dialog, dialogContainer'],
      dialog: {
        pointerEvents: 'auto',
      },
      dialogContainer: {
        pointerEvents: 'none',
      },
    },
  },
})

const theme = extendTheme({
  colors,
  components: {
    Link: LinkComponent,
    Drawer: DrawerComponent,
    ...components,
  },
  styles,
  fonts,
})

export function ReactMigrationLayout({
  children,
}: React.PropsWithChildren<{}>) {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>
}
