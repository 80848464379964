import {
  OnboardingEvents,
  useOnboardingEvents,
} from '@app/hooks/useOnboardingEvents'
import { ExternalLink } from '@app/molecules/ExternalLink/ExternalLink'
import { ReactMigrationLayout } from '@app/templates/ReactMigrationLayout'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Heading,
  HStack,
  Icon,
  Image,
  Link,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Tag,
  Text,
  UnorderedList,
  useSteps,
  VStack,
} from '@chakra-ui/react'
import { CheckIcon } from 'lucide-react'
import * as React from 'react'
import { ReactNode, useEffect, useState } from 'react'

import {
  NEW_ONBOARDING_TARGET_NAME,
  OnboardingStoreEvents,
  useOnboardingStore,
} from '@/store/onboardingStore'

import ClusterOnboardingSuccess from '@/assets/cluster-onboarding-success.svg'

import { defineVueContext } from '@/react-migration/defineVueContext'
import { sleep } from '@/tests/utils/timers'

type Props = {
  isOpen: boolean
  onClose?: () => {}
  onOpen?: () => {}
  handleEvent: (eventType: OnboardingStoreEvents) => void
}

export function OnboardingSidebar({
  isOpen,
  onOpen,
  onClose,
  handleEvent,
}: Props) {
  return (
    <>
      <ReactMigrationLayout>
        {/* @ts-ignore */}
        <Drawer
          size={'sm'}
          variant="permanent"
          isOpen={isOpen}
          placement="right"
          onOpen={onOpen}
          onClose={onClose}
          trapFocus={false}
          blockScrollOnMount={false}
        >
          <DrawerContent
            backgroundColor="#fff"
            boxShadow="lg"
            containerProps={{
              h: 'auto',
              top: '78px',
              bottom: '12px',
            }}
            style={{
              position: 'absolute',
            }}
            sx={{
              border: '1px solid #f0f0f0',
              borderRadius: '10px 0 0 10px',
            }}
          >
            <DrawerCloseButton mt={4} mr={6} />
            <DrawerHeader fontSize={32} pb={0}>
              <Heading as="span" variant="h1">
                Cluster Quick Start
              </Heading>
            </DrawerHeader>

            <DrawerBody>
              <Text>
                Follow this interactive guide to create your cluster and connect
                your first device in just a few minutes.
              </Text>
              <OnboardingProgressStepper handleEvent={handleEvent} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </ReactMigrationLayout>
    </>
  )
}

type StepData = {
  title: React.ReactNode
  Content: ReactNode
}

type StepNavigation = {
  handleNext: () => void
  handlePrevious?: () => void
  handleEvent: (eventType: OnboardingStoreEvents) => void
  isSuccessfulStep?: (step: number) => boolean
  reset?: () => void
}

type OnboardingProgressStepperProps = {
  handleEvent: (eventType: OnboardingStoreEvents) => void
}

const ONBOARDING_STEPS = {
  S0_CLUSTER_CREATION_STEP: 0,
  S1_SELECT_CONNECTION_STEP: 1,
  S2_CONNECT_YOUR_DEVICE_STEP: 2,
  S3_SEND_FIRST_MESSAGE_STEP: 3,
  S4_SUMMARY_STEP: 4,
} as const

export function OnboardingProgressStepper({
  handleEvent,
}: OnboardingProgressStepperProps) {
  const {
    setStep,
    setSuccessfulStep,
    isSuccessfulStep,
    clusterTargetName,
    clusterSession,
  } = useOnboardingStore()
  const { subscribe } = useOnboardingEvents()

  const [currentStep, setCurrentStep] = useState(clusterSession.currentStep)

  const steps = [
    {
      title: (
        <HStack alignItems="center">
          <span>Create Cluster</span>
          {clusterTargetName !== NEW_ONBOARDING_TARGET_NAME && (
            <Tag
              color="#706e67"
              backgroundColor="#fff"
              borderColor="#e5e3df"
              variant="outline"
            >
              {clusterTargetName}
            </Tag>
          )}
        </HStack>
      ),
      Content: (
        <CreateClusterStep handleNext={nextStep} handleEvent={handleEvent} />
      ),
    },
    {
      title: 'Select Connection Method',
      Content: (
        <SelectConnectionMethodStep
          handleNext={nextStep}
          handleEvent={handleEvent}
          isSuccessfulStep={isSuccessfulStep}
        />
      ),
    },
    {
      title: 'Connect your device',
      Content: (
        <ConnectYourDeviceStep
          handleNext={nextStep}
          handlePrevious={previousStep}
          handleEvent={handleEvent}
        />
      ),
    },
    {
      title: 'Send your first message',
      Content: (
        <SendFirstMessageStep handleNext={nextStep} handleEvent={handleEvent} />
      ),
    },
    {
      title: 'Summary',
      Content: <SummaryStep handleNext={nextStep} handleEvent={handleEvent} />,
    },
  ] satisfies StepData[]

  const { activeStep, goToNext, goToPrevious, isActiveStep, setActiveStep } =
    useSteps({
      index: currentStep,
      count: steps.length,
    })

  subscribe(OnboardingEvents.__STEP_CHANGE, async () => {
    await sleep(10)

    setCurrentStep(clusterSession.currentStep)
    setActiveStep(clusterSession.currentStep)
  })

  subscribe(OnboardingEvents.CLUSTER_CREATION_SUCCESSFUL_NEXT, () => {
    setSuccessfulStep(ONBOARDING_STEPS.S0_CLUSTER_CREATION_STEP)
    setStep(ONBOARDING_STEPS.S1_SELECT_CONNECTION_STEP)
    setActiveStep(ONBOARDING_STEPS.S1_SELECT_CONNECTION_STEP)
  })

  subscribe(OnboardingEvents.CREDENTIALS_SUCCESSFUL, () => {
    setSuccessfulStep(ONBOARDING_STEPS.S1_SELECT_CONNECTION_STEP)
    setStep(ONBOARDING_STEPS.S2_CONNECT_YOUR_DEVICE_STEP)
    setActiveStep(ONBOARDING_STEPS.S2_CONNECT_YOUR_DEVICE_STEP)
  })

  subscribe(OnboardingEvents.CONNECT_WEB_CLIENT_SUCCESSFUL, () => {
    setSuccessfulStep(ONBOARDING_STEPS.S2_CONNECT_YOUR_DEVICE_STEP)
    setStep(ONBOARDING_STEPS.S3_SEND_FIRST_MESSAGE_STEP)
    setActiveStep(ONBOARDING_STEPS.S3_SEND_FIRST_MESSAGE_STEP)
  })

  subscribe(OnboardingEvents.PUBLISH_TEST_MESSAGE_SUCCESSFUL, () => {
    setSuccessfulStep(ONBOARDING_STEPS.S3_SEND_FIRST_MESSAGE_STEP)
    setStep(ONBOARDING_STEPS.S4_SUMMARY_STEP)
    setActiveStep(ONBOARDING_STEPS.S4_SUMMARY_STEP)
  })

  function nextStep() {
    setStep(activeStep + 1)
    goToNext()
  }

  function previousStep() {
    setStep(activeStep - 1)
    goToPrevious()
  }

  return (
    <>
      <Stepper
        index={currentStep}
        orientation="vertical"
        gap={0}
        mt={6}
        width="100%"
      >
        {steps.map((step, index) => {
          const isFirstStep = index === 0

          const isStepCompleted =
            clusterSession.currentSuccessfulSteps.includes(index)
          const isNextStep = clusterSession.currentSuccessfulSteps.includes(
            index - 1
          )

          const isStepInteractive =
            !isFirstStep && (isStepCompleted || isNextStep)

          return (
            <Step key={index}>
              <StepIndicator
                onClick={() => {
                  if (!isStepInteractive) {
                    return
                  }

                  setStep(index)
                }}
                _hover={
                  isStepInteractive ? { opacity: '50%', cursor: 'pointer' } : {}
                }
                borderColor={isStepCompleted ? 'blue.200' : 'inherit'}
              >
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={
                    isStepCompleted ? (
                      <Icon as={CheckIcon} color="blue.700" />
                    ) : (
                      <StepNumber />
                    )
                  }
                  active={<StepNumber />}
                />
              </StepIndicator>

              {/* eslint-disable-next-line no-magic-numbers */}
              <Box width="100%" pb={isActiveStep(index) ? 4 : 0}>
                {/* eslint-disable-next-line no-magic-numbers */}
                <StepTitle
                  mt={0}
                  mb={isActiveStep(index) ? 2 : 6}
                  fontFamily="Roboto"
                  fontWeight={600}
                  color="#171612"
                >
                  {step.title}
                </StepTitle>
                {isActiveStep(index) && <div>{step.Content}</div>}
              </Box>

              <StepSeparator />
            </Step>
          )
        })}
      </Stepper>
    </>
  )
}

export function CreateClusterStep({ handleNext, handleEvent }: StepNavigation) {
  const onboardingStore = useOnboardingStore()

  const steps = [
    {
      title: 'Select plan',
      Content: (
        <SelectPlanSubStep handleNext={nextStep} handleEvent={handleEvent} />
      ),
    },
    {
      title: 'Configure cluster',
      Content: (
        <ConfigureClusterSubStep
          handleNext={handleNext}
          handleEvent={handleEvent}
          reset={resetSubStep}
        />
      ),
    },
  ]

  const { goToNext, isActiveStep, setActiveStep } = useSteps({
    index: onboardingStore.clusterSession.currentClusterCreateStep,
    count: steps.length,
  })

  function nextStep() {
    goToNext()
    onboardingStore.clusterSession.currentClusterCreateStep += 1
  }

  function resetSubStep() {
    setActiveStep(0)
    onboardingStore.clusterSession.currentClusterCreateStep = 0
  }

  return (
    <HStack>
      <Stepper
        listStyleType="disc"
        as={UnorderedList}
        index={onboardingStore.clusterSession.currentClusterCreateStep}
        orientation="vertical"
        gap="0"
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <Box flexShrink="0" width="100%">
              <li>
                <Box mt={0}>{step.title}</Box>
              </li>
              {isActiveStep(index) && <div>{step.Content}</div>}
            </Box>
          </Step>
        ))}
      </Stepper>
    </HStack>
  )
}

export function SelectPlanSubStep({ handleNext, handleEvent }: StepNavigation) {
  return (
    <VStack alignItems="left">
      <Text color="var(--color-text-muted)">
        Select the plan that best suits your needs.
        <br /> We recommend the Starter plan.
      </Text>
      <Button
        variant="primary"
        onClick={() => {
          handleEvent(OnboardingStoreEvents.SELECT_STARTER_PLAN)
          handleNext()
        }}
      >
        Select Starter
      </Button>
      <Link onClick={() => handleEvent(OnboardingStoreEvents.VIEW_ALL_PLANS)}>
        See all plans
      </Link>
    </VStack>
  )
}

export function ConfigureClusterSubStep({ handleEvent }: StepNavigation) {
  const { clusterTargetName } = useOnboardingStore()
  const { subscribe, publish, unsubscribe } = useOnboardingEvents()

  const [isClusterCreating, setIsClusterCreating] = useState(false)
  const [isCreationCompleted, setIsCreationCompleted] = useState(false)

  function handleIsCreating() {
    setIsClusterCreating(true)
  }

  function handleCreationSuccessful() {
    setIsCreationCompleted(true)
  }

  function handleCreationFailure() {
    setIsClusterCreating(false)
  }

  useEffect(() => {
    subscribe(OnboardingEvents.CLUSTER_CREATING, handleIsCreating)

    subscribe(
      OnboardingEvents.CLUSTER_CREATION_SUCCESSFUL,
      handleCreationSuccessful
    )

    subscribe(OnboardingEvents.CLUSTER_CREATION_FAILED, handleCreationFailure)

    return () => {
      unsubscribe(OnboardingEvents.CLUSTER_CREATING, handleIsCreating)

      unsubscribe(
        OnboardingEvents.CLUSTER_CREATION_SUCCESSFUL,
        handleCreationSuccessful
      )

      unsubscribe(
        OnboardingEvents.CLUSTER_CREATION_FAILED,
        handleCreationFailure
      )
    }
  }, [])

  return (
    <VStack alignItems="left">
      <Text color="var(--color-text-muted)">
        {clusterTargetName !== NEW_ONBOARDING_TARGET_NAME ? (
          <>
            Your cluster is being initialized. This takes approximately 3
            minutes.
          </>
        ) : (
          <>
            Confirm the cluster configuration and enter your details in the
            following Stripe screen to start your cluster.
          </>
        )}
      </Text>
      <HStack>
        {clusterTargetName === NEW_ONBOARDING_TARGET_NAME && (
          <Button
            variant="primary"
            onClick={() => {
              handleEvent(OnboardingStoreEvents.CONTINUE_TO_STRIPE)
            }}
            isLoading={isClusterCreating}
            loadingText="Creating Cluster..."
          >
            Continue
          </Button>
        )}

        {clusterTargetName !== NEW_ONBOARDING_TARGET_NAME && (
          <Button
            isLoading={!isCreationCompleted}
            loadingText="Cluster Starting..."
            variant="primary"
            onClick={(event) => {
              event.preventDefault()
              publish(OnboardingEvents.CLUSTER_CREATION_SUCCESSFUL_NEXT)
              handleEvent(OnboardingStoreEvents.GO_TO_ACCESS_MANAGEMENT)
            }}
          >
            {isCreationCompleted
              ? 'Continue to access management'
              : 'Cluster Starting'}
          </Button>
        )}
      </HStack>
    </VStack>
  )
}

export function SelectConnectionMethodStep({ handleEvent }: StepNavigation) {
  const { publish, subscribe, unsubscribe } = useOnboardingEvents()
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)

  async function handleCredentialsAdded() {
    setShowSuccessAlert(true)
    await sleep(3000)
    publish(OnboardingEvents.CREDENTIALS_SUCCESSFUL)
    handleEvent(OnboardingStoreEvents.USE_WEBCLIENT)
  }

  useEffect(() => {
    subscribe(OnboardingEvents.CREDENTIALS_ADDED, handleCredentialsAdded)
    return () =>
      unsubscribe(OnboardingEvents.CREDENTIALS_ADDED, handleCredentialsAdded)
  }, [])

  return (
    <VStack alignItems="left">
      <Text color="var(--color-text-muted)">
        You can generate credentials to test your connection or create custom
        credentials.
      </Text>
      {!showSuccessAlert && (
        <HStack>
          <>
            <Button
              variant="primary"
              onClick={() => handleEvent(OnboardingStoreEvents.ADD_CREDENTIALS)}
            >
              Add Credentials
            </Button>
            <Button
              variant="secondary"
              onClick={() =>
                handleEvent(OnboardingStoreEvents.GENERATE_CREDENTIALS)
              }
            >
              Generate credentials
            </Button>
          </>
        </HStack>
      )}
      {showSuccessAlert && (
        <HStack>
          <Alert status="success">
            <AlertIcon />
            <Box>
              <AlertTitle>Success!</AlertTitle>
              <AlertDescription>Credentials Added</AlertDescription>
            </Box>
          </Alert>
        </HStack>
      )}

      <ExternalLink href="https://app.storylane.io/share/azooemhzzhjb">
        Watch a quick demo
      </ExternalLink>
    </VStack>
  )
}

export function ConnectYourDeviceStep({ handleEvent }: StepNavigation) {
  const { publish } = useOnboardingEvents()

  return (
    <>
      <Text color="var(--color-text-muted)" mb={4}>
        Let's get started by connecting your first device! Feel free to use our
        Web Client to explore MQTT and learn how it works.
      </Text>

      <Button
        variant="primary"
        onClick={() => publish(OnboardingEvents.CONNECT_WEB_CLIENT)}
      >
        Connect Web Client
      </Button>
      {/*  FIXME ENABLE AS SOON AS MARKETING MATERIAL IS THERE*/}
      {/*<Button variant="secondary">With a real device</Button>*/}
    </>
  )
}

export function SendFirstMessageStep({ handleEvent }: StepNavigation) {
  return (
    <>
      <VStack alignItems="left">
        <Text color="var(--color-text-muted)">
          We've pre-filled the message and topic for you. You can click publish
          to use our values or customize them as needed.
          <br />
          Please make sure that the Web Client is connected.
        </Text>
        <HStack>
          <Button
            variant="primary"
            onClick={() =>
              handleEvent(OnboardingStoreEvents.PUBLISH_TEST_MESSAGE)
            }
          >
            Publish Test Message
          </Button>
        </HStack>
        <ExternalLink href="https://app.storylane.io/share/zhqsfcxlmhgc">
          Watch a quick demo
        </ExternalLink>
      </VStack>
    </>
  )
}

export function SummaryStep({ handleEvent }: StepNavigation) {
  return (
    <VStack alignItems="left">
      <Text color="var(--color-text-muted)">
        Congratulations! You've successfully completed the setup of your
        cluster.
      </Text>
      <Box size="sm">
        <Image src={ClusterOnboardingSuccess}></Image>
      </Box>
      <HStack>
        <Button
          variant="primary"
          onClick={() => handleEvent(OnboardingStoreEvents.MANAGE_CLUSTER)}
        >
          Manage Cluster
        </Button>
        <Button
          variant="secondary"
          onClick={() =>
            handleEvent(OnboardingStoreEvents.BACK_TO_CLUSTER_LIST)
          }
        >
          Back to the cluster list
        </Button>
      </HStack>
    </VStack>
  )
}

export default defineVueContext(OnboardingSidebar)
