import { useLocalStorage } from '@vueuse/core'
import { gt as compareVersions, eq as equalVersion } from 'semver'
import { computed } from 'vue'

//@ts-expect-error -json should be imported with special command
import { version } from '@/../package.json'

export const previousVersion = useLocalStorage('application-version', {
  lastSeenVersion: '0.0.0',
})

export function setToCurrentVersion() {
  previousVersion.value.lastSeenVersion = version
}

export const isVersionDifferent = computed(() => {
  // Deactivated for now, because we do not want to highlight
  return false
  // compareVersions(version, previousVersion.value.lastSeenVersion)
})

export function isCurrentVersion(testVersion: string): boolean {
  return equalVersion(version, testVersion)
}
