import React__default, {
  createElement,
  Fragment,
  version,
  Component,
  forwardRef,
  lazy,
  createContext,
  useContext,
} from 'react'
import ReactDOM, { createPortal } from 'react-dom'
import {
  reactive,
  getCurrentInstance,
  h,
  Fragment as Fragment$1,
  Teleport,
  createApp,
  defineAsyncComponent,
  provide,
  inject,
  Text,
  Comment,
} from 'vue'
function _callSuper(e, t, r) {
  return (
    (t = _getPrototypeOf(t)),
    _possibleConstructorReturn(
      e,
      _isNativeReflectConstruct()
        ? Reflect.construct(t, r || [], _getPrototypeOf(e).constructor)
        : t.apply(e, r)
    )
  )
}
function _isNativeReflectConstruct() {
  try {
    var e = !Boolean.prototype.valueOf.call(
      Reflect.construct(Boolean, [], function () {})
    )
  } catch (e) {}
  return (_isNativeReflectConstruct = function () {
    return !!e
  })()
}
function _iterableToArrayLimit(e, t) {
  var r =
    null == e
      ? null
      : ('undefined' != typeof Symbol && e[Symbol.iterator]) || e['@@iterator']
  if (null != r) {
    var n,
      o,
      a,
      c,
      u = [],
      i = !0,
      s = !1
    try {
      if (((a = (r = r.call(e)).next), 0 === t)) {
        if (Object(r) !== r) {
          return
        }
        i = !1
      } else {
        for (
          ;
          !(i = (n = a.call(r)).done) && (u.push(n.value), u.length !== t);
          i = !0
        ) {}
      }
    } catch (e) {
      ;(s = !0), (o = e)
    } finally {
      try {
        if (!i && null != r.return && ((c = r.return()), Object(c) !== c)) {
          return
        }
      } finally {
        if (s) {
          throw o
        }
      }
    }
    return u
  }
}
function ownKeys(t, e) {
  var r,
    n = Object.keys(t)
  return (
    Object.getOwnPropertySymbols &&
      ((r = Object.getOwnPropertySymbols(t)),
      e &&
        (r = r.filter(function (e) {
          return Object.getOwnPropertyDescriptor(t, e).enumerable
        })),
      n.push.apply(n, r)),
    n
  )
}
function _objectSpread2(t) {
  for (var e = 1; e < arguments.length; e++) {
    var r = null != arguments[e] ? arguments[e] : {}
    e % 2
      ? ownKeys(Object(r), !0).forEach(function (e) {
          _defineProperty(t, e, r[e])
        })
      : Object.getOwnPropertyDescriptors
        ? Object.defineProperties(t, Object.getOwnPropertyDescriptors(r))
        : ownKeys(Object(r)).forEach(function (e) {
            Object.defineProperty(t, e, Object.getOwnPropertyDescriptor(r, e))
          })
  }
  return t
}
function _toPrimitive(e, t) {
  if ('object' != typeof e || !e) {
    return e
  }
  var r = e[Symbol.toPrimitive]
  if (void 0 === r) {
    return ('string' === t ? String : Number)(e)
  }
  r = r.call(e, t || 'default')
  if ('object' != typeof r) {
    return r
  }
  throw new TypeError('@@toPrimitive must return a primitive value.')
}
function _toPropertyKey(e) {
  e = _toPrimitive(e, 'string')
  return 'symbol' == typeof e ? e : e + ''
}
function _typeof(e) {
  return (_typeof =
    'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
      ? function (e) {
          return typeof e
        }
      : function (e) {
          return e &&
            'function' == typeof Symbol &&
            e.constructor === Symbol &&
            e !== Symbol.prototype
            ? 'symbol'
            : typeof e
        })(e)
}
function _classCallCheck(e, t) {
  if (!(e instanceof t)) {
    throw new TypeError('Cannot call a class as a function')
  }
}
function _defineProperties(e, t) {
  for (var r = 0; r < t.length; r++) {
    var n = t[r]
    ;(n.enumerable = n.enumerable || !1),
      (n.configurable = !0),
      'value' in n && (n.writable = !0),
      Object.defineProperty(e, _toPropertyKey(n.key), n)
  }
}
function _createClass(e, t, r) {
  return (
    t && _defineProperties(e.prototype, t),
    r && _defineProperties(e, r),
    Object.defineProperty(e, 'prototype', { writable: !1 }),
    e
  )
}
function _defineProperty(e, t, r) {
  return (
    (t = _toPropertyKey(t)) in e
      ? Object.defineProperty(e, t, {
          value: r,
          enumerable: !0,
          configurable: !0,
          writable: !0,
        })
      : (e[t] = r),
    e
  )
}
function _extends() {
  return (_extends = Object.assign
    ? Object.assign.bind()
    : function (e) {
        for (var t = 1; t < arguments.length; t++) {
          var r,
            n = arguments[t]
          for (r in n) {
            Object.prototype.hasOwnProperty.call(n, r) && (e[r] = n[r])
          }
        }
        return e
      }).apply(this, arguments)
}
function _inherits(e, t) {
  if ('function' != typeof t && null !== t) {
    throw new TypeError('Super expression must either be null or a function')
  }
  ;(e.prototype = Object.create(t && t.prototype, {
    constructor: { value: e, writable: !0, configurable: !0 },
  })),
    Object.defineProperty(e, 'prototype', { writable: !1 }),
    t && _setPrototypeOf(e, t)
}
function _getPrototypeOf(e) {
  return (_getPrototypeOf = Object.setPrototypeOf
    ? Object.getPrototypeOf.bind()
    : function (e) {
        return e.__proto__ || Object.getPrototypeOf(e)
      })(e)
}
function _setPrototypeOf(e, t) {
  return (_setPrototypeOf = Object.setPrototypeOf
    ? Object.setPrototypeOf.bind()
    : function (e, t) {
        return (e.__proto__ = t), e
      })(e, t)
}
function _objectWithoutPropertiesLoose(e, t) {
  if (null == e) {
    return {}
  }
  var r,
    n = {}
  for (r in e) {
    !Object.prototype.hasOwnProperty.call(e, r) ||
      0 <= t.indexOf(r) ||
      (n[r] = e[r])
  }
  return n
}
function _objectWithoutProperties(e, t) {
  if (null == e) {
    return {}
  }
  var r,
    n = _objectWithoutPropertiesLoose(e, t)
  if (Object.getOwnPropertySymbols) {
    for (var o = Object.getOwnPropertySymbols(e), a = 0; a < o.length; a++) {
      ;(r = o[a]),
        0 <= t.indexOf(r) ||
          (Object.prototype.propertyIsEnumerable.call(e, r) && (n[r] = e[r]))
    }
  }
  return n
}
function _assertThisInitialized(e) {
  if (void 0 === e) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    )
  }
  return e
}
function _possibleConstructorReturn(e, t) {
  if (t && ('object' == typeof t || 'function' == typeof t)) {
    return t
  }
  if (void 0 !== t) {
    throw new TypeError(
      'Derived constructors may only return object or undefined'
    )
  }
  return _assertThisInitialized(e)
}
function _slicedToArray(e, t) {
  return (
    _arrayWithHoles(e) ||
    _iterableToArrayLimit(e, t) ||
    _unsupportedIterableToArray(e, t) ||
    _nonIterableRest()
  )
}
function _toConsumableArray(e) {
  return (
    _arrayWithoutHoles(e) ||
    _iterableToArray(e) ||
    _unsupportedIterableToArray(e) ||
    _nonIterableSpread()
  )
}
function _arrayWithoutHoles(e) {
  if (Array.isArray(e)) {
    return _arrayLikeToArray(e)
  }
}
function _arrayWithHoles(e) {
  if (Array.isArray(e)) {
    return e
  }
}
function _iterableToArray(e) {
  if (
    ('undefined' != typeof Symbol && null != e[Symbol.iterator]) ||
    null != e['@@iterator']
  ) {
    return Array.from(e)
  }
}
function _unsupportedIterableToArray(e, t) {
  var r
  if (e) {
    return 'string' == typeof e
      ? _arrayLikeToArray(e, t)
      : 'Map' ===
            (r =
              'Object' ===
                (r = Object.prototype.toString.call(e).slice(8, -1)) &&
              e.constructor
                ? e.constructor.name
                : r) || 'Set' === r
        ? Array.from(e)
        : 'Arguments' === r ||
            /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(r)
          ? _arrayLikeToArray(e, t)
          : void 0
  }
}
function _arrayLikeToArray(e, t) {
  ;(null == t || t > e.length) && (t = e.length)
  for (var r = 0, n = new Array(t); r < t; r++) {
    n[r] = e[r]
  }
  return n
}
function _nonIterableSpread() {
  throw new TypeError(
    'Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.'
  )
}
function _nonIterableRest() {
  throw new TypeError(
    'Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.'
  )
}
var originOptions = {
  react: {
    componentWrap: 'div',
    slotWrap: 'div',
    componentWrapAttrs: {
      __use_react_component_wrap: '',
      style: { all: 'unset' },
    },
    slotWrapAttrs: { __use_react_slot_wrap: '', style: { all: 'unset' } },
    vueNamedSlotsKey: ['node:'],
  },
  vue: {
    componentWrapHOC: function (t) {
      return function () {
        var e = (
          0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : {}
        ).portals
        return createElement(
          Fragment,
          null,
          t,
          (void 0 === e ? [] : e).map(function (e) {
            var t = e.Portal,
              e = e.key
            return createElement(t, { key: e })
          })
        )
      }
    },
    componentWrapAttrs: {
      'data-use-vue-component-wrap': '',
      style: { all: 'unset' },
    },
    slotWrapAttrs: { 'data-use-vue-slot-wrap': '', style: { all: 'unset' } },
  },
}
function setOptions() {
  var e =
      0 < arguments.length && void 0 !== arguments[0]
        ? arguments[0]
        : { react: {}, vue: {} },
    t =
      1 < arguments.length && void 0 !== arguments[1]
        ? arguments[1]
        : originOptions,
    r = 2 < arguments.length ? arguments[2] : void 0,
    t =
      (e.vue || (e.vue = {}),
      e.react || (e.react = {}),
      [
        t,
        _objectSpread2(
          _objectSpread2({}, e),
          {},
          {
            react: _objectSpread2(
              _objectSpread2(_objectSpread2({}, t.react), e.react),
              {},
              {
                componentWrapAttrs: _objectSpread2(
                  _objectSpread2({}, t.react.componentWrapAttrs),
                  e.react.componentWrapAttrs
                ),
                slotWrapAttrs: _objectSpread2(
                  _objectSpread2({}, t.react.slotWrapAttrs),
                  e.react.slotWrapAttrs
                ),
              }
            ),
            vue: _objectSpread2(
              _objectSpread2(_objectSpread2({}, t.vue), e.vue),
              {},
              {
                componentWrapAttrs: _objectSpread2(
                  _objectSpread2({}, t.vue.componentWrapAttrs),
                  e.vue.componentWrapAttrs
                ),
                slotWrapAttrs: _objectSpread2(
                  _objectSpread2({}, t.vue.slotWrapAttrs),
                  e.vue.slotWrapAttrs
                ),
              }
            ),
          }
        ),
      ])
  return r && t.unshift({}), Object.assign.apply(this, t)
}
var domMethods = [
    'getElementById',
    'getElementsByClassName',
    'getElementsByTagName',
    'getElementsByTagNameNS',
    'querySelector',
    'querySelectorAll',
  ],
  domTopObject = { Document: {}, Element: {} }
function overwriteDomMethods(c) {
  Object.keys(domTopObject).forEach(function (e) {
    domMethods.forEach(function (o) {
      var a = window[e].prototype[o]
      ;(domTopObject[e][o] = a),
        (window[e].prototype[o] = function () {
          for (var e = arguments.length, t = new Array(e), r = 0; r < e; r++) {
            t[r] = arguments[r]
          }
          var n = a.apply(this, t)
          return n &&
            (n.constructor !== NodeList ||
              (n.constructor === NodeList && 0 < n.length))
            ? n
            : (domTopObject.Element[o] || Element.prototype[o]).apply(c, t)
        })
    })
  })
}
function recoverDomMethods() {
  Object.keys(domTopObject).forEach(function (t) {
    domMethods.forEach(function (e) {
      window[t].prototype[e] = domTopObject[t][e]
    })
  })
}
var _excluded = ['ref'],
  _excluded2 = ['key'],
  _excluded3 = ['hashList'],
  ReactMajorVersion = parseInt(version)
function toRaws(e) {
  return e
}
var FunctionComponentWrap = (function () {
    function t(e) {
      return _classCallCheck(this, t), _callSuper(this, t, [e])
    }
    return (
      _inherits(t, Component),
      _createClass(t, [
        {
          key: 'render',
          value: function () {
            var e = this.props.component,
              t = this.props.passedProps,
              t = (t.ref, _objectWithoutProperties(t, _excluded))
            return createElement(e, t, this.props.children)
          },
        },
      ])
    )
  })(),
  createReactContainer = function (_, f, d) {
    var e = (function () {
      function p(e) {
        var t
        return (
          _classCallCheck(this, p),
          ((t = _callSuper(this, p, [e])).state = _objectSpread2(
            _objectSpread2({}, e),
            f.isSlots ? { children: _ } : {}
          )),
          (t.setRef = t.setRef.bind(t)),
          (t.vueInReactCall = t.vueInReactCall.bind(t)),
          ((t.__bridgeVueWrapperRef__ = d).__bridgeVueInReactCall__ =
            t.vueInReactCall),
          t
        )
      }
      return (
        _inherits(p, Component),
        _createClass(
          p,
          [
            {
              key: 'reactPropsLinkToVueInstance',
              value: function (t) {
                Object.keys(t).forEach(function (e) {
                  d[e] || (d[e] = t[e])
                }),
                  Object.getOwnPropertyNames(t.__proto__)
                    .filter(function (e) {
                      return ['constructor', 'render'].indexOf(e) < 0
                    })
                    .forEach(function (e) {
                      d[e] || (d[e] = t[e])
                    })
              },
            },
            {
              key: 'setRef',
              value: function (e) {
                var t = this
                e &&
                  ((d.__bridgeReactRef__ = e),
                  this.reactPropsLinkToVueInstance(e),
                  Promise.resolve().then(function () {
                    return t.reactPropsLinkToVueInstance(e)
                  }),
                  ((this.setRef.current = e).__bridgeVueWrapperRef__ = d))
              },
            },
            {
              key: 'createSlot',
              value: function (r) {
                return {
                  originVNode: r,
                  inheritAttrs: !1,
                  __fromReactSlot: !0,
                  render: function () {
                    var e, t
                    return (
                      1 ===
                        (null ==
                        (e = r =
                          (r =
                            (null == (t = this.$slots) ||
                            null == (e = t.default)
                              ? void 0
                              : e.call(t)) || r) instanceof Function
                            ? r(this)
                            : r)
                          ? void 0
                          : e.length) &&
                        null != (t = r[0]) &&
                        t.data &&
                        ((e = this.$attrs).key,
                        (t = _objectWithoutProperties(e, _excluded2)),
                        (r[0].props = _objectSpread2(
                          _objectSpread2({}, t),
                          r[0].props
                        ))),
                      r
                    )
                  },
                }
              },
            },
            {
              key: 'componentWillUnmount',
              value: function () {
                d.__bridgeReactRef__ &&
                  ((d.__bridgeReactRef__.__bridgeVueWrapperRef__ = null),
                  (d.__bridgeReactRef__ = null))
              },
            },
            {
              key: 'vueInReactCall',
              value: function (e) {
                var r = this,
                  n =
                    1 < arguments.length && void 0 !== arguments[1]
                      ? arguments[1]
                      : {}
                return (2 < arguments.length ? arguments[2] : void 0) &&
                  e &&
                  e[0]
                  ? e.map(function (e, t) {
                      return applyVueInReact(
                        r.createSlot(e instanceof Function ? e : [e]),
                        _objectSpread2(
                          _objectSpread2(_objectSpread2({}, f), n),
                          {},
                          { isSlots: !0, wrapInstance: d }
                        )
                      ).render({ key: (null == e ? void 0 : e.key) || void 0 })
                    })
                  : applyVueInReact(
                      this.createSlot(e),
                      _objectSpread2(
                        _objectSpread2(_objectSpread2({}, f), n),
                        {},
                        { isSlots: !0, wrapInstance: d }
                      )
                    ).render()
              },
            },
            {
              key: 'render',
              value: function () {
                var e,
                  t,
                  r,
                  c = this,
                  n = this.state,
                  o = n.hashList,
                  a = _objectWithoutProperties(n, _excluded3),
                  u = {},
                  i = {}
                for (e in a) {
                  ;(t = e),
                    (r = void 0),
                    a.hasOwnProperty(t) &&
                      null != a[t] &&
                      (a[t].__slot
                        ? (a[t].reactSlot
                            ? (a[t] = a[t].reactSlot)
                            : ((r = a[t]),
                              f.defaultSlotsFormatter && a[t].__trueChildren
                                ? ((a[t].__trueChildren.__top__ =
                                    c.__bridgeVueWrapperRef__),
                                  (a[t] = f.defaultSlotsFormatter(
                                    a[t].__trueChildren,
                                    c.vueInReactCall,
                                    o
                                  )),
                                  a[t] instanceof Array
                                    ? (a[t] = _toConsumableArray(a[t]))
                                    : -1 <
                                        ['string', 'number'].indexOf(
                                          _typeof(a[t])
                                        )
                                      ? (a[t] = [a[t]])
                                      : 'object' === _typeof(a[t]) &&
                                        (a[t] = _objectSpread2({}, a[t])))
                                : (a[t] = _objectSpread2(
                                    {},
                                    applyVueInReact(
                                      c.createSlot(a[t]),
                                      _objectSpread2(
                                        _objectSpread2({}, f),
                                        {},
                                        { isSlots: !0, wrapInstance: d }
                                      )
                                    ).render()
                                  )),
                              (a[t].vueFunction = r)),
                          (u[t] = a[t]))
                        : a[t].__scopedSlot &&
                          ((a[t] = a[t](c.createSlot)), (i[t] = a[t])))
                }
                var s,
                  l,
                  n = {}
                return (
                  (n.ref = this.setRef),
                  f.isSlots
                    ? this.state.children || this.props.children
                    : ((s = a),
                      _.__syncUpdateForPureReactInVue &&
                        Object.keys(_.__syncUpdateForPureReactInVue).map(
                          function (n) {
                            var o, a
                            s[n] &&
                              'function' == typeof s[n] &&
                              ((o = c.__bridgeVueWrapperRef__),
                              (a = s[n]),
                              (s[n] = function () {
                                for (
                                  var e = arguments.length,
                                    t = new Array(e),
                                    r = 0;
                                  r < e;
                                  r++
                                ) {
                                  t[r] = arguments[r]
                                }
                                o.__bridgeSyncUpdateProps__(
                                  _.__syncUpdateForPureReactInVue[n].apply(
                                    this,
                                    t
                                  )
                                ),
                                  a.apply(this, t),
                                  (o.macroTaskUpdate = !0),
                                  o.__bridgeMountReactComponent__(!0, !0, {})
                              }))
                          }
                        ),
                      (l = _objectSpread2(
                        _objectSpread2(
                          _objectSpread2(
                            {},
                            (s = f.defaultPropsFormatter
                              ? f.defaultPropsFormatter.call(
                                  this,
                                  s,
                                  this.vueInReactCall,
                                  o
                                )
                              : s)
                          ),
                          u
                        ),
                        i
                      )),
                      (Object.getPrototypeOf(_) !== Function.prototype &&
                        ('object' !== _typeof(_) || _.render)) ||
                      p.catchVueRefs()
                        ? (Object.getPrototypeOf(_) === Function.prototype &&
                            delete n.ref,
                          createElement(_, _extends({}, l, n)))
                        : createElement(
                            FunctionComponentWrap,
                            _extends({ passedProps: l, component: _ }, n),
                            l.children
                          ))
                )
              },
            },
          ],
          [
            {
              key: 'catchVueRefs',
              value: function () {
                if (d.$parent) {
                  for (var e in d.$parent.$refs) {
                    if (d.$parent.$refs[e] === d) {
                      return !0
                    }
                  }
                }
                return !1
              },
            },
          ]
        )
      )
    })()
    return (
      _defineProperty(
        e,
        'displayName',
        'applyReact_'.concat(_.displayName || _.name || 'Component')
      ),
      e
    )
  }
function applyReactInVue(m) {
  var b = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {}
  return (
    m.__esModule && m.default && (m = m.default),
    b.isSlots && (m = m()),
    (b = setOptions(b, void 0, !0)),
    {
      originReactComponent: m,
      setup: function (e, t) {
        var r, n, o, a
        if (!b.isSlots) {
          return (
            (r = {}),
            (n = reactive({})),
            (o = getCurrentInstance()),
            'function' ==
              typeof (a =
                b.useInjectPropsFromWrapper ||
                m.__bridgeInjectPropsFromWrapper__) &&
              ('function' != typeof (a = a.call(o.proxy, e))
                ? (Object.assign(n, a), (r.__bridgeInjectedProps__ = n))
                : (o.proxy.__bridgeInjectedComputed__ = a)),
            r
          )
        }
      },
      data: function () {
        return { BRIDGE_Portals: [] }
      },
      created: function () {
        ;(this.__bridgePortalKeyPool__ = []),
          (this.__bridgeMaxPortalCount__ = 0)
      },
      computed: {
        __bridgeInjectedProps__: function () {
          var e
          return null == (e = this.__bridgeInjectedComputed__)
            ? void 0
            : e.call(this)
        },
      },
      render: function () {
        var e = h(
          b.react.componentWrap,
          _objectSpread2({ ref: 'react' }, b.react.componentWrapAttrs || {}),
          this.BRIDGE_Portals.map(function (e) {
            var t = e.Portal,
              e = e.key
            return t(h, e)
          })
        )
        return this.__bridgeCheckReactSlot__(this.$slots), e
      },
      methods: {
        __bridgeCheckReactSlot__: function (n) {
          var o = this
          function a(e, t, r) {
            return t[r] && ((e[r] = t[r]), 1)
          }
          'object' === _typeof(n) &&
            null != n &&
            (n instanceof Array
              ? n.forEach(function (e) {
                  o.__bridgeCheckReactSlot__(e.children)
                })
              : Object.keys(n).forEach(function (e) {
                  var t,
                    r,
                    e = n[e]
                  if ('function' == typeof e) {
                    try {
                      t = e.apply(o, e.__reactArgs || [{}])
                    } catch (e) {
                      return
                    }
                    ;(e.__trueChildren = t).forEach(function (e) {
                      e.children && o.__bridgeCheckReactSlot__(e.children)
                    }),
                      1 !== t.length ||
                        a(e, (t = t[0]), 'reactSlot') ||
                        a(e, t, 'reactFunction') ||
                        t.type !== Fragment$1 ||
                        1 !== (null == (r = t.children) ? void 0 : r.length) ||
                        a(e, (r = t.children[0]), 'reactSlot') ||
                        a(e, r, 'reactFunction')
                  }
                }))
        },
        __bridgePushVuePortal__: function (e) {
          var t =
            this.__bridgePortalKeyPool__.shift() ||
            this.__bridgeMaxPortalCount__++
          this.BRIDGE_Portals.push({ Portal: e, key: t })
        },
        __bridgeRemoveVuePortal__: function (r) {
          var n,
            e = this.BRIDGE_Portals.find(function (e, t) {
              if (e.Portal === r) {
                return (n = t), !0
              }
            })
          this.__bridgePortalKeyPool__.push(e.key),
            this.BRIDGE_Portals.splice(n, 1)
        },
        __bridgeGetScopeSlot__: function (c, u, t) {
          var i = this
          function e(a) {
            function e() {
              for (
                var e, t = this, r = arguments.length, n = new Array(r), o = 0;
                o < r;
                o++
              ) {
                n[o] = arguments[o]
              }
              return c.reactFunction
                ? c.reactFunction.apply(this, n)
                : b.defaultSlotsFormatter
                  ? (((e = c.apply(this, n)).__top__ = i),
                    (e = b.defaultSlotsFormatter(
                      e,
                      i.__bridgeVueInReactCall__,
                      u
                    )) instanceof Array ||
                    -1 < _typeof(e).indexOf('string', 'number')
                      ? (e = _toConsumableArray(e))
                      : 'object' === _typeof(e) && (e = _objectSpread2({}, e)),
                    e)
                  : applyVueInReact(
                      a(function () {
                        return c.apply(t, n)
                      }),
                      _objectSpread2(
                        _objectSpread2({}, b),
                        {},
                        { isSlots: !0, wrapInstance: i }
                      )
                    ).render()
            }
            return (
              b.pureTransformer && t
                ? (e.vueFunction = t)
                : (e.vueFunction = c),
              e
            )
          }
          return (e.__scopedSlot = !0), e
        },
        __bridgeSyncUpdateProps__: function (e) {
          this.__bridgeReactInstance__ &&
            this.__bridgeReactInstance__.setState(e)
        },
        __bridgeMountReactComponent__: function (e, t) {
          var r,
            n,
            o = this,
            a =
              2 < arguments.length && void 0 !== arguments[2]
                ? arguments[2]
                : {},
            c = {},
            u = [],
            i = this.$.vnode.scopeId,
            s = (i && ((c[i] = ''), u.push(i)), {}),
            l = {}
          if (!e || (null != t && t.slot)) {
            for (var p in this.$slots || {}) {
              ;(function (t) {
                var e
                o.$slots.hasOwnProperty(t) &&
                  null != o.$slots[t] &&
                  ((e = b.react.vueNamedSlotsKey.find(function (e) {
                    return 0 === t.indexOf(e)
                  })) || 'default' === t
                    ? ((e = t.replace(new RegExp('^'.concat(e)), '')),
                      (s[e] = o.$slots[t]),
                      (s[e].__slot = !0))
                    : (l[t] = o.__bridgeGetScopeSlot__(
                        o.$slots[t],
                        u,
                        null == (e = o.$.vnode) || null == (e = e.children)
                          ? void 0
                          : e[t]
                      )))
              })(p)
            }
          }
          ;(!e || (null != t && t.slot)) &&
            ((n = _objectSpread2({}, s)), (r = n.default), delete n.default),
            (this.__bridgeLast__ = this.__bridgeLast__ || {}),
            (this.__bridgeLast__.slot = this.__bridgeLast__.slot || {}),
            (this.__bridgeLast__.attrs = this.__bridgeLast__.attrs || {})
          var _ = {
            slot: function () {
              o.__bridgeLast__.slot = _objectSpread2(
                _objectSpread2(
                  _objectSpread2({}, r ? { children: r } : { children: null }),
                  n
                ),
                l
              )
            },
            attrs: function () {
              o.__bridgeLast__.attrs = o.$attrs
            },
          }
          if (
            (t &&
              Object.keys(t).forEach(function (e) {
                return _[e]()
              }),
            e)
          ) {
            function f() {
              o.__bridgeReactInstance__ &&
                o.__bridgeReactInstance__.setState(function (t) {
                  return (
                    Object.keys(t).forEach(function (e) {
                      ;(b.isSlots && 'children' === e) || delete t[e]
                    }),
                    _objectSpread2(
                      _objectSpread2(
                        _objectSpread2(
                          _objectSpread2({}, o.__bridgeCache__),
                          toRaws(o.__bridgeInjectedProps__)
                        ),
                        !b.isSlots && o.__bridgeLast__.slot
                      ),
                      toRaws(o.__bridgeLast__.attrs)
                    )
                  )
                }),
                (o.__bridgeCache__ = null)
            }
            !this.microTaskUpdate ||
              this.__bridgeCache__ ||
              this.$nextTick(function () {
                f(), (o.microTaskUpdate = !1)
              }),
              this.macroTaskUpdate &&
                (clearTimeout(this.updateTimer),
                (this.updateTimer = setTimeout(function () {
                  clearTimeout(o.updateTimer), f(), (o.macroTaskUpdate = !1)
                }))),
              (this.__bridgeCache__ = _objectSpread2(
                _objectSpread2({}, this.__bridgeCache__ || {}),
                _objectSpread2(
                  _objectSpread2(
                    _objectSpread2(
                      _objectSpread2({}, a),
                      this.$attrs.class ? { className: this.$attrs.class } : {}
                    ),
                    _objectSpread2({}, c)
                  ),
                  {},
                  { hashList: u },
                  this.$attrs.style ? { style: this.$attrs.style } : {}
                )
              )),
              this.macroTaskUpdate || this.microTaskUpdate || f()
          } else {
            _.slot(), _.attrs()
            var i = createReactContainer(m, b, this),
              d = createElement(
                i,
                _extends(
                  {},
                  toRaws(this.$attrs),
                  toRaws(this.__bridgeInjectedProps__),
                  { children: r },
                  n,
                  l,
                  this.$attrs.class ? { className: this.$attrs.class } : {},
                  c,
                  { hashList: u },
                  this.$attrs.style ? { style: this.$attrs.style } : {},
                  {
                    ref: function (e) {
                      return (o.__bridgeReactInstance__ = e)
                    },
                  }
                )
              ),
              y = this.$refs.react,
              v = b.wrapInstance
            if (v) {
              ;(v = b.wrapInstance).__bridgeVueWrapperRef__ = this
            } else {
              for (var h = this.$parent; h; ) {
                if (h.parentReactWrapperRef) {
                  v = h.parentReactWrapperRef
                  break
                }
                if (h.reactWrapperRef) {
                  v = h.reactWrapperRef
                  break
                }
                h = h.$parent
              }
            }
            v
              ? ((this.parentReactWrapperRef = v),
                (this.reactPortal = function () {
                  return createPortal(d, y)
                }),
                v.pushReactPortal(this.reactPortal))
              : 17 < ReactMajorVersion
                ? (void 0 !==
                    ReactDOM.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED &&
                    (ReactDOM.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.usingClientEntryPoint =
                      !0),
                  (this.__bridgeReactApp__ = ReactDOM.createRoot(y)),
                  this.__bridgeReactApp__.render(d))
                : ReactDOM.render(d, y)
          }
        },
      },
      mounted: function () {
        var e = this
        ;(this.__BRIDGE_IGNORE_STRANGE_UPDATE__ = !0),
          Promise.resolve().then(function () {
            e.__BRIDGE_IGNORE_STRANGE_UPDATE__ = !1
          }),
          clearTimeout(this.updateTimer),
          this.__bridgeMountReactComponent__()
      },
      beforeUnmount: function () {
        var e
        clearTimeout(this.updateTimer),
          overwriteDomMethods(this.$refs.react),
          this.reactPortal
            ? null != (e = this.parentReactWrapperRef) &&
              e.removeReactPortal(this.reactPortal)
            : 17 < ReactMajorVersion
              ? this.__bridgeReactApp__.unmount()
              : ReactDOM.unmountComponentAtNode(this.$refs.react),
          recoverDomMethods()
      },
      updated: function () {
        this.__BRIDGE_IGNORE_STRANGE_UPDATE__ ||
          this.__bridgeMountReactComponent__(!0, { slot: !0 })
      },
      inheritAttrs: !1,
      watch: {
        $attrs: {
          handler: function () {
            this.__bridgeMountReactComponent__(!0, { attrs: !0 })
          },
          deep: !0,
        },
        __bridgeInjectedProps__: {
          handler: function () {
            this.__bridgeMountReactComponent__(!0, { attrs: !0 })
          },
          deep: !0,
        },
      },
    }
  )
}
var REACT_ALL_HANDLERS = new Set([
  'onClick',
  'onContextMenu',
  'onDoubleClick',
  'onDrag',
  'onDragEnd',
  'onDragEnter',
  'onDragExit',
  'onDragLeave',
  'onDragOver',
  'onDragStart',
  'onDrop',
  'onMouseDown',
  'onMouseEnter',
  'onMouseLeave',
  'onMouseMove',
  'onMouseOut',
  'onMouseOver',
  'onMouseUp',
  'onChange',
  'onInput',
  'onInvalid',
  'onReset',
  'onSubmit',
  'onError',
  'onLoad',
  'onPointerDown',
  'onPointerMove',
  'onPointerUp',
  'onPointerCancel',
  'onGotPointerCapture',
  'onLostPointerCapture',
  'onPointerEnter',
  'onPointerLeave',
  'onPointerOver',
  'onPointerOut',
  'onSelect',
  'onTouchCancel',
  'onTouchEnd',
  'onTouchMove',
  'onTouchStart',
  'onScroll',
  'onWheel',
  'onAbort',
  'onCanPlay',
  'onCanPlayThrough',
  'onDurationChange',
  'onEmptied',
  'onEncrypted',
  'onEnded',
  'onError',
  'onLoadedData',
  'onLoadedMetadata',
  'onLoadStart',
  'onPause',
  'onPlay',
  'onPlaying',
  'onProgress',
  'onRateChange',
  'onSeeked',
  'onSeeking',
  'onStalled',
  'onSuspend',
  'onTimeUpdate',
  'onVolumeChange',
  'onWaiting',
  'onLoad',
  'onError',
  'onAnimationStart',
  'onAnimationEnd',
  'onAnimationIteration',
  'onTransitionEnd',
  'onToggle',
])
function lookupVueWrapperRef(e, t) {
  for (
    var r =
      null ==
      (e = t =
        (null == e ? void 0 : e._reactInternals) ||
        (null == e ? void 0 : e._reactInternalFiber) ||
        t)
        ? void 0
        : e.return;
    r;

  ) {
    var n = r.stateNode
    if (
      (n =
        (null == n ? void 0 : n.parentVueWrapperRef) ||
        (null == n ? void 0 : n.__bridgeVueWrapperRef__))
    ) {
      return n
    }
    r = r.return
  }
}
function createModifiers(e, t, r) {
  var n = {}
  return (
    r.forEach(function (e) {
      n[e] = !0
    }),
    (e[('modelValue' === t ? 'model' : t) + 'Modifiers'] = n)
  )
}
function setVModel(e, t, r) {
  var n = this,
    o =
      3 < arguments.length && void 0 !== arguments[3]
        ? arguments[3]
        : 'v-model',
    a = t
  if (!(a instanceof Array)) {
    throw Error(
      "[error:bridge] Parameter type error from '".concat(
        o,
        "', a single v-model is an array, such as [val, setter, argumentKey, modifiers] or [val, setter, modifiers]"
      )
    )
  }
  if ('function' != typeof a[1]) {
    throw Error(
      "[error:bridge] Parameter type error from '".concat(
        o,
        "', a single v-model is an array, the second element of the array must be a setter function"
      )
    )
  }
  var c = a[1],
    u =
      ('string' == typeof a[2]
        ? ((r = a[2]), a[3] instanceof Array && createModifiers(e, r, a[3]))
        : a[2] instanceof Array && createModifiers(e, r, a[2]),
      e['onUpdate:' + r])
  ;(e['onUpdate:' + r] =
    'function' == typeof u
      ? function () {
          for (var e = arguments.length, t = new Array(e), r = 0; r < e; r++) {
            t[r] = arguments[r]
          }
          u.apply(n, t), c.apply(n, t)
        }
      : c),
    (e[r] = a[0])
}
function parseVModel(a) {
  var c = this,
    r = {},
    u = _objectSpread2({}, a)
  return (
    Object.keys(a).forEach(function (n) {
      var o,
        e = n.match(/^onUpdate-([^-]+)/)
      if (e) {
        delete u[n],
          (o = r['onUpdate:'.concat(e[1])]),
          (r['onUpdate:'.concat(e[1])] =
            'function' == typeof o
              ? function () {
                  for (
                    var e = arguments.length, t = new Array(e), r = 0;
                    r < e;
                    r++
                  ) {
                    t[r] = arguments[r]
                  }
                  o.apply(c, t), a[n].apply(c, t)
                }
              : a[n])
      } else if ((e = n.match(/^v-model($|:([^:]+)|-([^:]+))/))) {
        ;(e = e[2] || e[3] || 'modelValue'), setVModel(r, a[n], e), delete u[n]
      } else if ('v-models' === n) {
        if ('object' !== _typeof(a[n]) || a[n] instanceof Array) {
          throw Error(
            "[error:bridge] The parameter 'v-models' must be an object type, such as {[argumentKey]: singleVModel}"
          )
        }
        var t = a[n]
        Object.keys(t).forEach(function (e) {
          setVModel(r, t[e], e, 'v-models')
        }),
          delete u[n]
      }
    }),
    _objectSpread2(_objectSpread2({}, u), r)
  )
}
var _default = (function () {
  return _createClass(
    function e() {
      _classCallCheck(this, e), _defineProperty(this, 'pool', new Set())
    },
    [
      {
        key: 'getRandomId',
        value: function (e) {
          var t = e + (Math.random() + '').substr(2)
          return this.pool.has(t) ? this.getRandomId(e) : (this.pool.add(t), t)
        },
      },
    ]
  )
})()
function RenderReactNode(e, t) {
  var r,
    e = e.node
  if (
    ('function' == typeof e && (e = e()),
    (null != (r = t) && r.current) ||
      'function' == typeof t ||
      (null != (r = t) && r.toString().match(/^function/)) ||
      (t = null),
    -1 < ['string', 'number'].indexOf(_typeof(e)))
  ) {
    return e
  }
  if (e instanceof Array) {
    if (1 !== e.length) {
      return e
    }
    e = e[0]
  }
  return _objectSpread2(_objectSpread2({}, e), {}, { ref: t })
}
var Bridge = applyReactInVue(RenderReactNode)
function WrapVue(e) {
  return h(Bridge, {
    node: function () {
      return e.node
    },
  })
}
WrapVue.originReactComponent = forwardRef(RenderReactNode)
var _excluded$1 = ['component', 'node'],
  _excluded2$1 = ['component', '$slots', 'children', 'class', 'style'],
  _excluded3$1 = ['className', 'classname'],
  optionsName = 'bridge-options',
  random = new _default()
function filterVueComponent(e, t) {
  var r
  return (e =
    'string' == typeof e && t
      ? null == (t = t.$) ||
        null == (t = t.appContext) ||
        null == (t = t.app) ||
        null == (r = t.component)
        ? void 0
        : r.call(t, e)
      : e)
}
function transferSlots(r) {
  if (r) {
    return (
      Object.keys(r).forEach(function (e) {
        var t = r[e]
        null != t &&
          ('function' == typeof t
            ? ((r[e] = t), (r[e].reactFunction = t))
            : ((r[e] = function () {
                return t
              }),
              (r[e].reactSlot = t)),
          t.vueFunction) &&
          (r[e].vueFunction = t.vueFunction)
      }),
      r
    )
  }
}
function VNodeBridge(e) {
  var t
  return null == (t = e.node) ? void 0 : t.call(e)
}
var VueContainer = forwardRef(function (e, t) {
    var r,
      n = e.component,
      o = e.node,
      e = _objectWithoutProperties(e, _excluded$1)
    if (null == n && null == o) {
      return null
    }
    if (null != o) {
      if (o.$$typeof || 'string' == typeof o || 'number' == typeof o) {
        return o
      }
      'function' != typeof o &&
        ((r = o),
        (o = function () {
          return r
        }))
    }
    var a,
      n = n || VNodeBridge,
      c = setOptions(e[optionsName] || {}, void 0, !0),
      u = c.useInjectPropsFromWrapper || n.__bridgeInjectPropsFromWrapper__
    return (
      c.isSlots || ('function' == typeof u && (a = u(e))),
      createElement(
        VueComponentLoader,
        _extends(
          {},
          _objectSpread2(
            _objectSpread2(
              _objectSpread2(
                _objectSpread2({ component: n }, o ? { node: o } : {}),
                e
              ),
              a
            ),
            {},
            _defineProperty({}, optionsName, c)
          ),
          { ref: t }
        )
      )
    )
  }),
  VueComponentLoader = (function () {
    function r(e) {
      var t
      return (
        _classCallCheck(this, r),
        ((t = _callSuper(this, r, [e])).state = { portals: [] }),
        (t.__bridgePortalKeyPool__ = []),
        (t.__bridgeMaxPortalCount__ = 0),
        (t.__bridgeCurrentVueComponent__ = e.component),
        (t.__bridgeCreateVueInstance__ = t.__bridgeCreateVueInstance__.bind(t)),
        (t.__bridgeVueComponentContainer__ = t.createVueComponentContainer()),
        t
      )
    }
    return (
      _inherits(r, Component),
      _createClass(r, [
        {
          key: 'pushReactPortal',
          value: function (e) {
            var t = this.state.portals,
              r =
                this.__bridgePortalKeyPool__.shift() ||
                this.__bridgeMaxPortalCount__++
            t.push({ Portal: e, key: r }), this.setState({ portals: t })
          },
        },
        {
          key: 'removeReactPortal',
          value: function (r) {
            var n,
              e = this.state.portals,
              t = e.find(function (e, t) {
                if (e.Portal === r) {
                  return (n = t), !0
                }
              })
            this.__bridgePortalKeyPool__.push(t.key),
              e.splice(n, 1),
              this.__bridgeVueRef__ && this.setState({ portals: e })
          },
        },
        {
          key: 'createVueComponentContainer',
          value: function () {
            var t = this,
              r = {},
              e = this.props[optionsName]
            return (
              e.isSlots
                ? (Object.keys(this.props).forEach(function (e) {
                    REACT_ALL_HANDLERS.has(e) &&
                      'function' == typeof t.props[e] &&
                      (r[e] = t.props[e])
                  }),
                  e.vue.slotWrapAttrs &&
                    (r = _objectSpread2(
                      _objectSpread2({}, r),
                      e.vue.slotWrapAttrs
                    )))
                : e.vue.componentWrapAttrs &&
                  (r = _objectSpread2(
                    _objectSpread2({}, r),
                    e.vue.componentWrapAttrs
                  )),
              e.vue.componentWrapHOC(
                createElement(
                  'div',
                  _extends({}, e.vue.componentWrapAttrs, {
                    ref: this.__bridgeCreateVueInstance__,
                    key: null,
                  })
                ),
                r
              )
            )
          },
        },
        {
          key: 'shouldComponentUpdate',
          value: function (e, t, r) {
            var n,
              o,
              a,
              c,
              u = this
            return (
              e === this.props ||
              ((n = e.component),
              e[optionsName],
              (o = void 0 === (o = e['v-slots']) ? null : o),
              (a = e.children),
              (e = _objectWithoutProperties(
                e,
                ['component', optionsName, 'v-slots', 'children'].map(
                  _toPropertyKey
                )
              )),
              this.__bridgeCurrentVueComponent__ !== n &&
                this.updateVueComponent(n),
              !!n.__fromReactSlot) ||
              (this.__bridgeVueInstance__
                ? (a &&
                    ((o = o || {}),
                    'object' !== _typeof(a) || a instanceof Array || a.$$typeof
                      ? (o.default = a)
                      : (o = a)),
                  (c = this.__bridgeVueInstance__.$data.$slots) &&
                    Object.keys(c).forEach(function (e) {
                      delete c[e]
                    }),
                  o &&
                    (c || (this.__bridgeVueInstance__.$data.$slots = {}),
                    Object.assign(
                      this.__bridgeVueInstance__.$data.$slots,
                      transferSlots(o)
                    )),
                  Object.keys(this.__bridgeVueInstance__.$data).forEach(
                    function (e) {
                      '$slots' !== e && delete u.__bridgeVueInstance__.$data[e]
                    }
                  ),
                  this.__bridgeVueInstance__ &&
                    Object.assign(
                      this.__bridgeVueInstance__.$data,
                      parseVModel(e)
                    ),
                  !0)
                : void 0)
            )
          },
        },
        {
          key: 'componentWillUnmount',
          value: function () {
            this.vuePortal
              ? this.parentVueWrapperRef.__bridgeRemoveVuePortal__(
                  this.vuePortal
                )
              : (this.__bridgeVueInstance__ &&
                  this.__bridgeVueInstance__.$.appContext.app.unmount(),
                random.pool.delete(this.__bridgeVueTargetId__))
          },
        },
        {
          key: '__bridgeCreateVueInstance__',
          value: function (e) {
            var r = this,
              p = this,
              t = this.props,
              _ = (t.component, t[optionsName]),
              n = t.children,
              o = t['v-slots'],
              o = void 0 === o ? {} : o,
              t = _objectWithoutProperties(
                t,
                ['component', optionsName, 'children', 'v-slots'].map(
                  _toPropertyKey
                )
              )
            function a(e) {
              this.__bridgeVueInstance__ || (this.__bridgeVueInstance__ = e)
            }
            n &&
              ('object' !== _typeof(n) || n instanceof Array || n.$$typeof
                ? (o.default = n)
                : (o = n)),
              (o = transferSlots(o)) && (t.$slots = o),
              (a = a.bind(this))
            var c,
              u = _objectSpread2({}, parseVModel(t)),
              i = {
                data: function () {
                  return _.isSlots
                    ? { children: p.__bridgeCurrentVueComponent__.originVNode }
                    : u
                },
                created: function () {
                  ;(this.reactWrapperRef = p), a(this)
                },
                methods: {
                  reactInVueCall: function (e) {
                    return (2 < arguments.length ? arguments[2] : void 0) &&
                      e &&
                      e[0]
                      ? e.map(function (e, t) {
                          return h(WrapVue, {
                            node: e,
                            key:
                              (null == e || null == (e = e.data)
                                ? void 0
                                : e.key) || t,
                          })
                        })
                      : h(WrapVue, { node: e })
                  },
                  getScopedSlots: function (s, e) {
                    var t,
                      l = this,
                      r =
                        (this.getScopedSlots.__scopeSlots ||
                          (this.getScopedSlots.__scopeSlots = {}),
                        _objectSpread2({}, e))
                    for (t in r) {
                      ;(function (u) {
                        var e, i
                        !r.hasOwnProperty(u) ||
                          null == (e = r[u]) ||
                          ((r[u] =
                            ((i = e),
                            function () {
                              for (
                                var e,
                                  t,
                                  r,
                                  n,
                                  o = arguments.length,
                                  a = new Array(o),
                                  c = 0;
                                c < o;
                                c++
                              ) {
                                a[c] = arguments[c]
                              }
                              return i.vueFunction
                                ? i.vueFunction.apply(l, a)
                                : ((r = i.reactSlot),
                                  (n = i.reactFunction),
                                  (r =
                                    r || (null == n ? void 0 : n.apply(l, a))),
                                  (n = _.defaultSlotsFormatter),
                                  null !=
                                    (e = l.getScopedSlots.__scopeSlots[u]) &&
                                  null != (e = e.component) &&
                                  null != (e = e.ctx) &&
                                  e.__bridgeReactInstance__
                                    ? ((t = l.getScopedSlots.__scopeSlots[u]),
                                      Promise.resolve().then(function () {
                                        var e
                                        null != (e = t) &&
                                          null != (e = e.component) &&
                                          null != (e = e.ctx) &&
                                          null !=
                                            (e = e.__bridgeReactInstance__) &&
                                          e.setState({
                                            children: i.apply(l, a),
                                          })
                                      }))
                                    : ((t =
                                        n && r
                                          ? [n(r, l.reactInVueCall)]
                                          : s(
                                              applyReactInVue(
                                                function () {
                                                  return i.apply(l, a)
                                                },
                                                _objectSpread2(
                                                  _objectSpread2({}, _),
                                                  {},
                                                  {
                                                    isSlots: !0,
                                                    wrapInstance: p,
                                                  }
                                                )
                                              )
                                            )),
                                      (l.getScopedSlots.__scopeSlots[u] = t)),
                                  i.reactFunction
                                    ? (t.reactFunction = i.reactFunction)
                                    : i.reactSlot &&
                                      (t.reactSlot = i.reactSlot),
                                  t)
                            })),
                          (r[u].reactFunction = e))
                      })(t)
                    }
                    return r
                  },
                },
                mounted: function () {
                  e.removeAttribute('id'),
                    (p.__bridgeVueRef__ = this.$refs.use_vue_wrapper),
                    (this.$refs.use_vue_wrapper.reactWrapperRef = p)
                },
                beforeUnmount: function () {
                  ;(p.__bridgeVueRef__ = null),
                    (this.$refs.use_vue_wrapper.reactWrapperRef = null)
                },
                render: function () {
                  var e = this,
                    t = this.$data,
                    r = (t.component, t.$slots),
                    n = (t.children, t.class),
                    o = t.style,
                    t = _objectWithoutProperties(t, _excluded2$1),
                    a = this.getScopedSlots(h, _objectSpread2({}, r)),
                    r = t.className,
                    c = t.classname,
                    t = _objectWithoutProperties(t, _excluded3$1),
                    u = {}
                  return (
                    Object.keys(a).forEach(function (e) {
                      var t = a[e]
                      u[e] =
                        'function' == typeof t
                          ? t
                          : function () {
                              return t
                            }
                    }),
                    h(
                      filterVueComponent(p.__bridgeCurrentVueComponent__, this),
                      _objectSpread2(
                        _objectSpread2(
                          _objectSpread2(
                            _objectSpread2({}, t),
                            n || r || c ? { class: n || r || c } : {}
                          ),
                          o ? { style: o } : {}
                        ),
                        {},
                        { ref: 'use_vue_wrapper' }
                      ),
                      _objectSpread2(
                        {},
                        _.isSlots && this.children
                          ? {
                              default:
                                'function' == typeof this.children
                                  ? this.children
                                  : function () {
                                      return e.children
                                    },
                            }
                          : _objectSpread2({}, u)
                      )
                    )
                  )
                },
              }
            e &&
              ((c = random.getRandomId('__vue_wrapper_container_')),
              (e.id = c),
              (this.__bridgeVueTargetId__ = c),
              (n = _.wrapInstance)
                ? ((n = _.wrapInstance).reactWrapperRef = p)
                : (n = lookupVueWrapperRef(this)),
              n && document.getElementById(c)
                ? ((this.parentVueWrapperRef = n),
                  (this.vuePortal = function (e, t) {
                    return e(Teleport, { to: '#' + c, key: c }, [
                      e(Object.assign(i, { router: r._router })),
                    ])
                  }),
                  n.__bridgePushVuePortal__(this.vuePortal))
                : ((o = createApp(i)),
                  'function' == typeof _.beforeVueAppMount &&
                    _.beforeVueAppMount(o),
                  (this.__bridgeVueInstance__ = o.mount(e))))
          },
        },
        {
          key: 'updateVueComponent',
          value: function (e) {
            this.__bridgeVueInstance__ &&
              (e.__fromReactSlot
                ? (this.__bridgeVueInstance__.children =
                    'function' == typeof e.originVNode
                      ? e.originVNode
                      : function () {
                          return e.originVNode
                        })
                : ((this.__bridgeCurrentVueComponent__ = e),
                  this.__bridgeVueInstance__.$forceUpdate()))
          },
        },
        {
          key: 'render',
          value: function () {
            return createElement(this.__bridgeVueComponentContainer__, {
              portals: this.state.portals,
            })
          },
        },
      ])
    )
  })()
function applyVueInReact(r) {
  var n = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {},
    e =
      (r || console.warn('Component must be passed in applyVueInReact!'),
      r.__esModule && r.default && (r = r.default),
      forwardRef(function (e, t) {
        return createElement(
          VueContainer,
          _extends(
            {},
            e,
            { component: r, ref: t },
            _defineProperty({}, optionsName, n)
          )
        )
      }))
  return (e.originVueComponent = r), e
}
function lazyVueInReact(e, t) {
  return lazy(function () {
    return e().then(function (e) {
      return { default: applyVueInReact(e.default, t) }
    })
  })
}
function lazyReactInVue(e, t) {
  function r() {
    return n().then(function (e) {
      return applyReactInVue(e.default, t)
    })
  }
  var n = e
  'object' === _typeof(e) && (n = e.loader)
  return defineAsyncComponent(
    'object' === _typeof(e)
      ? _objectSpread2(_objectSpread2({}, e), {}, { loader: r })
      : r
  )
}
function injectPropsFromWrapper(e, t) {
  return (
    console.warn(
      "[bridge warn]: HOC injectPropsFromWrapper is deprecated! Try using 'useInjectPropsFromWrapper' in the options of 'applyReactInVue' or 'applyVueInReact'!"
    ),
    'function' != typeof e
      ? console.warn(
          "[bridge warn]: parameter 'injectionHook' is not a function"
        )
      : (t.__bridgeInjectPropsFromWrapper__ = e),
    t
  )
}
var _excluded$2 = ['children']
function createCrossingProviderForReactInVue(e) {
  var r = createContext({})
  return [
    function () {
      return useContext(r)
    },
    applyReactInVue(
      function (e) {
        var t = e.children,
          e = _objectWithoutProperties(e, _excluded$2)
        return createElement(r.Provider, { value: _objectSpread2({}, e) }, t)
      },
      { useInjectPropsFromWrapper: e }
    ),
    r,
  ]
}
var random$1 = new _default()
function createCrossingProviderForVueInReact(e, r) {
  return (
    (r = r || random$1.getRandomId('bridgeCrossingProvide_')),
    [
      function () {
        return inject(r)
      },
      applyVueInReact(
        {
          setup: function (e, t) {
            return (
              provide(r, t.attrs),
              function () {
                return h(t.slots.default)
              }
            )
          },
        },
        { useInjectPropsFromWrapper: e }
      ),
    ]
  )
}
function createReactMissVue(e) {
  var t = e.useVueInjection,
    e = e.beforeVueAppMount,
    t = _slicedToArray(createCrossingProviderForReactInVue(t), 3),
    r = t[0],
    n = t[1],
    t = t[2]
  return [r, applyVueInReact(n, { beforeVueAppMount: e }), t]
}
function transformer(e) {
  var t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {},
    r = t.globalName,
    n = t.combinedOption,
    o = (t.transparentApi, applyReactInVue(e, n || {}))
  return (
    (o.install = function (e) {
      var t = (
        1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {}
      ).globalName
      return r && e.component(t || r, o), o
    }),
    o
  )
}
function toCamelCase(e) {
  return e.replace(/-(\w)/g, function (e, t) {
    return t.toUpperCase()
  })
}
function formatStyle(t) {
  var r
  return t
    ? 'string' == typeof t
      ? (t = t.trim()).split(/\s*;\s*/).reduce(function (e, t) {
          return (
            t &&
              2 === (t = t.split(/\s*:\s*/)).length &&
              Object.assign(e, _defineProperty({}, toCamelCase(t[0]), t[1])),
            e
          )
        }, {})
      : 'object' === _typeof(t)
        ? ((r = {}),
          Object.keys(t).forEach(function (e) {
            r[toCamelCase(e)] = t[e]
          }),
          r)
        : {}
    : {}
}
function formatClass(t) {
  return t
    ? t instanceof Array
      ? t
      : 'string' == typeof t
        ? (t = t.trim()).split(/\s+/)
        : 'object' === _typeof(t)
          ? Object.keys(t).filter(function (e) {
              return !!t[e]
            })
          : []
    : []
}
var _excluded$3 = ['ref']
function getChildInfo(r, e, o, a, c) {
  var t = r.props || {},
    t = (t.ref, _objectWithoutProperties(t, _excluded$3)),
    u = {},
    n =
      (Object.keys(r.children || {}).forEach(function (t) {
        var n = r.children[t],
          e = originOptions.react.vueNamedSlotsKey.find(function (e) {
            return 0 === t.indexOf(e)
          })
        e || 'default' === t
          ? ((e = t
              .replace(new RegExp('^'.concat(e)), '')
              .replace(/^default$/, 'children')),
            (u[e] = a.call(r.__top__, n(), o, c)))
          : 'function' == typeof n &&
            (u[t] = function () {
              for (
                var e = arguments.length, t = new Array(e), r = 0;
                r < e;
                r++
              ) {
                t[r] = arguments[r]
              }
              return (n.__reactArgs = t), a(n.apply(this, t), o, c)
            })
      }),
      {}),
    i = formatStyle(t.style),
    s = Array.from(new Set(formatClass(t.class))).join(' ')
  return (
    0 < Object.keys(i).length && (n.style = i),
    '' !== s && (n.className = s),
    Object.assign(t, _objectSpread2(_objectSpread2({}, n), u)),
    delete t.class,
    t
  )
}
function isTextOwner(e) {
  return e.type === Text
}
var random$2 = new _default()
function DirectiveHOC(e, t) {
  var r
  return 0 < (null == (r = e.dirs) ? void 0 : r.length)
    ? createElement(FakeDirective, { vnode: e }, t)
    : t
}
var FakeDirective = (function () {
  function r(e) {
    var t
    return (
      _classCallCheck(this, r),
      ((t = _callSuper(this, r, [e])).state = {
        prevVnode: null,
        savedDirectives: [],
        ref: null,
        prevProps: e,
      }),
      t
    )
  }
  return (
    _inherits(r, Component),
    _createClass(r, [
      {
        key: 'findDirectiveName',
        value: function (e) {
          var r = e.dir,
            n = -1
          return [
            this.state.savedDirectives.find(function (e, t) {
              if (e.dir === r) {
                return (n = t), !0
              }
            }),
            n,
          ]
        },
      },
      {
        key: 'doDirective',
        value: function () {
          var i = this,
            e = this.state,
            s = e.savedDirectives
          if (!(l = e.ref)) {
            for (
              var l = (this._reactInternals || this._reactInternalFiber).child;
              l && 5 !== l.tag;

            ) {
              l = l.child
            }
            if (!l) {
              return
            }
            l = l.stateNode
          }
          var p = this.props.vnode,
            e = p.dirs
          e &&
            (e.forEach(function (e) {
              var t, r, n, o, a, c, u
              e &&
                ((u = (t = _slicedToArray(i.findDirectiveName(e), 2))[0]),
                (t = t[1]),
                (r = (a = e.dir).created),
                (n = a.beforeMount),
                (o = a.mounted),
                (c = a.beforeUpdate),
                (a = a.updated),
                u
                  ? ((s[t] = _objectSpread2(
                      _objectSpread2(_objectSpread2({}, u), e),
                      {},
                      { oldValue: u.oldValue }
                    )),
                    (u = [l, s[t], p, i.state.prevVnode]),
                    null != c && c.apply(null, u),
                    null != a && a.apply(null, u),
                    (s[t].oldValue = e.value))
                  : (s.push(e),
                    (c = [l, e, p, null]),
                    null != r && r.apply(null, c),
                    null != n && n.apply(null, c),
                    null != o && o.apply(null, c),
                    (e.oldValue = e.value)))
            }),
            this.setState({
              prevVnode: _objectSpread2({}, p),
              savedDirectives: s,
              ref: l,
            }))
        },
      },
      {
        key: 'componentDidMount',
        value: function () {
          this.doDirective()
        },
      },
      {
        key: 'componentDidUpdate',
        value: function (e) {
          e.vnode !== this.props.vnode && this.doDirective()
        },
      },
      {
        key: 'componentWillUnmount',
        value: function () {
          var a = this,
            c = this.props.vnode,
            e = this.state,
            u = e.savedDirectives,
            i = e.ref,
            s = e.prevVnode,
            e = c.dirs
          e &&
            (e.forEach(function (e) {
              var t, r, n, o
              e &&
                ((t = (o = _slicedToArray(a.findDirectiveName(e), 2))[0]),
                (o = o[1]),
                t) &&
                ((r = (n = e.dir).beforeUnmount),
                (n = n.unmounted),
                (u[o] = _objectSpread2(_objectSpread2({}, t), e)),
                (o = [i, t, c, s]),
                null != r && r.apply(null, o),
                null != n) &&
                n.apply(null, o)
            }),
            this.setState({
              prevVnode: _objectSpread2({}, c),
              savedDirectives: u,
            }))
        },
      },
      {
        key: 'render',
        value: function () {
          var e = this.props
          e.vnode
          return e.children
        },
      },
    ])
  )
})()
function couldBeClass(e, t) {
  var r
  return (
    'function' == typeof e &&
    ((r = e.toString()), void 0 !== e.prototype) &&
    e.prototype.constructor === e &&
    ('class' == r.slice(0, 5) ||
      2 <= Object.getOwnPropertyNames(e.prototype).length ||
      (!/^function\s+\(|^function\s+anonymous\(/.test(r) &&
        (!(!t || !/^function\s+[A-Z]/.test(r)) ||
          (!!/\b\(this\b|\bthis[\.\[]\b/.test(r) &&
            (!(t && !/classCallCheck\(this/.test(r)) ||
              /^function\sdefault_\d+\s*\(/.test(r))))))
  )
}
function resolveRef(o, r) {
  var e, n, t, a
  return 'function' !=
    typeof (null == (t = o.type) ? void 0 : t.originReactComponent) ||
    couldBeClass(null == (t = o.type) ? void 0 : t.originReactComponent)
    ? (null != (t = o.ref) && t.k
        ? ((e = null == (t = o.ref) ? void 0 : t.k),
          (n = null == (t = o.ref) ? void 0 : t.r))
        : (e = null == (t = o.ref) ? void 0 : t.r),
      e &&
        'string' == typeof e &&
        ((a = e),
        (e = function (e) {
          var t
          null != (t = o.ref) &&
            null != (t = t.i) &&
            t.refs &&
            (((t = _objectSpread2({}, o.ref.i.refs))[a] = e),
            (o.ref.i.refs = t)),
            n
              ? (n.value = e)
              : o.ref.i.setupState &&
                a in o.ref.i.setupState &&
                (o.ref.i.setupState[a] = e),
            e &&
              (e.__syncUpdateProps = function () {
                r.__top__ &&
                  ((o.__extraData =
                    0 < arguments.length && void 0 !== arguments[0]
                      ? arguments[0]
                      : {}),
                  r.__top__.__syncUpdateProps({}))
              })
        }),
        (e = new Proxy(e, {
          get: function (e, t) {
            return e[t]
          },
          set: function (e, t, r) {
            var n
            return (
              null != (n = o.ref) &&
                null != (n = n.i) &&
                n.refs &&
                a in
                  (null == (n = o.ref) || null == (n = n.i)
                    ? void 0
                    : n.refs) &&
                (((n = _objectSpread2({}, o.ref.i.refs))[t] = r),
                (o.ref.i.refs = n)),
              r
            )
          },
        }))),
      e)
    : null
}
function addScopeId(t, e) {
  return (
    !e ||
      (e instanceof Array && 0 === e.length) ||
      ('string' == typeof e && (e = [e]),
      ((t = _objectSpread2({}, t)).props = _objectSpread2({}, t.props)),
      e.forEach(function (e) {
        t.props[e] = ''
      })),
    t
  )
}
var _excluded$4 = ['style', 'class']
function takeVueDomInReact(e, t, r, n, o, a, c) {
  var u, i, s
  return (
    'all' === t || t instanceof Array || (t = t ? [t] : []),
    e.type === Fragment$1
      ? o.call(c, e.children, r, a)
      : 'string' == typeof e.type && ('all' === t || -1 < t.indexOf(e.type))
        ? ((t = resolveRef(e)),
          (s = (i = e.props || {}).style),
          (u = i.class),
          (i = _objectSpread2(
            _objectSpread2({}, _objectWithoutProperties(i, _excluded$4)),
            {},
            {
              style: formatStyle(s),
              className: Array.from(new Set(formatClass(u))).join(' '),
            },
            t ? { ref: t } : {}
          )),
          (s = e.children || i.children) &&
            ((s =
              -1 < ['string', 'number'].indexOf(_typeof(s))
                ? [s]
                : _toConsumableArray(s)).__top__ = c),
          DirectiveHOC(
            e,
            addScopeId(
              React__default.createElement(e.type, i, o.call(c, s, r, a)),
              e.scopeId
            )
          ))
        : r([e], null, n)
  )
}
function pureInterceptProps() {
  var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : {},
    a = 1 < arguments.length ? arguments[1] : void 0,
    c = 2 < arguments.length ? arguments[2] : void 0
  return (
    c.__syncUpdateForPureReactInVue &&
      Object.keys(c.__syncUpdateForPureReactInVue).map(function (n) {
        var o
        e[n] &&
          'function' == typeof e[n] &&
          a.__top__ &&
          ((o = e[n]),
          (e[n] = function () {
            for (
              var e = arguments.length, t = new Array(e), r = 0;
              r < e;
              r++
            ) {
              t[r] = arguments[r]
            }
            ;(a.__extraData = c.__syncUpdateForPureReactInVue[n].apply(
              this,
              t
            )),
              a.__top__.__bridgeSyncUpdateProps__({}),
              (a.__top__.macroTaskUpdate = !0),
              o.apply(this, t),
              a.__top__ &&
                Promise.resolve().then(function () {
                  ;(a.__extraData = null),
                    a.__top__.__bridgeMountReactComponent__(!0)
                })
          }))
      }),
    e
  )
}
function setChildKey(e, t, r) {
  return (
    !((e = e instanceof Array && 1 === e.length ? e[0] : e) instanceof Array) &&
      null == e.key &&
      1 < t.length &&
      ((e = _objectSpread2({}, e)).key = '_key_'.concat(r)),
    e
  )
}
function getDistinguishReactOrVue(e) {
  var l = e.reactComponents,
    p = e.domTags,
    e = e.division,
    _ = void 0 === e || e
  return function a(c, u, i) {
    var s
    return c && c.forEach
      ? (c.__top__ || (c.__top__ = this),
        (s = []),
        c.forEach(function (e, t) {
          if (e && e.type !== Comment) {
            if (null == (o = e.type) || !o.originReactComponent) {
              return e.$$typeof || 'string' == typeof e || 'number' == typeof e
                ? void s.push(e)
                : isTextOwner(e)
                  ? void ('' !== e.children.trim() && s.push(e.children.trim()))
                  : void (
                      e.type &&
                      (addScopeId(
                        (o = setChildKey(
                          takeVueDomInReact(e, p, u, _, a, i, c.__top__),
                          c,
                          t
                        )),
                        e.scopeId
                      ),
                      s.push(o))
                    )
            }
            var r,
              n,
              o = e.type.originReactComponent
            addScopeId(
              (r = setChildKey(
                (r =
                  'all' === (l = 'all' === l || l instanceof Array ? l : [l]) ||
                  -1 < l.indexOf(o)
                    ? ((e.__top__ = c.__top__),
                      (r = getChildInfo(e, '_key_'.concat(t), u, a, i)),
                      (n = resolveRef(e, c)),
                      e.children && (e.children.__top__ = c.__top__),
                      DirectiveHOC(
                        e,
                        React__default.createElement(
                          o,
                          _objectSpread2(
                            _objectSpread2(
                              _objectSpread2({}, pureInterceptProps(r, e, o)),
                              e.__extraData || {}
                            ),
                            n ? { ref: n } : {}
                          )
                        )
                      ))
                    : isTextOwner(e)
                      ? e.text
                      : takeVueDomInReact(e, p, u, _, a, i)),
                c,
                t
              )),
              e.scopeId
            ),
              s.push(r)
          }
        }),
        1 === s.length ? s[0] : s)
      : c
  }
}
var NoWrapFunction = getDistinguishReactOrVue({
  reactComponents: 'all',
  domTags: 'all',
})
function applyPureReactInVue(e, t) {
  return transformer(e, {
    combinedOption: _objectSpread2(
      {
        pureTransformer: !0,
        defaultSlotsFormatter: NoWrapFunction,
        defaultPropsFormatter: function (t, o, a) {
          var r = {}
          return (
            Object.keys(t).forEach(function (e) {
              var n = t[e]
              n &&
                (n.vueFunction
                  ? ((r[e] = function () {
                      for (
                        var e = arguments.length, t = new Array(e), r = 0;
                        r < e;
                        r++
                      ) {
                        t[r] = arguments[r]
                      }
                      return NoWrapFunction(n.vueFunction.apply(this, t), o, a)
                    }),
                    Object.defineProperty(r[e], 'length', {
                      get: function () {
                        return n.vueFunction.length
                      },
                    }))
                  : n.vueSlot && (r[e] = NoWrapFunction(n.vueSlot, o, a)))
            }),
            Object.assign(t, r)
          )
        },
      },
      t
    ),
  })
}
var NoWrapFunction$1 = getDistinguishReactOrVue({
  reactComponents: 'all',
  domTags: 'all',
})
function getReactNode(e) {
  return (
    (e = (e = [(e = 'function' == typeof e ? e() : e)]).flat(1 / 0)),
    NoWrapFunction$1(e, function (e) {
      return React__default.createElement(VueContainer, { node: e })
    })
  )
}
function transformer$1(e) {
  var t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {},
    r = (t.globalName, t.combinedOption)
  t.transparentApi
  return applyVueInReact(e, r || {})
}
var _excluded$5 = ['ref', 'children', 'v-slots']
function getChildInfo$1(e, t, o, a, c) {
  var e = e.props || {},
    r = (e.ref, e.children),
    n = e['v-slots'],
    u = void 0 === n ? {} : n,
    n = _objectWithoutProperties(e, _excluded$5),
    i =
      (r &&
        ('object' !== _typeof(r) || r instanceof Array || r.$$typeof
          ? (u.default = r)
          : (u = r)),
      null),
    e =
      (Object.keys(u || {}).forEach(function (e) {
        var n = u[e]
        ;(i = i || {})[e] = function () {
          if ('function' == typeof n) {
            for (
              var e = arguments.length, t = new Array(e), r = 0;
              r < e;
              r++
            ) {
              t[r] = arguments[r]
            }
            n = n.apply(this, t)
          }
          return a(n, o, c)
        }
      }),
      {}),
    r = formatStyle(n.style),
    s = Array.from(new Set(formatClass(n.className))).join(' ')
  return (
    0 < Object.keys(r).length && (e.style = r),
    '' !== s && (e.class = s),
    Object.assign(n, _objectSpread2({}, e)),
    delete n.className,
    { props: (n = parseVModel(n)), slots: i }
  )
}
function resolveRef$1(t) {
  var e = t.ref
  if (e) {
    return 'object' === _typeof(e)
      ? function (e) {
          t.ref.current = e
        }
      : 'function' == typeof e
        ? e
        : void 0
  }
}
var _excluded$6 = ['style', 'class', 'children']
function takeReactDomInVue(e, t, r, n, o, a) {
  var c, u, i, s
  return (
    'all' === t || t instanceof Array || (t = t ? [t] : []),
    e.type === Fragment
      ? o(null == (c = e.props) ? void 0 : c.children, r)
      : 'string' == typeof e.type && ('all' === t || -1 < t.indexOf(e.type))
        ? ((c = resolveRef$1(e)),
          (s = (t = e.props || {}).style),
          (i = t.class),
          (u = t.children),
          (t = _objectWithoutProperties(t, _excluded$6)),
          (i = Array.from(new Set(formatClass(i))).join(' ')),
          (s = formatStyle(s)),
          (t = _objectSpread2(
            _objectSpread2(
              _objectSpread2(
                _objectSpread2({}, t),
                0 === Object.keys(s).length ? {} : { style: s }
              ),
              i ? { className: i } : {}
            ),
            c ? { ref: c } : {}
          )),
          0 === Object.keys(t).length && (t = null),
          (s = u) &&
            ((s =
              -1 < ['string', 'number'].indexOf(_typeof(s))
                ? [s]
                : s instanceof Array
                  ? _toConsumableArray(s)
                  : _objectSpread2({}, s)).__top__ = a),
          h(e.type, t, o(s, r)))
        : r([e], null, n)
  )
}
function getDistinguishReactOrVue$1(e) {
  var i = e.vueComponents,
    s = e.domTags,
    e = e.division,
    l = void 0 === e || e
  return function o(a, c) {
    if (null == a) {
      return a
    }
    a instanceof Array || (a = [a])
    var u = []
    return (
      a.forEach(function (e, t) {
        if (
          (null == (r = e.type) || !r.originVueComponent) &&
          e.type !== VueContainer
        ) {
          return e.__v_isVNode || 'string' == typeof e || 'number' == typeof e
            ? void u.push(e)
            : void (
                e.type &&
                ((r = takeReactDomInVue(e, s, c, l, o, a.__top__)), u.push(r))
              )
        }
        var r = e.type.originVueComponent
        if (e.type === VueContainer) {
          if (!e.props.component) {
            return void u.push(e.props.node)
          }
          ;(r = e.props.component), (e = _objectSpread2({}, e))
          var n = _objectSpread2({}, e.props)
          delete n.component, (e.props = n)
        }
        ;(r =
          'all' === (i = 'all' === i || i instanceof Array ? i : [i]) ||
          -1 < i.indexOf(r)
            ? (((e = _objectSpread2({}, e)).__top__ = a.__top__),
              (t = (n = getChildInfo$1(e, '_key_'.concat(t), c, o)).props),
              (n = n.slots),
              resolveRef$1(e),
              e.children && (e.children.__top__ = a.__top__),
              h(r, _objectSpread2({}, t), n))
            : takeReactDomInVue(e, s, c, l, o)),
          u.push(r)
      }),
      1 === (u = u.flat(1 / 0)).length ? u[0] : u
    )
  }
}
var NoWrapFunction$2 = getDistinguishReactOrVue$1({
  vueComponents: 'all',
  domTags: 'all',
})
function applyPureVueInReact(e, t) {
  return transformer$1(e, {
    combinedOption: _objectSpread2(
      { pureTransformer: !0, defaultSlotsFormatter: NoWrapFunction$2 },
      t
    ),
  })
}
var NoWrapFunction$3 = getDistinguishReactOrVue$1({
  reactComponents: 'all',
  domTags: 'all',
})
function getVNode(e) {
  return (
    1 ===
      (e = (e = [(e = 'function' == typeof e ? e() : e)]).flat(1 / 0)).length &&
      (e = e[0]),
    NoWrapFunction$3(e, function (e) {
      return h(WrapVue, { node: e })
    })
  )
}
function lazyReactInVue$1(e, t) {
  function r() {
    return n().then(function (e) {
      return applyPureReactInVue(e.default, t)
    })
  }
  var n = e
  'object' === _typeof(e) && (n = e.loader)
  return defineAsyncComponent(
    'object' === _typeof(e)
      ? _objectSpread2(_objectSpread2({}, e), {}, { loader: r })
      : r
  )
}
function lazyVueInReact$1(e, t) {
  return lazy(function () {
    return e().then(function (e) {
      return { default: applyPureVueInReact(e.default, t) }
    })
  })
}
var _excluded$7 = ['children']
function createCrossingProviderForReactInVue$1(e) {
  var r = createContext({})
  return [
    function () {
      return useContext(r)
    },
    applyPureReactInVue(
      function (e) {
        var t = e.children,
          e = _objectWithoutProperties(e, _excluded$7)
        return createElement(r.Provider, { value: _objectSpread2({}, e) }, t)
      },
      { useInjectPropsFromWrapper: e }
    ),
    r,
  ]
}
var random$3 = new _default()
function createCrossingProviderForVueInReact$1(e, r) {
  return (
    (r = r || random$3.getRandomId('bridgeCrossingProvide_')),
    [
      function () {
        return inject(r)
      },
      applyPureVueInReact(
        {
          setup: function (e, t) {
            return (
              provide(r, t.attrs),
              function () {
                return h(t.slots.default)
              }
            )
          },
        },
        { useInjectPropsFromWrapper: e }
      ),
    ]
  )
}
function injectSyncUpdateForPureReactInVue(e, t) {
  e.__syncUpdateForPureReactInVue || (e.__syncUpdateForPureReactInVue = {}),
    Object.assign(e.__syncUpdateForPureReactInVue, t)
}
export {
  REACT_ALL_HANDLERS,
  WrapVue as RenderReactNode,
  VueContainer,
  applyPureReactInVue,
  applyPureVueInReact,
  applyReactInVue,
  applyVueInReact,
  createCrossingProviderForReactInVue$1 as createCrossingProviderForPureReactInVue,
  createCrossingProviderForVueInReact$1 as createCrossingProviderForPureVueInReact,
  createCrossingProviderForReactInVue,
  createCrossingProviderForVueInReact,
  createReactMissVue,
  getReactNode,
  getVNode,
  injectPropsFromWrapper,
  injectSyncUpdateForPureReactInVue,
  lazyReactInVue$1 as lazyPureReactInVue,
  lazyVueInReact$1 as lazyPureVueInReact,
  lazyReactInVue,
  lazyVueInReact,
  setOptions as setBridgeOptions,
  originOptions as bridgeOptions,
}
