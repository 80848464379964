import { MaybeRef } from '@vueuse/core'
import { ReasonPhrases, StatusCodes } from 'http-status-codes'
import { unref } from 'vue'

export const httpStatusCode = {
  // wraps codes from https://github.com/prettymuchbryce/http-status-codes
  TOO_EARLY: 425, // missing in the lib
  ...StatusCodes,
}

export const httpReasonPhrase = {
  // wraps reason phrases from https://github.com/prettymuchbryce/http-status-codes
  TOO_EARLY: 'Too Early', // missing in the lib
  ...ReasonPhrases,
}

export function isHttpSuccess(
  response: MaybeRef<{
    data?: unknown
    status: number
    statusText?: string
  }>
): boolean {
  const { status } = unref(response)
  return status >= 200 && status < 300
}

export function isHttpError(
  response: MaybeRef<{
    data?: unknown
    status: number
    statusText?: string
  }>
): boolean {
  const { status } = unref(response)
  return status >= 400
}
