<template>
  <v-list-item
    @click="data.onClick"
    :active="isActive"
    class="navigation__subitem"
  >
    <Stack direction="row" justify="space-between" alignItems="center">
      <p class="navigation__subitem__text mb-0">
        {{ data.name }}
      </p>
      <v-chip label color="info">{{ data.clusterType }}</v-chip>
    </Stack>
  </v-list-item>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import { isActiveRoute } from '@/utils/location'

import Stack from '@/components/layout/Stack.vue'
import { ClusterNavigationSubItemData } from '@/components/navigation/NavigationItems'

export default defineComponent({
  name: 'ClusterNavigationSubItem',
  components: { Stack },
  methods: { isActiveRoute },
  props: {
    data: {
      required: true,
      type: Object as PropType<ClusterNavigationSubItemData>,
    },
    active: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const isActive = computed(() => {
      return props.data.clusterUuid === props.active
    })
    return { isActive }
  },
})
</script>

<style scoped lang="scss">
@use '@/styles/typography.scss';
@use '@/styles/mixins.scss' as *;

.navigation__subitem {
  //FIXME Need to replicate this color in our globals as this is a vuetify calculated color
  border-left: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
  padding: 0.5rem;
}

.navigation__subitem__text {
  font-size: 0.9rem;
}

.v-chip.v-chip--size-default {
  @extend %cluster-list-item-chip;
  --v-chip-height: auto;
  padding: 4px 8px;
  color: var(--color-brand-secondary) !important;
  @include lighter(var(--color-brand-secondary), 88%);
}

:deep(.v-chip--variant-tonal .v-chip__underlay) {
  opacity: 0;
}

.v-list-item {
  @extend %cluster-list-item;
  font-size: 1rem !important;
}

.v-list-item--active .v-chip.v-chip--size-default {
  background-color: var(--color-surface-tertiary);
}

.v-list-item--density-default.v-list-item--one-line {
  min-height: 2rem;
}

.v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line {
  padding-inline-start: 1.5rem;
  padding-inline-end: 0.625rem;
}
</style>
