import { AppState, Auth0Provider, useAuth0, User } from '@auth0/auth0-react'
import * as React from 'react'
import { useNavigate } from 'react-router'

import { Status } from '@/@types'

type State = {
  status: Status
  user: User | null
  token: string | null
  isAuthenticated: boolean
}

type Auth = {
  onAuthUpdate: (user: User, token: string) => void
  storeState: State
}

const initialState = {
  status: Status.Loading,
  user: null,
  token: null,
  isAuthenticated: false,
}

export const AuthContext = React.createContext<Auth>({
  storeState: initialState,
} as Auth)

export function AuthProvider({ children }: React.PropsWithChildren) {
  const navigate = useNavigate()
  const auth0 = useAuth0()
  const { auth0_domain, auth0_clientid, auth0_audience } = window.hmqcConfig

  const [storeState, setStoreState] = React.useState<State>(initialState)

  function onAuthUpdate(user: User, token: string) {
    setStoreState((state) => ({
      ...state,
      user,
      token,
      status: Status.Success,
      isAuthenticated: true,
    }))
  }

  function onRedirectCallback(appState?: AppState) {
    navigate((appState && appState.returnTo) || window.location.pathname)
  }

  const value = React.useMemo(
    () => ({ storeState, onAuthUpdate }),
    [storeState, onAuthUpdate]
  )

  return (
    <Auth0Provider
      domain={auth0_domain}
      clientId={auth0_clientid}
      authorizationParams={{
        audience: auth0_audience,
        redirect_uri: window.location.origin,
      }}
      cacheLocation="memory"
      onRedirectCallback={onRedirectCallback}
    >
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    </Auth0Provider>
  )
}
