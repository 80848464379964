import { gtmLogger } from '@/utils/logger/logger'
import {
  GTMCloudEventName,
  GTMCloudEventsDictionary,
  GTMMarketingEventName,
  GTMMarketingEventsDictionary,
} from '@/utils/tracking/googleTagManager/dictionaries'
import { googleTagManagerFactory } from '@/utils/tracking/googleTagManager/utils/googleTagManagerFactory'

const factory = googleTagManagerFactory()

export const googleTagManager = factory.create<
  typeof GTMMarketingEventsDictionary,
  GTMMarketingEventName
>({
  id: 'GTM-5268S8K',
  dataLayerName: 'gtmMarketingDataLayer',
  eventDictionary: GTMMarketingEventsDictionary,
  logger: gtmLogger.extend('marketing'),
})!

googleTagManager.sendEvent(
  GTMMarketingEventName.HiveMQCloud__General_anyViewVisited,
  {}
)

export {
  GTMCloudEventName,
  GTMMarketingEventName,
} from '@/utils/tracking/googleTagManager/dictionaries'
