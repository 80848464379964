import { MaybeRef, useAsyncState } from '@vueuse/core'
import { unref } from 'vue'

import {
  HmqErrorResponse,
  HmqResponse,
  toHmqResponse,
  toHmqResponseError,
  toInitialHmqResponseState,
} from '@/utils/http/httpResponseUtils'

import { Cluster, ClusterType, CloudProvider } from '@/@types/consoleApi.types'
import { $http } from '@/clients/consoleAxiosClient'

export type ClusterConfig = {
  type: ClusterType
  providerType: CloudProvider
  //TODO remove with V1
  isFreeTrial?: boolean
  controlCenterUsername?: string
  controlCenterPassword?: string
}

export type ClusterCreateConfig = {
  clusterCreate: ClusterConfig // TODO: rename to config
  destroy: boolean // TODO: do we net to set this every time => investigate
}

const initialState = {} as Cluster

export function usePostCluster(
  clusterCreateConfig: MaybeRef<ClusterCreateConfig>
) {
  return useAsyncState<HmqResponse<Cluster> | HmqErrorResponse<Cluster>>(
    async () =>
      $http
        .post('clusters', unref(clusterCreateConfig))
        .then(toHmqResponse)
        .catch((error) => toHmqResponseError(error, initialState)),
    toInitialHmqResponseState(initialState)
  )
}
