import { useAsyncState } from '@vueuse/core'

import { components } from '@/generated/openapi/apiary'

import { $httpV2 } from '@/clients/consoleAxiosClient'

export function usePayment() {
  type CostAndBilling = components['schemas']['CustomerPortalSessionResponse']

  function postCostAndBilling(orgId: string) {
    return useAsyncState<CostAndBilling>(async () => {
      return $httpV2
        .post(`orgs/${orgId}/subscriptions/createCustomerPortalSession`)
        .then((response) => response.data)
    }, {} as CostAndBilling)
  }

  return {
    postCostAndBilling,
  }
}
