export function useMixpanel() {
  function trackEvent(eventName: string, properties?: Record<string, any>) {
    try {
      if (properties === undefined) {
        window.mixpanel.track(eventName)
      } else {
        window.mixpanel.track(eventName, properties)
      }
    } catch (e) {
      console.error(e)
    }
  }
  return {
    trackEvent,
  }
}
