import { Auth0Client, RedirectLoginOptions, User } from '@auth0/auth0-spa-js'

import { authenticationLogger } from '@/utils/logger/logger'

export type Auth0User = User

/**
 * Hold the client in memory once it's initialized and work against the service directly without storing it's state
 *
 * !important: This is a singleton and should only be used within this file and not exported ⚠️
 *
 * @readonly
 * @singleton
 */
let auth0Client: Auth0Client | undefined

/**
 * Initialize the Auth0 Client and store it in memory
 * @param client
 */
export function initAuth0(client: Auth0Client) {
  auth0Client = client
}

/**
 * Check if the current user is authenticated
 * @returns determine if the user is authenticated
 */
export function isAuth0UserAuthenticated(): Promise<boolean> {
  return auth0Client!.isAuthenticated()
}

/**
 * Get the current logged in user data
 *
 * {@link https://auth0.com/docs/libraries/auth0-single-page-app-sdk#get-user|Auth0 Docs}
 * @returns the currently logged in user
 */
export function getAuth0User() {
  return auth0Client!.getUser()
}

/**
 * Get the current access token of the user
 *
 * {@link https://auth0.com/docs/libraries/auth0-single-page-app-sdk#get-access-token-with-no-interaction|Auth0 Docs}
 * @returns the token of the current user
 */
export function getAuth0Token() {
  return auth0Client!.getTokenSilently()
}

/**
 * Redirect to the Auth0 login page
 *
 * {@link https://auth0.com/docs/libraries/auth0-single-page-app-sdk#login-and-get-user-info|Auth0 Docs}
 * @param options
 * @returns
 */
export function redirectToAuth0Login(options?: RedirectLoginOptions<any>) {
  return auth0Client!.loginWithRedirect(options)
}

/**
 * Handle authentication callback if the customer is coming from auth0
 *
 * ---
 *
 * It checks if the url contains the required parameters and then calls the handleRedirectCallback function
 * Additionally it's important to check if `code` or `state` are present in the url otherwise the
 * function will loop indefinitely
 *
 * ---
 *
 * !Important: This function should be called in the main.ts file once
 *
 * {@link https://auth0.com/docs/libraries/auth0-single-page-app-sdk#logout|Auth0 Docs}
 * @returns RedirectLoginResult
 */
export function handleAuth0RedirectCallback() {
  authenticationLogger('handleAuth0RedirectCallback: start')

  const { pathname, search } = window.location

  // !important: This function should only be called when the path is `/` otherwise it will loop indefinitely
  if (pathname !== '/') {
    authenticationLogger('handleAuth0RedirectCallback: path is not / skipping ')
    return
  }

  const urlParams = new URLSearchParams(search)

  // Handling should only kick in, if the url contains the required parameters
  if (urlParams.has('code') && urlParams.has('state')) {
    authenticationLogger(
      'handleAuth0RedirectCallback: calling handleRedirectCallback'
    )

    return auth0Client!.handleRedirectCallback().catch(() => {
      // If the state is not present in the url, the function will hard redirect the user to the home page and then to the Auth0 login
      window.location.href = '/'
    })
  }
}

/**
 * Logout the user
 *
 * {@link https://auth0.com/docs/libraries/auth0-single-page-app-sdk#logout-default-|Auth0 Docs}
 * @returns the state of the logout function
 */
export async function logoutAuth0User() {
  const { origin } = window.location

  await auth0Client!.logout({
    logoutParams: {
      returnTo: origin,
    },
  })
}
