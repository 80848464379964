<template>
  <ApplicationWarning
    v-if="featureFlagStore.hasActiveFeatureFlag('MigrationWarning')"
    :title="t('notification.warning.migration.title')"
  >
    <template #description>
      <!-- eslint-disable vue/no-undef-components -->
      <i18n-t keypath="notification.warning.migration.description">
        <a :href="`mailto:${t('meta.email.default')}`">
          {{ t('meta.email.default') }}
        </a>
      </i18n-t>
    </template>
  </ApplicationWarning>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import { useFeatureFlagStore } from '@/store/featureFlagStore'

import ApplicationWarning from './ApplicationWarning.vue'

const featureFlagStore = useFeatureFlagStore()
const { t } = useI18n()
</script>
