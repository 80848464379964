import { VueComponent, applyReactInVue } from './bridge'

/**
 * Inject React context into the Vue component that does not break the render tree
 * @param Component React component that will be converted to Vue
 * @returns
 */
export const defineVueContext = (
  Component: any,
  options: any = {}
): VueComponent => applyReactInVue(Component, options)
