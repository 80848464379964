import { OrganizationSelect } from '@app/molecules/OrganizationSelect/OrganizationSelect'
import { AccountContext } from '@app/store/account/account'
import { ClusterContext } from '@app/store/clusters/clusters'
import { OrganizationContext } from '@app/store/organization/organization'
import { Flex, List, Text, Tooltip, UnorderedList } from '@chakra-ui/react'
import { colors } from '@hivemq/ui-theme'
import {
  BookOpenText,
  CircleHelp,
  CreditCard,
  LogOut,
  MessageSquareQuote,
  PartyPopper,
  User,
} from 'lucide-react'
import * as React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

import { ClusterPackageConfigType } from '@/utils/packages'

import {
  ClusterOverview,
  ClustersOverview,
} from '../ClustersOverview/ClustersOverview'

type Props = {
  onLogout: () => Promise<void>
  expanded: boolean
}

export const hoverStyles = {
  pt: '3',
  pb: '3',
  pl: '2',
  _hover: { backgroundColor: colors.surface[200], borderRadius: '5' },
  cursor: 'pointer',
}

export function Sidebar({ onLogout, expanded }: Props) {
  const { onActiveOrganizationChange, storeState: orgState } =
    React.useContext(OrganizationContext)
  const clusterStore = React.useContext(ClusterContext)
  const accountStore = React.useContext(AccountContext)
  const navigate = useNavigate()

  const clustersArr = Object.entries(clusterStore.clusters).reduce(
    (acc, curr) => {
      const [key, value] = curr
      const type = key as ClusterPackageConfigType
      const overview = value.map((cluster, index) => ({
        id: cluster.id!,
        type,
        name: cluster.plan
          ? cluster.name
          : `${key} #${index + 1}`.toUpperCase(),
      }))

      acc[type] = overview
      return acc
    },
    {} as Record<ClusterPackageConfigType, Array<ClusterOverview>>
  )

  async function handleBilling() {
    const { url } = await accountStore.costAndBilling()
    window.open(url!, '_blank', 'noopener,noreferrer')
  }

  const bottomOptions = [
    { name: "What's new", path: '/release', icon: <PartyPopper /> },
    { name: 'Help', path: '/help', icon: <CircleHelp /> },
    {
      name: 'Documentation',
      path: 'https://docs.hivemq.com/hivemq-cloud/index.html',
      external: true,
      icon: <BookOpenText />,
    },
    { name: 'Feedback', path: '', icon: <MessageSquareQuote /> },
    { name: 'Profile', path: 'profile', icon: <User /> },
    { name: 'Logout', path: '', icon: <LogOut />, onClick: onLogout },
  ]
  const topOptions = [
    {
      name: 'Organizations',
      component: (
        <OrganizationSelect
          isCollapsed={!expanded}
          handleIconAction={() => navigate('/orgs/settings')}
          onActiveOrganisationChange={onActiveOrganizationChange}
          organizations={orgState.orgs.list}
          activeOrganization={orgState.activeOrg.data}
        />
      ),
    },
    {
      name: 'Data',
      component: (
        <ClustersOverview clusters={clustersArr} expanded={expanded} />
      ),
    },
    {
      name: 'Billing',
      component: (
        <Flex alignItems={'center'} onClick={handleBilling} {...hoverStyles}>
          <Tooltip
            children={<CreditCard />}
            label="Billing & Payment"
            isDisabled={expanded}
            placement="right"
          />
          {expanded && (
            <Text fontWeight={'500'} lineHeight={'1rem'} ml={'4'}>
              Billing & Payment
            </Text>
          )}
        </Flex>
      ),
    },
  ]
  return (
    <Flex
      width={'100%'}
      marginLeft={'0'}
      backgroundColor={'#f5f5f5'}
      borderRight={'1px solid #d7d7d7'}
      height={'100%'}
      paddingTop={'3'}
      paddingLeft={'3'}
      paddingRight={'2'}
      direction={'column'}
      justifyContent={'space-between'}
    >
      <UnorderedList listStyleType={'none'} marginLeft={'0'}>
        {topOptions.map((option) => (
          <List mb={'10'} key={option.name}>
            {expanded && (
              <Text fontSize={'sm'} marginBottom={'2'}>
                {option.name}
              </Text>
            )}
            {option.component}
          </List>
        ))}
      </UnorderedList>

      <UnorderedList mb={'5'} ml={'0'}>
        {bottomOptions.map((option) => (
          <List key={option.name} {...hoverStyles}>
            <NavLink
              {...(option.external && {
                href: option.path,
                rel: 'noopener noreferrer',
                target: '_blank',
                relative: 'route',
              })}
              {...(option.name === 'Logout' && { onClick: onLogout })}
              to={option.path}
            >
              <Flex alignItems={'center'}>
                <Tooltip
                  mr={'1'}
                  children={option.icon}
                  isDisabled={expanded}
                  placement="right"
                  label={option.name}
                />
                {expanded && (
                  <Text fontWeight={'500'} ml={'4'} lineHeight={'1rem'}>
                    {option.name}
                  </Text>
                )}
              </Flex>
            </NavLink>
          </List>
        ))}
      </UnorderedList>
    </Flex>
  )
}
