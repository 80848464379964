/**
 * Organisation Management Store
 */

import { whenever } from '@vueuse/core'
import { defineStore } from 'pinia'
import { reactive } from 'vue'

import { components } from '@/generated/openapi/apiary'

import { useClusterStore } from './clusterStore'

import { Status } from '@/@types'
import { useGetOrgId } from '@/modules/cluster/hooks/useGetOrgId'
const { trackEvent, events } = useTracking()
import {
  useAddOrgMember,
  useChangeOrganisationName,
  useGetOrganizationMembers,
  useRemoveAdmin,
} from '@/modules/cluster/hooks/useOrganizationManagement'
import { EventType, useTracking } from '@/modules/tracking/hooks/useTracking'

type StoreState = {
  members: {
    status: Status
    list: components['schemas']['User'][]
    orgId: string
  }
  activeOrg: { status: Status; data: { name: string; id: string } }
  updateMemberStatus: Status
  orgs: {
    status: Status
    list: Array<{ id: string; name: string }>
  }
}

const ACTIVE_ORG_ID = 'activeOrgId'

export const useOrganizationStore = defineStore('orgsMgmt', () => {
  const storeState = reactive<StoreState>({
    members: {
      status: Status.Rest,
      list: [],
      orgId: '',
    },
    activeOrg: { status: Status.Rest, data: { name: '', id: '' } },
    updateMemberStatus: Status.Rest,
    orgs: {
      status: Status.Rest,
      list: [],
    },
  })

  function setupActiveOrg() {
    const localActiveOrgId = retrieveActiveOrg()
    const localActiveOrgExists = storeState.orgs.list.find(
      (o) => o.id === localActiveOrgId
    )

    if (localActiveOrgExists) {
      storeState.activeOrg.data = localActiveOrgExists
    } else {
      storeState.activeOrg.data = storeState.orgs.list?.[0]
      window.localStorage.setItem(ACTIVE_ORG_ID, storeState.orgs.list[0]?.id)
    }
  }

  function setupOrganisationAccounts() {
    // get all available organisations
    const orgs = useGetOrgId()
    storeState.orgs.status = Status.Loading

    whenever(orgs.isReady, () => {
      storeState.orgs.list = orgs.state.value.map((o) => ({
        id: o.org_id!,
        name: o.org_name!,
      }))
      storeState.orgs.status = Status.Success

      // Setup the users's activeOrg
      setupActiveOrg()
      // Get the organisation members of the activeOrg
      getOrgMembers()
    })

    whenever(orgs.error, () => {
      storeState.orgs.status = Status.Error
    })
  }

  function getOrgMembers() {
    const result = useGetOrganizationMembers(storeState.activeOrg.data.id)
    storeState.members.status = Status.Loading

    whenever(result.isReady, () => {
      storeState.members.status = Status.Success
      storeState.members.list = result.state.value
      storeState.members.orgId = storeState.activeOrg.data.id
    })
  }

  function changeOrgName(newName: string) {
    trackEvent(events.hiveMQCloud.userAndOrgMgmt.actions.orgNameChange, {
      type: EventType.userAction,
    })
    const change = useChangeOrganisationName(storeState.activeOrg.data.id, {
      name: newName,
    })

    whenever(change.isReady, () => {
      storeState.activeOrg.status = Status.Success
      storeState.activeOrg.data.name = newName
      let orgIndex = storeState.orgs.list.findIndex(
        (o) => o.id === storeState.activeOrg.data.id
      )
      storeState.orgs.list[orgIndex].name = newName
    })

    whenever(change.error, () => {
      storeState.activeOrg.status = Status.Error
    })
  }

  function inviteTeamMembers(members: Array<string>) {
    trackEvent(events.hiveMQCloud.userAndOrgMgmt.actions.submitInviteMember, {
      type: EventType.userAction,
    })
    const invite = useAddOrgMember(
      storeState.activeOrg.data.id,
      members.slice(0)
    )
    storeState.updateMemberStatus = Status.Loading

    whenever(invite.isReady, () => {
      storeState.updateMemberStatus = Status.Success

      // update list after addition
      getOrgMembers()
    })

    whenever(invite.error, () => {
      storeState.updateMemberStatus = Status.Error
    })
  }

  function removeOrgAdmin(subjectId: string, objectId: string) {
    trackEvent(events.hiveMQCloud.userAndOrgMgmt.actions.removeOrgMember, {
      type: EventType.userAction,
    })
    const remove = useRemoveAdmin(storeState.activeOrg.data.id, objectId)
    storeState.updateMemberStatus = Status.Loading

    whenever(remove.isReady, () => {
      storeState.updateMemberStatus = Status.Success
    })

    whenever(remove.error, () => {
      storeState.updateMemberStatus = Status.Error
      return
    })

    const { getClusters } = useClusterStore()
    // this means user is removing themselve from this organisation
    if (subjectId == objectId) {
      const previousOrg = storeState.activeOrg.data.id
      storeState.orgs.list = storeState.orgs.list.filter(
        (o) => o.id != previousOrg
      )
      window.localStorage.removeItem(ACTIVE_ORG_ID)
      const nextActiveOrg = storeState.orgs.list.find(
        (org) => org.id !== previousOrg
      )
      storeState.activeOrg.data = nextActiveOrg!
      getOrgMembers()
      getClusters()
    } else {
      storeState.members.list = storeState.members.list.filter(
        (m) => m.id !== objectId
      )
    }
  }

  function onActiveOrganisationChange(newOrgId: string) {
    const { getClusters } = useClusterStore()
    const index = storeState.orgs.list.findIndex((org) => org.id === newOrgId)
    if (index !== -1) {
      storeState.activeOrg.data = storeState.orgs.list[index]
      getOrgMembers()
      window.localStorage.setItem(ACTIVE_ORG_ID, storeState.activeOrg.data.id)
      getClusters()
    }
  }

  function retrieveActiveOrg() {
    return window.localStorage.getItem(ACTIVE_ORG_ID)
  }

  function resetAddMembersStatus() {
    storeState.updateMemberStatus = Status.Rest
  }

  if (storeState.orgs.status === Status.Rest) {
    setupOrganisationAccounts()
  }

  return {
    changeOrgName,
    inviteTeamMembers,
    state: storeState,
    resetAddMembersStatus,
    onActiveOrganisationChange,
    removeOrgAdmin,
  }
})
