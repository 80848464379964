<template>
  <Component
    :is="tag"
    :class="[
      'd-flex',
      'gap',
      `flex-${direction}`,
      `justify-${justify}`,
      `align-${align}`,
      `w-${width}`,
    ]"
  >
    <slot name="default" />
  </Component>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import { pxToRem } from '@/utils/units'

type Direction = 'row' | 'column'
type Justify = 'start' | 'end' | 'center' | 'space-between' | 'space-around'
type Align = 'start' | 'end' | 'center' | 'baseline' | 'stretch'
type Width = 'auto' | '25' | '50' | '75' | '100'
type Gap =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'

export default defineComponent({
  name: 'Stack',
  props: {
    /**
     * Align the Stack items on the y-axis but is reversed when using flex-direction: column, modifying the x-axis.
     * Choose from start, end, center, baseline, or stretch (default).
     */
    align: {
      default: 'stretch',
      type: String as PropType<Align>,
    },
    /**
     * Changes the layout direction of Stack.
     * Use row (default) for vertical direction (x-axis) or column for horizontal direction (y-axis)
     */
    direction: {
      default: 'row',
      type: String as PropType<Direction>,
    },
    /**
     * Modifies the flexbox items on the x-axis but is reversed when using
     * flex-direction: column, modifying the y-axis.
     * Choose from start (default), end, center, space-between, or space-around.
     */
    justify: {
      default: 'start',
      type: String as PropType<Justify>,
    },
    /**
     * 0 - eliminates gap by setting it to 0
     * 1 - sets gap to 4px
     * 2 - sets gap to 8px
     * 3 - sets gap to 12px
     * 4 - sets gap to 16px
     * 5 - sets gap to 20px
     * 6 - sets gap to 24px
     * 7 - sets gap to 28px
     * 8 - sets gap to 32px
     * 9 - sets gap to 36px
     * 10 - sets gap to 40px
     * 11 - sets gap to 44px
     * 12 - sets gap to 48px
     * 13 - sets gap to 52px
     * 14 - sets gap to 56px
     * 15 - sets gap to 60px
     * 16 - sets gap to 64px
     */
    gap: {
      default: '4',
      type: String as PropType<Gap>,
    },
    /**
     * The HTML Element the container should be.
     * Default is div.
     */
    tag: {
      default: 'div',
      type: String,
    },
    /**
     * Width of the Stack in percent (or auto sizing).
     * Choose between auto (default), 25, 50, 75, 100.
     */
    width: {
      default: 'auto',
      type: String as PropType<Width>,
    },
  },
  setup(props) {
    // One of a kind method that has a magic *4 modifier
    // eslint-disable-next-line no-magic-numbers
    const gapSize = computed(() => pxToRem(+props.gap * 4))

    return {
      gapSize,
    }
  },
})
</script>

<style scoped>
.gap {
  gap: v-bind(gapSize);
  /* gap: 5rem; */
}
</style>
