import { MaybeRef, useAsyncState } from '@vueuse/core'
import { unref } from 'vue'

import { getErrorCode } from '@/utils/errorCodes.js'
import {
  HmqErrorResponse,
  HmqResponse,
  toHmqResponse,
  toHmqResponseError,
  toInitialHmqResponseState,
} from '@/utils/http/httpResponseUtils'

import { Cluster } from '@/@types/consoleApi.types'
import { $http } from '@/clients/consoleAxiosClient'

export function useUpdateCustomUrl(
  clusterUuid: MaybeRef<string>,
  customUrl: MaybeRef<string>
) {
  return useAsyncState<{}>(async () => {
    return $http
      .patch(`clusters/${clusterUuid}`, {
        type: null,
        additionalUrls: [customUrl],
      })
      .then(
        (response) => {
          return response.data
        },
        (error) => {
          // FIXME: this is a workaround because the returned error object by useAsyncState is always undefined
          let errorCode = error.response.data.code
          return { errors: [getErrorCode(errorCode)] }
        }
      )
  }, {})
}

const initialUpdateClusterState = {} as Cluster

export function useUpdateCluster(
  clusterUuid: MaybeRef<string>,
  clusterType: MaybeRef<string>
) {
  return useAsyncState<HmqResponse<Cluster> | HmqErrorResponse<Cluster>>(
    async () =>
      $http
        .patch(`clusters/${unref(clusterUuid)}`, {
          type: unref(clusterType.valueOf()),
          additionalUrls: null,
        })
        .then(toHmqResponse)
        .catch((error) => toHmqResponseError(error, initialUpdateClusterState)),
    toInitialHmqResponseState(initialUpdateClusterState)
  )
}
