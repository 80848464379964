import { hoverStyles } from '@app/organisms/Sidebar/Sidebar'
import { ReactMigrationLayout } from '@app/templates/ReactMigrationLayout'
import { HStack, Select, Icon, Tooltip, Box } from '@chakra-ui/react'
import { UserRoundCog } from 'lucide-react'
import * as React from 'react'

import styles from './OrganizationSelect.module.css'

import { defineVueContext } from '@/react-migration/defineVueContext'
type Props = {
  isCollapsed: boolean
  handleIconAction: () => void
  onActiveOrganisationChange: (_newOrgId: string) => void
  organizations: Array<{ name: string; id: string }>
  activeOrganization: { name: string; id: string }
}

export function OrganizationSelect({
  isCollapsed,
  handleIconAction,
  onActiveOrganisationChange,
  organizations,
  activeOrganization,
}: Props) {
  function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    e.preventDefault()
    onActiveOrganisationChange(e.target.value)
  }
  return (
    <>
      <ReactMigrationLayout>
        {isCollapsed && (
          <Box {...hoverStyles} onClick={handleIconAction}>
            <Tooltip
              label="organizations"
              placement="right"
              children={<UserRoundCog />}
            />
          </Box>
        )}
        {!isCollapsed && (
          <HStack>
            <Select
              onChange={handleChange}
              backgroundColor="white"
              defaultValue={activeOrganization.id}
            >
              {organizations.map(({ name, id }) => (
                <option value={id} key={id} id={id}>
                  {name}
                </option>
              ))}
            </Select>
            <Icon
              className={styles.icon}
              onClick={handleIconAction}
              fontSize={'1.4rem'}
              mr={isCollapsed ? '' : '10px'}
              color="black"
              opacity={0.7}
            >
              <UserRoundCog />
            </Icon>
          </HStack>
        )}
      </ReactMigrationLayout>
    </>
  )
}
export default defineVueContext(OrganizationSelect)
