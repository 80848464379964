/**
 * We introduced a skip able account creation feature.
 * For that we send `step_skipped` for all fields that are unfilled.
 * This function removes all skipped fields that are being sent back from the backend.
 * TODO: Add extensions for the generic type
 * @param payload
 */
export function removeSkippedAccountPayload<TPayload>(
  payload: TPayload
): TPayload {
  const modifiedPayloadData = { ...payload }

  Object.entries(modifiedPayloadData).forEach(([key, value]) => {
    if (['step_skipped'].includes(value)) {
      Object.assign(modifiedPayloadData, {
        ...modifiedPayloadData,
        [key]: '',
      })
    }
  })

  return modifiedPayloadData
}
