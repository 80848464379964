import { defineStore } from 'pinia'

import { Transition, transitionMap } from '@/@types/animations.types'
import {
  MessageConfig,
  messageConfigs,
  MessageTypes,
} from '@/@types/message.types'

type SnackbarOptions = {
  /**
   * Set the message that will be shown to the user
   * @example
   * `Your cluster was deleted 🫠`
   */
  message: string
  /**
   * Set how long the snackbar navigation should be visible
   * @default 2000ms
   */
  duration?: number
  /**
   * Set the color of the Notification
   * @default 'info'
   */
  type?: MessageTypes
  /**
   * Toggle if the action should have a close button
   * @default true
   */
  dismissible?: boolean
}

type SnackbarItem = Pick<SnackbarOptions, 'message'> & {
  id: number
  visible: boolean
  updateHandler: (_value: boolean) => void
  transition: Transition
  timeout: number
  type: MessageConfig
  attrs: Record<'top' | 'right' | 'bottom' | 'left', boolean>
  dismissible: boolean
}

const DEFAULT_OPTIONS: Omit<
  SnackbarItem,
  'id' | 'visible' | 'updateHandler' | 'message'
> = {
  timeout: 2000,
  transition: transitionMap.slideYReverse,
  dismissible: true,
  type: messageConfigs.info,
  attrs: {
    top: false,
    right: false,
    bottom: false,
    left: false,
  },
}

/**
 * Connect to the `snackbarStore` to show users important notifications
 * @example
 * const snackbarStore = useSnackbarStore()
 *
 * snackbarStore.createNotification({
 *   message: 'URL was copied to the clipboard',
 *   type: 'info',
 *   duration: 2000,
 * })
 */
export const useSnackbarStore = defineStore({
  id: 'snackbar',
  state() {
    return {
      items: [] as ReadonlyArray<SnackbarItem>,
    }
  },
  actions: {
    createNotification(options: SnackbarOptions) {
      const id = this.count + 1

      const timeout = options.duration || DEFAULT_OPTIONS.timeout

      const type = options.type
        ? messageConfigs[options.type]
        : DEFAULT_OPTIONS.type

      this.items = [
        ...this.items,
        {
          ...options,
          type,
          visible: true,
          updateHandler(_newVisibleState) {
            // TODO: Add some sort of callback that the dev can use to add a follow up state
          },
          attrs: DEFAULT_OPTIONS.attrs,
          dismissible: options.dismissible || DEFAULT_OPTIONS.dismissible,
          transition: DEFAULT_OPTIONS.transition,
          timeout,
          id,
        },
      ]
    },
  },
  getters: {
    count(): number {
      return this.items.length
    },
  },
})
