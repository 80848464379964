import axios from 'axios'

import { useFeatureFlagStore } from '@/store/featureFlagStore'

import { httpLogger, httpV2Logger } from '@/utils/logger/logger'

// TODO: Next line with fallback is added to prevent broken test setup from failing (false positives).
// Tests are currently disabled because they need to be re-build from ground up.
// This is the original line form before:  let baseUrl = window.hmqcStaticConfig.API_BASE_URL
let baseUrl =
  window?.hmqcStaticConfig?.API_BASE_URL ??
  'http://local-vitest.dev:1337/testing/foo'

if (baseUrl.substr(-1) !== '/') {
  baseUrl += '/'
}

const httpRequestTypes = ['get', 'options', 'head']

export const $http = axios.create({
  baseURL: baseUrl + 'api/v1/',
})

export const $httpV2 = axios.create({
  baseURL: baseUrl + 'api/v2/',
})

/**
 * Abort write requests when the migration feature flag is active
 * @param request
 * @returns {{request, signal: AbortSignal}|void|*}
 */
function handleMigrationState(request) {
  if (request.signal?.aborted) {
    httpLogger('request already aborted', { url: request.url })
    return request
  }

  const store = useFeatureFlagStore()
  const controller = new AbortController()

  if (store.isMigrationActive) {
    const usedRequestType = request.method.toLowerCase()
    if (!httpRequestTypes.includes(usedRequestType)) {
      controller.abort()
    }
  }

  return { ...request, signal: controller.signal }
}

/**
 * Prevent unnecessary 401 calls that are usually made on first mount of the application
 * @param request
 * @returns {*&{signal: AbortSignal}}
 */
function preventUnauthorizedRequests(request) {
  if (request.signal?.aborted) {
    httpLogger('request already aborted', { url: request.url })
    return request
  }

  const controller = new AbortController()
  const hasAuthorization = request.headers.common?.Authorization
  const isSettingsRequest = request.url === 'settings/frontend'
  if (!hasAuthorization && !isSettingsRequest) {
    controller.abort()
  }

  return {
    ...request,
    signal: controller.signal,
  }
}

/**
 * Abort almost all network requests to the V2 API if
 * the business module is inactive.
 * @param request
 * @returns {(*&{signal: AbortSignal})|*}
 */
function abortInactiveBusinessModuleRequests(request) {
  if (request.signal?.aborted) {
    httpLogger('request already aborted', { url: request.url })
    return request
  }

  const allowedUrls = ['feature-flags', 'orgs']
  const isAllowedUrl = allowedUrls.some((url) => url === request.url)

  if (isAllowedUrl) {
    httpV2Logger('send request', request.url, request)
    return request
  }

  const controller = new AbortController()

  httpV2Logger('send request', request.url, request)

  return {
    ...request,
    signal: controller.signal,
  }
}

function defaultErrorHandler(error) {
  return Promise.reject(error)
}

$http.interceptors.request.use(handleMigrationState, defaultErrorHandler)
$http.interceptors.request.use(preventUnauthorizedRequests, defaultErrorHandler)

$httpV2.interceptors.request.use(
  preventUnauthorizedRequests,
  defaultErrorHandler
)
$httpV2.interceptors.request.use(
  abortInactiveBusinessModuleRequests,
  defaultErrorHandler
)
