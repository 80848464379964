import { useLaunchDarkly } from '@/modules/tracking/hooks/useLaunchDarkly'
import { useMixpanel } from '@/modules/tracking/hooks/useMixpanel'

export enum EventName {
  'HiveMQCloud__Navigation_clustersViewVisited' = 'HiveMQCloud__Navigation_clustersViewVisited',
  'HiveMQCloud__Navigation_billingViewVisited' = 'HiveMQCloud__Navigation_billingViewVisited',
  'HiveMQCloud__Navigation_helpViewVisited' = 'HiveMQCloud__Navigation_helpViewVisited',
  'HiveMQCloud__Navigation_clusterOverviewVisited' = 'HiveMQCloud__Navigation_clusterOverviewVisited',
  'HiveMQCloud__Navigation_integrationsViewVisited' = 'HiveMQCloud__Navigation_integrationsViewVisited',
  'HiveMQCloud__Navigation_gettingStartedViewVisited' = 'HiveMQCloud__Navigation_gettingStartedViewVisited',
  'HiveMQCloud__Navigation_credentialsViewVisited' = 'HiveMQCloud__Navigation_credentialsViewVisited',
  'HiveMQCloud__Navigation_webClientViewVisited' = 'HiveMQCloud__Navigation_webClientViewVisited',
  'HiveMQCloud__Navigation_homeLinkClicked' = 'HiveMQCloud__Navigation_homeLinkClicked',
  'HiveMQCloud__Navigation_forumLinkClicked' = 'HiveMQCloud__Navigation_forumLinkClicked',
  'HiveMQCloud__Navigation_paymentAndBillingViewVisited' = 'HiveMQCloud__Navigation_paymentAndBillingViewVisited',
  'HiveMQCloud__Navigation_releaseNotesVisited' = 'HiveMQCloud__Navigation_releaseNotesVisited',
  'HiveMQCloud__Navigation_feedbackVisited' = 'HiveMQCloud__Navigation_feedbackVisited',
  'HiveMQCloud__Navigation_controlCenterViewVisited' = 'HiveMQCloud__Navigation_controlCenterViewVisited',
  'HiveMQCloud__Navigation_accessManagementVisited' = 'HiveMQCloud__Navigation_accessManagementVisited',
  'HiveMQCloud__Navigation_logOutVisited' = 'HiveMQCloud__Navigation_logOutVisited',
  'HiveMQCloud__Navigation_apiAccessViewVisited' = 'HiveMQCloud__Navigation_apiAccessViewVisited',
  'HiveMQCloud__Navigation_jwtAuthenticationViewVisited' = 'HiveMQCloud__Navigation_jwtAuthenticationViewVisited',
  'HiveMQCloud__General_emailContactInitialized' = 'HiveMQCloud__General_emailContactInitialized',
  'HiveMQCloud__General_documentationLinkVisited' = 'HiveMQCloud__General_documentationLinkVisited',
  'HiveMQCloud__General_initialAccountDetailsAdded' = 'HiveMQCloud__General_initialAccountDetailsAdded',
  'HiveMQCloud__General_handRaiserContactUsSelected' = 'HiveMQCloud__General_handRaiserContactUsSelected',
  'HiveMQCloud__General_verificationEmailNotReceived' = 'HiveMQCloud__General_verificationEmailNotReceived',
  'HiveMQCloud__General_supportLinkVisited' = 'HiveMQCloud__General_supportLinkVisited',
  'HiveMQCloud__General_faqLinkVisited' = 'HiveMQCloud__General_faqLinkVisited',
  'HiveMQCloud__Navigation_verifyEmailVisited' = 'HiveMQCloud__Navigation_verifyEmailVisited',
  'HiveMQCloud__Navigation_completeProfileVisited' = 'HiveMQCloud__Navigation_completeProfileVisited',
  'HiveMQCloud__Navigation_documentationViewVisited' = 'HiveMQCloud__Navigation_documentationViewVisited',
  'HiveMQCloud__ExternalNavigation_edgeRepositoryVisited' = 'HiveMQCloud__ExternalNavigation_edgeRepositoryVisited',
  'HiveMQCloud__ExternalNavigation_edgeDocumentationVisited' = 'HiveMQCloud__ExternalNavigation_edgeDocumentationVisited',
  'HiveMQCloud__Cluster_cloudProviderSelected' = 'HiveMQCloud__Cluster_cloudProviderSelected',
  'HiveMQCloud__Cluster_mqttClientCredentialsAdded' = 'HiveMQCloud__Cluster_mqttClientCredentialsAdded',
  'HiveMQCloud__Cluster_mqttClientCredentialsDeleted' = 'HiveMQCloud__Cluster_mqttClientCredentialsDeleted',
  'HiveMQCloud__Cluster_clusterCreated' = 'HiveMQCloud__Cluster_clusterCreated',
  'HiveMQCloud__Cluster_clusterDeleted' = 'HiveMQCloud__Cluster_clusterDeleted',
  'HiveMQCloud__Cluster_clusterTypeChosen' = 'HiveMQCloud__Cluster_clusterTypeChosen',
  'HiveMQCloud__Cluster_createNewButtonClicked' = 'HiveMQCloud__Cluster_createNewButtonClicked',
  'HiveMQCloud__Cluster_createNewButtonFailed' = 'HiveMQCloud__Cluster_createNewButtonFailed',
  'HiveMQCloud__Cluster_upgradeButtonClicked' = 'HiveMQCloud__Cluster_upgradeButtonClicked',
  'HiveMQCloud__Cluster_configurationStepChooseBillingPlanOption' = 'HiveMQCloud__Cluster_configurationStepChooseBillingPlanOption',
  'HiveMQCloud__Cluster_configurationStepVisited' = 'HiveMQCloud__Cluster_configurationStepVisited',
  'HiveMQCloud__Cluster_choosePackageStepVisited' = 'HiveMQCloud__Cluster_choosePackageStepVisited',
  'HiveMQCloud__Cluster_deleteDialogOpened' = 'HiveMQCloud__Cluster_deleteDialogOpened',
  'HiveMQCloud__Cluster_deleteDialogDelete' = 'HiveMQCloud__Cluster_deleteDialogDelete',
  'HiveMQCloud__Cluster_deleteDialogCancel' = 'HiveMQCloud__Cluster_deleteDialogCancel',
  'HiveMQCloud__Cluster_manageButtonClicked' = 'HiveMQCloud__Cluster_manageButtonClicked',
  'HiveMQCloud__DomainName_customDomainBannerClicked' = 'HiveMQCloud__DomainName_customDomainBannerClicked',
  'HiveMQCloud__DomainName_customizeDomainNameClicked' = 'HiveMQCloud__DomainName_customizeDomainNameClicked',
  'HiveMQCloud__DomainName_customDomainBannerClosed' = 'HiveMQCloud__DomainName_customDomainBannerClosed',
  'HiveMQCloud__DomainName_customDomainNameCreated' = 'HiveMQCloud__DomainName_customDomainNameCreated',
  'HiveMQCloud__Client_clientConnected' = 'HiveMQCloud__Client_clientConnected',
  'HiveMQCloud__Client_clientDisconnected' = 'HiveMQCloud__Client_clientDisconnected',
  'HiveMQCloud__Client_clientConnectedAutomatically' = 'HiveMQCloud__Client_clientConnectedAutomatically',
  'HiveMQCloud__Client_topicAdded' = 'HiveMQCloud__Client_topicAdded',
  'HiveMQCloud__Client_messagePublished' = 'HiveMQCloud__Client_messagePublished',
  'HiveMQCloud__Client_subscribed' = 'HiveMQCloud__Client_subscribed',
  'HiveMQCloud__Payment_billingAddressAdded' = 'HiveMQCloud__Payment_billingAddressAdded',
  'HiveMQCloud__Payment_billingAddressUpdated' = 'HiveMQCloud__Payment_billingAddressUpdated',
  'HiveMQCloud__Payment_creditCardAdded' = 'HiveMQCloud__Payment_creditCardAdded',
  'HiveMQCloud__Payment_billingDetailsOpened' = 'HiveMQCloud__Payment_billingDetailsOpened',
  'HiveMQCloud__Integrations_configSaved' = 'HiveMQCloud__Integrations_configSaved',
  'HiveMQCloud__Integrations_configRemoved' = 'HiveMQCloud__Integrations_configRemoved',
  'HiveMQCloud__Integrations_configError' = 'HiveMQCloud__Integrations_configError',
  'HiveMQCloud__Integrations_integrationSelected' = 'HiveMQCloud__Integrations_integrationSelected',
  'HiveMQCloud__Release_learnMoreSelected' = 'HiveMQCloud__Release_learnMoreSelected',
  'HiveMQCloud__Release_learnMoreClosed' = 'HiveMQCloud__Release_learnMoreClosed',
  'HiveMQCloud__Release_tryFeatureSelected' = 'HiveMQCloud__Release_tryFeatureSelected',
  'HiveMQCloud__Integrations_searchSelected' = 'HiveMQCloud__Integrations_searchSelected',
  'HiveMQCloud__Integrations_cantFindSelected' = 'HiveMQCloud__Integrations_cantFindSelected',
  'HiveMQCloud__Integrations_whatsNewSelected' = 'HiveMQCloud__Integrations_whatsNewSelected',
  'HiveMQCloud__Integrations_whatsNewModalOpen' = 'HiveMQCloud__Integrations_whatsNewModalOpen',
  'HiveMQCloud__Integrations_whatsNewModalConfirmationSelected' = 'HiveMQCloud__Integrations_whatsNewModalConfirmationSelected',
  'HiveMQCloud__ACL_createCredentialClicked' = 'HiveMQCloud__ACL_createCredentialClicked',
  'HiveMQCloud__ACL_createPermissionClicked' = 'HiveMQCloud__ACL_createPermissionClicked',
  'HiveMQCloud__ACL_deleteCredentialClicked' = 'HiveMQCloud__ACL_deleteCredentialClicked',
  'HiveMQCloud__ACL_deletePermissionClicked' = 'HiveMQCloud__ACL_deletePermissionClicked',
  'HiveMQCloud__ACL_deletePermissionModalOpened' = 'HiveMQCloud__ACL_deletePermissionModalOpened',
  'HiveMQCloud__ACL_deletePermissionModal_deleteOk' = 'HiveMQCloud__ACL_deletePermissionModal_deleteOk',
  'HiveMQCloud__ACL_deletePermissionModal_deletedCancel' = 'HiveMQCloud__ACL_deletePermissionModal_deletedCancel',
  'HiveMQCloud__ACL_deleteRoleClicked' = 'HiveMQCloud__ACL_deleteRoleClicked',
  'HiveMQCloud__RBAC_createCredentialClicked' = 'HiveMQCloud__RBAC_createCredentialClicked',
  'HiveMQCloud__RBAC_createPermissionClicked' = 'HiveMQCloud__RBAC_createPermissionClicked',
  'HiveMQCloud__RBAC_createRoleClicked' = 'HiveMQCloud__RBAC_createRoleClicked',
  'HiveMQCloud__RBAC_deleteRoleClicked' = 'HiveMQCloud__RBAC_deleteRoleClicked',
  'HiveMQCloud__RBAC_updateRoleClicked' = 'HiveMQCloud__RBAC_updateRoleClicked',
  'HiveMQCloud__RBAC_deleteCredentialClicked' = 'HiveMQCloud__RBAC_deleteCredentialClicked',
  'HiveMQCloud__RBAC_deletePermissionClicked' = 'HiveMQCloud__RBAC_deletePermissionClicked',
  'HiveMQCloud__RBAC_updatePermissionClicked' = 'HiveMQCloud__RBAC_updatePermissionClicked',
  'HiveMQCloud__Certificate_add' = 'HiveMQCloud__Certificate_add',
  'HiveMQCloud__Certificate_delete' = 'HiveMQCloud__Certificate_delete',
  'HiveMQCloud__GettingStarted_createCredential' = 'HiveMQCloud__GettingStarted_createCredential',
  'HiveMQCloud__GettingStarted_selectGuide' = 'HiveMQCloud__GettingStarted_selectGuide',
  'HiveMQCloud__GettingStarted_learningMaterial' = 'HiveMQCloud__GettingStarted_learningMaterial',
  'HiveMQCloud__JWT_addClaim' = 'HiveMQCloud__JWT_addClaim',
  'HiveMQCloud__JWT_deleteClaim' = 'HiveMQCloud__JWT_deleteClaim',
  'HiveMQCloud__JWT_saveConfiguration' = 'HiveMQCloud__JWT_saveConfiguration',
  'HiveMQCloud__JWT_deleteConfiguration' = 'HiveMQCloud__JWT_deleteConfiguration',
  'HiveMQCloud__APIToken_indexPageVisited' = 'HiveMQCloud__APIToken_indexPageVisited',
  'HiveMQCloud__APIToken_detailsPageVisited' = 'HiveMQCloud__APIToken_detailsPageVisited',
  'HiveMQCloud__APIToken_createPageVisited' = 'HiveMQCloud__APIToken_createPageVisited',
  'HiveMQCloud__APIToken_createToken' = 'HiveMQCloud__APIToken_createToken',
  'HiveMQCloud__APIToken_deleteToken' = 'HiveMQCloud__APIToken_deleteToken',
  'HiveMQCloud__APIToken_copyRestURL' = 'HiveMQCloud__APIToken_copyRestURL',
  'HiveMQCloud__Organization_settingsPageVisited' = 'HiveMQCloud__Organization_settingsPageVisited',
  'HiveMQCloud__Organization_settingsNameUpdated' = 'HiveMQCloud__Organization_settingsNameUpdated',
  'HiveMQCloud__Organization_membersPageVisited' = 'HiveMQCloud__Organization_membersPageVisited',
  'HiveMQCloud__Organization_membersAddClicked' = 'HiveMQCloud__Organization_membersAddClicked',
  'HiveMQCloud__Organization_membersAdded' = 'HiveMQCloud__Organization_membersAdded',
  'HiveMQCloud__Organization_membersRemoved' = 'HiveMQCloud__Organization_membersRemoved',
}

export const events = {
  hiveMQCloud: {
    navigation: {
      clustersViewVisited:
        EventName.HiveMQCloud__Navigation_clustersViewVisited,
      billingViewVisited: EventName.HiveMQCloud__Navigation_billingViewVisited,
      helpViewVisited: EventName.HiveMQCloud__Navigation_helpViewVisited,
      integrationsViewVisited:
        EventName.HiveMQCloud__Navigation_integrationsViewVisited,
      gettingStartedViewVisited:
        EventName.HiveMQCloud__Navigation_gettingStartedViewVisited,
      credentialsViewVisited:
        EventName.HiveMQCloud__Navigation_credentialsViewVisited,
      clusterOverviewVisited:
        EventName.HiveMQCloud__Navigation_clusterOverviewVisited,
      webClientViewVisited:
        EventName.HiveMQCloud__Navigation_webClientViewVisited,
      paymentAndBillingViewVisited:
        EventName.HiveMQCloud__Navigation_paymentAndBillingViewVisited,
      homeLinkClicked: EventName.HiveMQCloud__Navigation_homeLinkClicked,
      forumLinkClicked: EventName.HiveMQCloud__Navigation_forumLinkClicked,
      verifyEmailVisited: EventName.HiveMQCloud__Navigation_verifyEmailVisited,
      completeProfileVisited:
        EventName.HiveMQCloud__Navigation_completeProfileVisited,
      releaseNotesVisited:
        EventName.HiveMQCloud__Navigation_releaseNotesVisited,
      logout: EventName.HiveMQCloud__Navigation_logOutVisited,
      feedbackVisited: EventName.HiveMQCloud__Navigation_feedbackVisited,
      documentationViewVisited:
        EventName.HiveMQCloud__Navigation_documentationViewVisited,
      controlCenterViewVisited:
        EventName.HiveMQCloud__Navigation_controlCenterViewVisited,
      accessManagementViewVisited:
        EventName.HiveMQCloud__Navigation_accessManagementVisited,
      // Currently not called
      apiAccessViewVisited:
        EventName.HiveMQCloud__Navigation_apiAccessViewVisited,
      jwtAuthenticationViewVisited:
        EventName.HiveMQCloud__Navigation_jwtAuthenticationViewVisited,
      external: {
        edgeRepositoryVisited:
          EventName.HiveMQCloud__ExternalNavigation_edgeRepositoryVisited,
        edgeDocumentationVisited:
          EventName.HiveMQCloud__ExternalNavigation_edgeDocumentationVisited,
      },
    },
    general: {
      emailContactInitialized:
        EventName.HiveMQCloud__General_emailContactInitialized,
      documentationLinkVisited:
        EventName.HiveMQCloud__General_documentationLinkVisited,
      initialAccountDetailsAdded:
        EventName.HiveMQCloud__General_initialAccountDetailsAdded,
      handRaiserContactUsSelected:
        EventName.HiveMQCloud__General_handRaiserContactUsSelected,
      verificationEmailNotReceived:
        EventName.HiveMQCloud__General_verificationEmailNotReceived,
      supportLinkVisited: EventName.HiveMQCloud__General_supportLinkVisited,
      faqLinkVisited: EventName.HiveMQCloud__General_faqLinkVisited,
    },
    cluster: {
      mqttClientCredentialsAdded:
        EventName.HiveMQCloud__Cluster_mqttClientCredentialsAdded,
      // Currently not called
      mqttClientCredentialsDeleted:
        EventName.HiveMQCloud__Cluster_mqttClientCredentialsDeleted,
      cloudProviderSelected:
        EventName.HiveMQCloud__Cluster_cloudProviderSelected,
      created: EventName.HiveMQCloud__Cluster_clusterCreated,
      deleted: EventName.HiveMQCloud__Cluster_clusterDeleted,
      typeChosen: EventName.HiveMQCloud__Cluster_clusterTypeChosen,
      manage: EventName.HiveMQCloud__Cluster_manageButtonClicked,
      createNewButtonClicked:
        EventName.HiveMQCloud__Cluster_createNewButtonClicked,
      upgradeButtonClicked: EventName.HiveMQCloud__Cluster_upgradeButtonClicked,
      configurationStepVisited:
        EventName.HiveMQCloud__Cluster_configurationStepVisited,
      choosePackageStepVisited:
        EventName.HiveMQCloud__Cluster_choosePackageStepVisited,
      configurationStepChooseBillingPlanOption:
        EventName.HiveMQCloud__Cluster_configurationStepChooseBillingPlanOption,
      deleteDialogOpened: EventName.HiveMQCloud__Cluster_deleteDialogOpened,
      deleteDialog: {
        delete: EventName.HiveMQCloud__Cluster_deleteDialogDelete,
        cancel: EventName.HiveMQCloud__Cluster_deleteDialogCancel,
      },
      customDomainName: {
        bannerClicked:
          EventName.HiveMQCloud__DomainName_customDomainBannerClicked,
        bannerClosed:
          EventName.HiveMQCloud__DomainName_customDomainBannerClosed,
        customizeButtonClicked:
          EventName.HiveMQCloud__DomainName_customizeDomainNameClicked,
        created: EventName.HiveMQCloud__DomainName_customDomainNameCreated,
      },
    },
    client: {
      connected: EventName.HiveMQCloud__Client_clientConnected,
      disconnected: EventName.HiveMQCloud__Client_clientDisconnected,
      autoConnected: EventName.HiveMQCloud__Client_clientConnectedAutomatically,
      subscribed: EventName.HiveMQCloud__Client_subscribed,
      topicAdded: EventName.HiveMQCloud__Client_topicAdded,
      messagePublished: EventName.HiveMQCloud__Client_messagePublished,
    },
    payment: {
      //currently not called should be called on the external stripe interface somehow
      creditCardAdded: EventName.HiveMQCloud__Payment_creditCardAdded,
      billingAddressAdded: EventName.HiveMQCloud__Payment_billingAddressAdded,
      billingAddressUpdated:
        EventName.HiveMQCloud__Payment_billingAddressUpdated,
      billingDetailsOpened: EventName.HiveMQCloud__Payment_billingDetailsOpened,
    },
    integrations: {
      selected: EventName.HiveMQCloud__Integrations_integrationSelected,
      configSaved: EventName.HiveMQCloud__Integrations_configSaved,
      configRemoved: EventName.HiveMQCloud__Integrations_configRemoved,
      configError: EventName.HiveMQCloud__Integrations_configError,
    },
    release: {
      learnMoreSelected: EventName.HiveMQCloud__Release_learnMoreSelected,
      learnMoreClosed: EventName.HiveMQCloud__Release_learnMoreClosed,
    },
    acl: {
      createCredentialClicked:
        EventName.HiveMQCloud__ACL_createCredentialClicked,
      createPermissionClicked:
        EventName.HiveMQCloud__ACL_createPermissionClicked,
      deleteCredentialClicked:
        EventName.HiveMQCloud__ACL_deleteCredentialClicked,
      deletePermissionClicked:
        EventName.HiveMQCloud__ACL_deletePermissionClicked,
      deleteRoleClicked: EventName.HiveMQCloud__ACL_deleteRoleClicked,
      deletePermissionModalOpened:
        EventName.HiveMQCloud__ACL_deletePermissionModalOpened,
      deletePermissionModalOk:
        EventName.HiveMQCloud__ACL_deletePermissionModal_deleteOk,
      deletePermissionModalCancel:
        EventName.HiveMQCloud__ACL_deletePermissionModal_deletedCancel,
    },
    rbac: {
      createCredentialClicked:
        EventName.HiveMQCloud__RBAC_createCredentialClicked,
      createPermissionClicked:
        EventName.HiveMQCloud__RBAC_createPermissionClicked,
      createRoleClicked: EventName.HiveMQCloud__RBAC_createRoleClicked,
      deleteCredentialClicked:
        EventName.HiveMQCloud__RBAC_deleteCredentialClicked,
      deletePermissionClicked:
        EventName.HiveMQCloud__RBAC_deletePermissionClicked,
      deleteRoleClicked: EventName.HiveMQCloud__RBAC_deleteRoleClicked,
      updateRoleClicked: EventName.HiveMQCloud__RBAC_updateRoleClicked,
      updatePermissionClicked:
        EventName.HiveMQCloud__RBAC_updatePermissionClicked,
    },
    certificate: {
      add: EventName.HiveMQCloud__Certificate_add,
      delete: EventName.HiveMQCloud__Certificate_delete,
    },
    jwt: {
      addClaim: EventName.HiveMQCloud__JWT_addClaim,
      deleteClaim: EventName.HiveMQCloud__JWT_deleteClaim,
      saveConfiguration: EventName.HiveMQCloud__JWT_saveConfiguration,
      deleteConfiguration: EventName.HiveMQCloud__JWT_deleteConfiguration,
    },
    gettingStarted: {
      createCredential: EventName.HiveMQCloud__GettingStarted_createCredential,
      selectGuide: EventName.HiveMQCloud__GettingStarted_selectGuide,
      learningMaterial: EventName.HiveMQCloud__GettingStarted_learningMaterial,
    },
    apiToken: {
      visited: {
        index: EventName.HiveMQCloud__APIToken_indexPageVisited,
        details: EventName.HiveMQCloud__APIToken_detailsPageVisited,
        new: EventName.HiveMQCloud__APIToken_createPageVisited,
      },
      copyRestURl: EventName.HiveMQCloud__APIToken_copyRestURL,
      createToken: EventName.HiveMQCloud__APIToken_createToken,
      deleteToken: EventName.HiveMQCloud__APIToken_deleteToken,
    },
    userAndOrgMgmt: {
      pages: {
        settingsPage: EventName.HiveMQCloud__Organization_settingsPageVisited,
        membersPage: EventName.HiveMQCloud__Organization_membersPageVisited,
      },
      actions: {
        orgNameChange: EventName.HiveMQCloud__Organization_settingsNameUpdated,
        clickInviteMember:
          EventName.HiveMQCloud__Organization_membersAddClicked,
        submitInviteMember: EventName.HiveMQCloud__Organization_membersAdded,
        removeOrgMember: EventName.HiveMQCloud__Organization_membersRemoved,
      },
    },
  },
} as const

export enum EventProperty {
  'KAFKA_INTEGRATION' = 'kafka',
  'WEB_CLIENT' = 'web client',
  'AWS' = 'aws',
  'AZURE' = 'azure',
  'AzureEventBus' = 'azure eventbus',
  'GooglePubSub' = 'google pubsub',
  'Databricks' = 'databricks',
  'InfluxDb' = 'influxdb',
  'Prometheus' = 'Prometheus',
  'NodeRed' = 'NodeRed',
  'ThingsBoard' = 'thingsboard',
  'AWSKinesis' = 'aws kinesis',
  'MQTTBroker' = 'mqtt broker',
  'Splunk' = 'splunk',
  'SparkplugInfluxDb' = 'sparkplug to influxdb',
  'SQLDatabase' = 'sql database',
  'MongoDB' = 'mongo db',
  'SparkPlug' = 'spark plug',
}

export enum EventType {
  pageView = 'Page View',
  userAction = 'User Action',
}

export type EventProperties = {
  type?: EventType
  jwtConfig?: {}
  jwtClaim?: {}
  apiCredential?: string
  features?: string[]
  billingCycle?: string
  messagePrice?: string
  hourlyPrice?: string
  productTier?: string
  clusterType?: string
  clientType?: string
  billingOptionType?: 'hourly' | 'monthly'
  integrationType?: Omit<
    EventProperty,
    EventProperty.AWS | EventProperty.WEB_CLIENT | EventProperty.AZURE
  >
  providerType?: string
  invoicePeriod?: string
  termsOfService?: boolean
  clusterUuid?: string
  clusterName?: string
  integrationFlavour?: string
  searchQuery?: string
  resultFound?: boolean
  topicName?: string
  mqttAction?: 'publish' | 'subscribe' | 'unsubscribe'
  [index: string]: any
}

export function useTracking() {
  let mixpanel = useMixpanel()
  let launchDarkly = useLaunchDarkly()

  function trackEvent(name: EventName, properties?: EventProperties) {
    mixpanel.trackEvent(name, properties)
    launchDarkly.trackEvent(name, properties)
  }

  return {
    events,
    trackEvent,
  }
}
