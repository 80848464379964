import { useAsyncState } from '@vueuse/core'

import { $http } from '@/clients/consoleAxiosClient'

export function useLaunchDarkly() {
  function trackEvent(eventName: string, properties?: Record<string, any>) {
    try {
      window.launchDarkly.track(eventName, properties)
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * Backend method to fetch feature flags for current user.
   */
  function getFeatureFlags() {
    return useAsyncState<FeatureFlagResponse[]>(async () => {
      return $http
        .get(`featureflags/enabled`, {
          params: { email: window.launchDarkly.getContext().email },
        })
        .then((response) => response.data)
    }, [] as FeatureFlagResponse[])
  }

  return {
    trackEvent,
    getFeatureFlags,
  }
}

export interface FeatureFlagRequest {
  name: string
}

export interface FeatureFlagResponse {
  name: string
  isEnabled: boolean
}
