import { isDevelop, isProduction, isTesting } from '@/utils/environments'
import { GTMConfiguration } from '@/utils/tracking/googleTagManager/@types/googleTagManager.types'

function appendScriptElement(src: string): void {
  const scriptElement = window.document.createElement('script')
  scriptElement.src = src
  window.document.body.appendChild(scriptElement)
}

/**
 * Factory to create new instances for Google Tag Manager.
 * Important: It holds the state for all created and added managers.
 */
export function googleTagManagerFactory() {
  const environmentsWithCompletedSetup: string[] = []

  /**
   * Set up the given Google Tag Manager instance with the individual configuration and dataLayer name
   * @throws Error - When a second set up was triggered
   * @example
   * const factory = googleTagManagerFactory()
   *
   * export const marketingGoogleTagManager = factory.create<
   *   typeof YourDictionary
   * >({
   *   id: 'GTM-******',
   *   dataLayerName: '<CustomDataLayerName>',
   *   eventDictionary: YourDictionary,
   *   logger: gtmLogger.extend('<CustomLoggerName>'),
   * })!
   */
  function create<TDictionary, TEvent extends keyof TDictionary>(
    config: GTMConfiguration<TDictionary>
  ) {
    const isSetupCompleted = environmentsWithCompletedSetup.includes(config.id)

    if (isSetupCompleted) {
      if (!isTesting) {
        config.logger('Prevent setup of a duplicated GTM instance')
      }

      throw new Error('Set up already completed')
    }

    window[config.dataLayerName] = window[config.dataLayerName] || []

    const dataLayer = window[config.dataLayerName]

    dataLayer.push({
      linker: {
        domains: [
          'http://hivemq.com',
          'http://console.hivemq.com',
          'auth.hivemq.cloud',
        ],
        accept_incoming: true,
      },
    })

    environmentsWithCompletedSetup.push(config.id)

    dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })

    const url = new URL('https://www.googletagmanager.com/gtag/js')

    url.searchParams.set('id', config.id)
    /*
     * set a custom dataLayer name
     * @link https://developers.google.com/tag-platform/tag-manager/web/datalayer?hl=en#rename_the_data_layer
     */
    url.searchParams.set('l', config.dataLayerName)

    if (isProduction) {
      appendScriptElement(url.toString())
    }

    if (isDevelop) {
      config.logger('Non production environment prevent set up of GTM', {
        config,
        url: url.toString(),
      })
    }

    return {
      dataLayer,
      url,
      /**
       * Send events to individual Google Tag Manager instances
       * @param eventName - Name of the event provided by the event dictionary
       * @param eventPayload - Inferred payload of the event
       * @example Parameters are strictly typed and will be provided automatically
       * const factory = googleTagManagerFactory()
       * const gtm = factory.create(...options)
       *
       * gtm.send(GTMMarketingEvents.HiveMQCloud__General_anyViewVisited, { payloadEntry: 'hello' })
       */
      sendEvent<TPayload extends (typeof config)['eventDictionary'][TEvent]>(
        eventName: TEvent,
        eventPayload?: TPayload
      ): void {
        try {
          const payload = eventPayload || {}

          dataLayer.push({
            ...payload,
            event: eventName.toString(),
          })

          if (isDevelop) {
            config.logger('Add event to dataLayer', {
              environment: config.dataLayerName,
              event: {
                name: eventName,
                payload: eventPayload,
              },
            })
          }
        } catch (error) {
          if (isDevelop) {
            config.logger('Add event to dataLayer', {
              environment: config.dataLayerName,
              event: {
                name: eventName,
                payload: eventPayload,
              },
              error,
            })
          }
        }
      },
    }
  }

  return {
    create,
  }
}
