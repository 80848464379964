import { useAsyncState } from '@vueuse/core'

import { components } from '@/generated/openapi/apiary'

import { $httpV2 } from '@/clients/consoleAxiosClient'

export function useGetOrganizationMembers(orgId: string) {
  return useAsyncState<components['schemas']['User'][]>(
    () => {
      return $httpV2
        .get(`orgs/${orgId}/users`)
        .then((response) => response.data)
    },
    {} as components['schemas']['User'][]
  )
}

export function useChangeOrganisationName(
  orgId: string,
  change: { name: string }
) {
  return useAsyncState<{}>(
    () =>
      $httpV2
        .put(`orgs/${orgId}/name`, change)
        .then((response) => response.data),
    {}
  )
}

export function useAddOrgMember(orgId: string, member: Array<string>) {
  return useAsyncState<{}>(
    () =>
      $httpV2
        .post(`orgs/${orgId}/users`, member)
        .then((response) => response.data),
    {}
  )
}

export function useRemoveAdmin(orgId: string, userId: string) {
  return useAsyncState<{}>(
    () =>
      $httpV2
        .delete(`orgs/${orgId}/users/${encodeURIComponent(userId)}`)
        .then((response) => response.data),
    {}
  )
}
