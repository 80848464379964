<template>
  <v-tooltip
    v-for="({ title, icon, to, onClick, onClickEmitName, ...rest }, i) in items"
    :key="i"
    anchor="end"
    :disabled="!sidebarCollapsed"
  >
    <template #activator="{ props }">
      <v-list-item
        class="navigation__item"
        :class="isOrganizationItem(title)"
        :active="
          isClusterItem(title)
            ? isClusterCreateRoute(to?.name)
            : isActiveRoute(to?.name)
        "
        v-bind="{ ...props, ...rest }"
        :to="to"
        :aria-current="isActiveRoute(to?.name) ? 'page' : undefined"
        @click="
          () => {
            if (onClick) {
              return onClick()
            }
            $emit(onClickEmitName)
          }
        "
      >
        <template #prepend>
          <v-icon :icon="icon" class="navigation__icon" />
        </template>
        <template #append v-if="!sidebarCollapsed && isClusterItem(title)">
          <v-icon
            size="24"
            class="hover-icon"
            icon="mdi-plus-circle-outline"
            @click.stop.prevent="goToCreateCluster"
          />
        </template>
        <v-list-item-title
          :class="{
            'd-sr-only': sidebarCollapsed,
          }"
        >
          {{ title }}
        </v-list-item-title>
      </v-list-item>

      <v-list
        class="navigation__subgroup"
        v-if="!sidebarCollapsed && title === 'Clusters'"
        justify="space-between"
      >
        <ClusterNavigationSubItem
          v-for="subItem in clusterSubItems"
          :key="subItem.name"
          :data="subItem"
          :active="activeClusterId"
        />
      </v-list>
    </template>
    <span>{{ title }}</span>
  </v-tooltip>
</template>

<script lang="ts">
import {
  defineEmits,
  defineComponent,
  PropType,
  computed,
  ComputedRef,
  ref,
  watch,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { useClusterStore } from '@/store/clusterStore'

import { isActiveRoute } from '@/utils/location'
import {
  ClusterPackageConfigType,
  ClusterPackageName,
  getPackageForType,
} from '@/utils/packages'

import ClusterNavigationSubItem from '@/components/navigation/ClusterNavigationSubItem.vue'
import {
  ClusterNavigationSubItemData,
  navigationClusterItems,
  NavigationItem,
  organizationSidebarItems,
} from '@/components/navigation/NavigationItems'

import routeNames from '@/router/routeNames'

export default defineComponent({
  name: 'NavigationGroup',
  components: { ClusterNavigationSubItem },
  props: {
    sidebarCollapsed: {
      type: Boolean,
    },
    items: {
      default: () => {},
      type: Array as PropType<NavigationItem[]>,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const clusterStore = useClusterStore()
    const router = useRouter()
    const route = useRoute()

    const activeClusterId = ref<string | null>(null)

    watch(
      () => route.params,
      (newParams) => {
        activeClusterId.value = newParams?.clusterUuid as null | string
      }
    )

    function isClusterItem(title: string): boolean {
      return title === navigationClusterItems[0].title
    }

    function getOrganizationStyle(title: string): string {
      if (title === organizationSidebarItems[0].title) {
        return 'organization_item'
      }
      return ''
    }

    const clusterSubItems: ComputedRef<ClusterNavigationSubItemData[]> =
      computed(() => {
        let freeClusterNumber = 0
        let paygClusterNumber = 0
        return clusterStore.visibleClusters.map((cluster) => {
          const clusterPackage = getPackageForType(cluster.type)
          if (
            clusterPackage.routeParams.clusterType ===
            ClusterPackageConfigType.FREE
          ) {
            freeClusterNumber++
          }
          if (
            clusterPackage.routeParams.clusterType ===
            ClusterPackageConfigType.PAYG
          ) {
            paygClusterNumber++
          }
          const mappedItem: ClusterNavigationSubItemData = {
            name: remapClusterNames(
              cluster.name,
              clusterPackage.routeParams.clusterType,
              freeClusterNumber,
              paygClusterNumber
            ),
            clusterUuid: cluster.uuid,
            clusterType: remapClusterTypeNames(
              clusterPackage.routeParams.clusterType
            ),
            onClick: () => {
              router.push({
                name: 'cluster-details',
                params: {
                  clusterUuid: cluster.uuid,
                  clusterType: clusterPackage.routeParams.clusterType,
                },
              })
            },
          }
          return mappedItem
        })
      })

    function remapClusterTypeNames(clusterType: string): string {
      if (clusterType === ClusterPackageConfigType.PAYG) {
        return ClusterPackageName.SERVERLESS
      }

      if (clusterType === ClusterPackageConfigType.FREE) {
        return ClusterPackageName.SERVERLESS
      }

      if (clusterType === ClusterPackageConfigType.STARTER) {
        return ClusterPackageName.STARTER
      }

      return clusterType
    }

    function remapClusterNames(
      clusterName: string,
      clusterType: string,
      freeClusterNumber: number,
      paygClusterNumber: number
    ): string {
      if (clusterType === ClusterPackageConfigType.FREE) {
        return 'FREE' + ' #' + freeClusterNumber
      }

      if (clusterType === ClusterPackageConfigType.PAYG) {
        return 'PAYG' + ' #' + paygClusterNumber
      }

      return clusterName
    }

    function isClusterCreateRoute(toName: string) {
      return (
        router.currentRoute.value.name ===
          routeNames.authenticated.clusters.create || isActiveRoute(toName)
      )
    }

    function goToCreateCluster() {
      router.push({
        name: routeNames.authenticated.clusters.create,
      })
    }

    const emits = props.items?.map((item) => item.onClickEmitName)
    defineEmits(emits)
    return {
      t,
      isActiveRoute,
      emits,
      isClusterItem,
      isOrganizationItem: getOrganizationStyle,
      isClusterCreateRoute,
      clusterSubItems,
      goToCreateCluster,
      activeClusterId,
    }
  },
})
</script>

<style scoped lang="scss">
.organization_item {
  margin-top: 10px;
}

.navigation__item {
  grid-template-columns: 35px 1fr auto;
}

.hover-icon {
  color: var(--color-product-action-btn-text) !important;
  transition: color 0.2s ease;
}

.hover-icon:hover {
  color: black !important;
}

.navigation__icon {
  margin-inline-end: 0;
}

.navigation__subgroup {
  margin-left: 1.1875rem;
  padding-top: 0;
}

.v-list .v-list-item--nav {
  margin-bottom: 0;
}
.v-list-item--nav .v-list-item-title {
  font-size: 1rem !important;
}
</style>
