export const TIMEOUT_DURATION_SHORT_IN_MILLISECONDS = 100
export const TIMEOUT_DURATION_MEDIUM_IN_MILLISECONDS = 250
export const TIMEOUT_DURATION_LONG_IN_MILLISECONDS = 500
export const TIMEOUT_DURATION_EXTRA_LONG_IN_MILLISECONDS = 1_000

/**
 * Timing that is used for error messages and notifications
 * that might include human interactions
 */
export const DISAPPEARING_MESSAGE_DURATION_IN_MILLISECONDS = 3_000

export const REFRESH_DATA_TIMEOUT_EXTRA_SHORT_IN_MILLISECONDS = 4_000
export const REFRESH_DATA_TIMEOUT_SHORT_IN_MILLISECONDS = 10_000
export const REFRESH_DATA_TIMEOUT_IN_MILLISECONDS = 60_000
export const REFRESH_CLUSTER_USAGE_TIMEOUT_IN_MILLISECONDS = 300_000

export const ONE_SECOND = 1_000
export const ONE_MINUTE = 60_000
