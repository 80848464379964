import { useAsyncState } from '@vueuse/core'

import { httpV2Logger } from '@/utils/logger/logger'

import { paths } from '@/generated/openapi/apiary'

import { isProduction } from '../environments'

import { $http, $httpV2 } from '@/clients/consoleAxiosClient'

type FeatureFlag = {
  name: string
  isEnabled: boolean
}

export function useGetFeatureFlags() {
  const initialState: string[] = []

  return useAsyncState<string[]>(async () => {
    const v1FeatureFlagsRequest = $http.get<{
      items: Omit<FeatureFlag, 'isEnabled'>[]
    }>(`settings/features/enabled`)
    const v2FeatureFlagsRequest = $httpV2.get<FeatureFlag[]>('feature-flags')

    const featureFlags: string[] = []

    v1FeatureFlagsRequest
      .then((response) => {
        const enabledFeatureFlagNames = response.data.items.map(
          (featureFlag) => featureFlag.name
        )

        featureFlags.push(...enabledFeatureFlagNames)
      })
      .catch((error) => {
        return initialState
      })

    v2FeatureFlagsRequest
      .then((response) => {
        httpV2Logger('Completed feature-flag request')

        const enabledFeatureFlagNames = response.data
          .filter((featureFlag) => featureFlag.isEnabled)
          .map((featureFlag) => featureFlag.name)

        featureFlags.push(...enabledFeatureFlagNames)
      })
      .catch((error) => {
        return initialState
      })

    return Promise.allSettled([
      v1FeatureFlagsRequest,
      v2FeatureFlagsRequest,
    ]).then(() => featureFlags)
  }, initialState)
}
