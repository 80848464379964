import { defineStore } from 'pinia'

export type TopBarNavigationItem = {
  to: {
    name: string
    params?: { [key: string]: string }
  }
  meta?: {
    externalLink?: string
    controlCenter?: boolean
  }
  title: string
  // Part of the route path used to match the active route.
  // May come in handy for special cases where we have a single menu item but different child items belonging to that menu item.
  // Note: This is mostly poor menu and/or routing design and may be avoided in most cases!
  partiallyMatchRoutePath?: string
}

export const useNavigationStore = defineStore({
  id: 'navigation',
  state() {
    return {
      title: '',
      items: [] as ReadonlyArray<TopBarNavigationItem>,
    }
  },
  actions: {
    setTitle(title: string) {
      this.title = title
    },
    setMenuItems(items: TopBarNavigationItem[]) {
      this.items = [...items]
    },
    resetMenuItems() {
      this.items = []
    },
  },
  getters: {
    showNavigation(): boolean {
      return this.title.length > 0 || this.items.length > 0
    },
  },
})
