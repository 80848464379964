import { components } from '@/generated/openapi/apiary'

import { Cluster, ClusterState, ClusterType } from '@/@types/consoleApi.types'

function getPaaSClusterState(
  status: components['schemas']['ClusterStatus'] | undefined
): ClusterState {
  if (!status?.state) {
    return ClusterState.NEW
  }

  switch (status.state) {
    case 'RUNNING':
      return ClusterState.DONE
    case 'ERROR':
      return ClusterState.ERROR
    case 'DELETING':
      return ClusterState.DESTROYING
    case 'LOCKED':
      return ClusterState.LOCKED
    default:
      return ClusterState.CREATING
  }
}
export function apiV2ClusterTransformer(
  cluster: components['schemas']['Cluster']
): Cluster {
  const {
    status,
    id,
    plan,
    cloud_provider,
    port,
    endpoint,
    websocket_port,
    start_time,
    restapi_endpoint,
    clients_auth_method,
    mqtt_endpoint,
    name,
    location,
    api_gw_host,
    paymentType,
  } = cluster

  return {
    uuid: id!,
    name,
    state: getPaaSClusterState(status),
    createdAt: start_time!,
    // TODO: Bypass the current type system and name the clusterType PaaS
    type: plan! || ClusterType.STARTER,
    started: start_time!,
    stopped: '',
    url: endpoint!,
    providerType: cloud_provider!,
    isFreeTrial: false,
    version: '1.0.0',
    additionalUrls: [],
    paymentType,
    port,
    location,
    websocketPort: websocket_port,
    urls: {
      controlCenter: endpoint,
      rest: restapi_endpoint,
      clusterUrl: mqtt_endpoint,
    },
    clientAuthMethod: clients_auth_method!,
    api_gw_host: api_gw_host,
  }
}
