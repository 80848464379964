import { useRoute } from 'vue-router'

type IsActiveRouteOptions = {
  partiallyMatchRoutePath?: string
}

export function isActiveRoute(
  routeName: string,
  options = {} as IsActiveRouteOptions
): boolean {
  const activeRoute = useRoute()

  if (options.partiallyMatchRoutePath) {
    return (
      window.location.pathname === activeRoute.path &&
      activeRoute.path.includes(options.partiallyMatchRoutePath)
    )
  }

  return (
    window.location.pathname === activeRoute.path &&
    routeName === activeRoute.name
  )
}
