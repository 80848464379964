import { whenever } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed, reactive, ref } from 'vue'

import { useOrganizationStore } from './organizationStore'

import { EventHandlerSignature } from '@/@types/signatures.types'
import { Address, CreditCard } from '@/modules/billing/@types/payment.types'
import { useAccount } from '@/modules/billing/deprecated/useAccount'
import { useTracking } from '@/modules/tracking/hooks/useTracking'

export const useAccountStore = defineStore('account', () => {
  const { getAccount, getAddress, updateAddress, postAddress, postAccount } =
    useAccount()

  const { state: organizationState } = useOrganizationStore()
  const { execute: accountExecute, ...account } = getAccount()
  const { execute: addressExecute, ...address } = getAddress()
  const hasServerAddressCompleted = ref(false)

  const { trackEvent, events } = useTracking()

  const addressForm: Address = reactive({
    ...address.state.value,
  })

  async function loadAccount() {
    await Promise.allSettled([accountExecute()])
  }

  async function loadAddress(forceUpdate: boolean = false): Promise<void> {
    if (forceUpdate) {
      // Removing execute from the needed response
      // eslint-disable-next-line no-unused-vars
      const { execute: addressExecuteUpdate, ...addressUpdate } = getAddress({
        noCache: true,
      })

      whenever(addressUpdate.isReady, () => {
        Object.assign(address, addressUpdate)
        updateAddressForm(addressUpdate.state.value)
      })

      return
    }

    await addressExecute()
    updateAddressForm(address.state.value)
  }

  const hasAccount = computed(() => {
    // ⚠️ Fix for the stuck account state that is preventing
    // customer to move to the clusters page
    // https://hivemq.kanbanize.com/ctrl_board/72/cards/21534/details/
    return !!account.state.value.createdAt
  })

  function updateUserCredentials(event: CreditCard): void {
    Object.assign(addressForm, {
      firstName: event.firstName,
      lastName: event.lastName,
    })
  }

  function updateAddressForm(newAddress: Address) {
    Object.assign(addressForm, newAddress)
  }

  async function startCreateAddress() {
    const updateAddressState = postAddress(addressForm)

    whenever(updateAddressState.isReady, () => {
      loadAddress(true)
      trackEvent(events.hiveMQCloud.payment.billingAddressAdded)
    })
  }

  async function startUpdateAddress(): Promise<void> {
    return new Promise((resolve) => {
      const updateAddressState = updateAddress(addressForm)

      whenever(updateAddressState.isReady, () => {
        loadAddress(true)
        trackEvent(events.hiveMQCloud.payment.billingAddressUpdated)
        resolve(void 0)
      })
    })
  }

  async function submitAddressForm(options?: EventHandlerSignature) {
    options?.onStart?.()

    if (!hasServerAddressCompleted.value) {
      await startCreateAddress()
      return
    }

    await startUpdateAddress()

    options?.onSuccess?.()
  }

  /**
   * REF-2
   * ---
   * Provides the user's active orgId as their account OrgId
   * @see REF-1
   */
  const accountOrgId = computed(() => {
    return organizationState.activeOrg.data?.id
  })

  const OWNER = 'owner'

  const accountData = computed(() => {
    return {
      activeOrgId: organizationState.activeOrg.data?.id,
      isOrgOwner:
        organizationState.members.list
          .find((m) => m.email == account.state.value.email)
          ?.role?.toLowerCase() == OWNER,
    }
  })

  return {
    account,
    accountOrgId,
    accountData,
    address,
    addressForm,
    loadAccount,
    loadAddress,
    hasAccount,
    hasServerAddressCompleted,
    postAccount,
    updateUserCredentials,
    updateAddressForm,
    submitAddressForm,
  }
})
