export enum GTMMarketingEventName {
  HiveMQCloud__General_anyViewVisited = 'HiveMQCloud__General_anyViewVisited',
  HiveMQCloud__General_completeProfileViewVisited = 'HiveMQCloud__General_completeProfileViewVisited',
  HiveMQCloud__General_initialAccountDetailsAdded = 'HiveMQCloud__General_initialAccountDetailsAdded',
}

export const GTMMarketingEventsDictionary = {
  [GTMMarketingEventName.HiveMQCloud__General_anyViewVisited]: {
    path: '' as string,
  },
  [GTMMarketingEventName.HiveMQCloud__General_completeProfileViewVisited]: {},
  [GTMMarketingEventName.HiveMQCloud__General_initialAccountDetailsAdded]: {},
} as const
