import { HTTPMethod, useFetchCall } from '@app/helpers/useFetchCall'
import * as React from 'react'

import { components } from '@/generated/openapi/apiary'

import { Status } from '@/@types'
import { EventType, useTracking } from '@/modules/tracking/hooks/useTracking'

type State = {
  members: {
    status: Status
    list: components['schemas']['User'][]
    orgId: string
  }
  activeOrg: { status: Status; data: { name: string; id: string } }
  updateMemberStatus: Status
  orgs: {
    status: Status
    list: Array<{ id: string; name: string }>
  }
}

type Org = {
  storeState: State
  changeOrgName: (_newName: string) => void
  inviteTeamMembers: (_members: Array<string>) => void
  resetAddMembersStatus: () => void
  onActiveOrganizationChange: (_newOrgid: string) => void
  removeOrgAdmin: (_subjectId: string, _objectId: string) => void
}

const ACTIVE_ORG_ID = 'activeOrgId'
const initialState: State = {
  members: {
    status: Status.Rest,
    list: [],
    orgId: '',
  },
  activeOrg: { status: Status.Rest, data: { name: '', id: '' } },
  updateMemberStatus: Status.Rest,
  orgs: {
    status: Status.Rest,
    list: [],
  },
}

export const OrganizationContext = React.createContext<Org>({
  storeState: initialState,
} as Org)

export function OrganizationProvider({ children }: React.PropsWithChildren) {
  const { fetchCall } = useFetchCall()
  const { trackEvent, events } = useTracking()

  const [storeState, setStoreState] = React.useState<State>(initialState)

  React.useEffect(() => {
    if (storeState.orgs.status === Status.Rest) {
      setupOrganizationAccounts()
    }
    if (storeState.orgs.list.length) {
      // Get the organisation members of the activeOrg
      setupActiveOrg()
    }
  }, [storeState.orgs.list])

  React.useEffect(() => {
    if (storeState.activeOrg.data.id) {
      getOrgMembers()
    }
  }, [storeState.activeOrg.data.id])

  async function setupActiveOrg() {
    const localActiveOrgId = retrieveActiveOrg()
    const localActiveOrgExists = storeState.orgs.list.find(
      (org) => org.id === localActiveOrgId
    )

    setStoreState((state) => ({
      ...state,
      activeOrg: {
        ...state.activeOrg,
        data: localActiveOrgExists ?? storeState.orgs.list?.[0],
      },
    }))

    if (!localActiveOrgExists) {
      window.localStorage.setItem(ACTIVE_ORG_ID, storeState.orgs.list?.[0]?.id)
    }
  }

  async function getOrgMembers() {
    setStoreState((state) => ({
      ...state,
      members: { ...state.members, status: Status.Loading, list: [] },
    }))

    try {
      const activeOrgId = storeState.activeOrg.data.id
      const orgMembers = (await fetchCall({
        path: `orgs/${activeOrgId}/users`,
      })) as components['schemas']['User'][]

      setStoreState((state) => ({
        ...state,
        members: {
          status: Status.Success,
          list: orgMembers,
          orgId: activeOrgId,
        },
      }))
    } catch (error) {
      setStoreState((state) => ({
        ...state,
        members: { ...state.members, status: Status.Error },
      }))
    }
  }

  async function setupOrganizationAccounts() {
    setStoreState((state) => ({
      ...state,
      orgs: { ...state.orgs, status: Status.Loading },
    }))

    try {
      // get all available organisations
      const userOrgs = await fetchCall({ path: 'orgs' })

      const orgs = (userOrgs as components['schemas']['Organisation'][]).map(
        (org) => ({ id: org.org_id!, name: org.org_name! })
      )

      setStoreState((state) => ({
        ...state,
        orgs: { status: Status.Success, list: orgs },
      }))
    } catch (error) {
      setStoreState((state) => ({
        ...state,
        orgs: { ...state.orgs, status: Status.Error },
      }))
    }
  }

  function retrieveActiveOrg() {
    return window.localStorage.getItem(ACTIVE_ORG_ID)
  }

  function resetAddMembersStatus() {
    setStoreState((state) => ({ ...state, updateMemberStatus: Status.Rest }))
  }

  async function changeOrgName(newName: string) {
    const activeOrgId = storeState.activeOrg.data.id
    setStoreState((state) => ({
      ...state,
      activeOrg: { ...state.activeOrg, status: Status.Loading },
    }))

    trackEvent(events.hiveMQCloud.userAndOrgMgmt.actions.orgNameChange, {
      type: EventType.userAction,
    })

    try {
      await fetchCall({
        path: `orgs/${activeOrgId}/users`,
        method: HTTPMethod.PUT,
        payload: { name: newName },
      })

      let orgsList = storeState.orgs.list.map((org) => ({
        id: org.id,
        name: org.id === activeOrgId ? newName : org.name,
      }))

      setStoreState((state) => ({
        ...state,
        activeOrg: {
          status: Status.Success,
          data: { ...state.activeOrg.data, name: newName },
        },
        orgs: { status: state.orgs.status, list: orgsList },
      }))
    } catch (error) {
      setStoreState((state) => ({
        ...state,
        activeOrg: { ...state.activeOrg, status: Status.Error },
      }))
    }
  }

  async function removeOrgAdmin(subjectId: string, objectId: string) {
    const activeOrgId = storeState.activeOrg.data.id
    trackEvent(events.hiveMQCloud.userAndOrgMgmt.actions.removeOrgMember, {
      type: EventType.userAction,
    })
    setStoreState((state) => ({
      ...state,
      updateMemberStatus: Status.Loading,
    }))

    try {
      const remove = await fetchCall({
        path: `orgs/${activeOrgId}/users/${encodeURIComponent(objectId)}`,
        method: HTTPMethod.DELETE,
      })
      setStoreState((state) => ({
        ...state,
        updateMemberStatus: Status.Success,
      }))

      if (subjectId === objectId) {
        // user is removing themselve from this organization
        const newOrgs = storeState.orgs.list.filter(
          (org) => org.id !== activeOrgId
        )
        const newActiveOrg = newOrgs[0]

        setStoreState((state) => ({
          ...state,
          orgs: { status: Status.Success, list: newOrgs },
          activeOrg: { status: Status.Success, data: newActiveOrg },
        }))
        window.localStorage.removeItem(ACTIVE_ORG_ID)
        window.localStorage.setItem(ACTIVE_ORG_ID, newActiveOrg.id)
      } else {
        let updatedMembers = storeState.members.list.filter(
          (member) => member.id != objectId
        )
        setStoreState((state) => ({
          ...state,
          members: { ...state.members, list: updatedMembers },
        }))
      }
    } catch (error) {
      setStoreState((state) => ({
        ...state,
        updateMemberStatus: Status.Error,
      }))
    }
  }

  async function onActiveOrganizationChange(newOrgId: string) {
    const index = storeState.orgs.list.findIndex((org) => org.id === newOrgId)
    if (index !== -1) {
      setStoreState((state) => ({
        ...state,
        activeOrg: { status: Status.Success, data: state.orgs.list[index] },
      }))

      window.localStorage.setItem(ACTIVE_ORG_ID, storeState.activeOrg.data.id)
    }
  }

  async function inviteTeamMembers(members: Array<string>) {
    const activeOrgId = storeState.activeOrg.data.id
    trackEvent(events.hiveMQCloud.userAndOrgMgmt.actions.submitInviteMember, {
      type: EventType.userAction,
    })

    try {
      setStoreState((state) => ({
        ...state,
        updateMemberStatus: Status.Loading,
      }))

      await fetchCall({
        path: `orgs/${activeOrgId}/users`,
        method: HTTPMethod.POST,
        payload: members,
      })
      setStoreState((state) => ({
        ...state,
        updateMemberStatus: Status.Success,
      }))

      await getOrgMembers()
    } catch (error) {
      setStoreState((state) => ({
        ...state,
        updateMemberStatus: Status.Error,
      }))
    }
  }

  const value = React.useMemo(
    () => ({
      storeState,
      changeOrgName,
      inviteTeamMembers,
      resetAddMembersStatus,
      onActiveOrganizationChange,
      removeOrgAdmin,
    }),
    [
      storeState,
      changeOrgName,
      inviteTeamMembers,
      resetAddMembersStatus,
      onActiveOrganizationChange,
      removeOrgAdmin,
    ]
  )
  return (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  )
}
