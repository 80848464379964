import { defineStore } from 'pinia'

import { useAccountStore } from '@/store/accountStore'
import { useClusterStore } from '@/store/clusterStore'

import { useFeatureFlagStore } from './featureFlagStore'

export const useApplicationStore = defineStore('application', {
  state() {
    return {}
  },
  actions: {
    async initStores() {
      const accountStore = useAccountStore()
      const featureFlagStore = useFeatureFlagStore()

      await Promise.all([
        featureFlagStore.loadFeatureFlags(),
        accountStore.loadAccount(),
      ]).then(() => {
        // With the new store init logic we don't need to await
        useClusterStore()
      })

      return Promise.all([accountStore.loadAddress()])
    },
  },
})
