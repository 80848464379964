import { debug } from 'debug'

import { isDevelop, isStaging } from '@/utils/environments'

/**
 * https://www.npmjs.com/package/debug
 * Enable logging in browser
 * Execute this command in browser terminal to view logs for all instances
 * ```js
 * localStorage.debug = '*'
 * ```
 * Toggle the `Verbose` option in the browser terminal to display the logs
 */

export const consoleLogger = debug('console')

export const paymentLogger = consoleLogger.extend('payment')
export const routerLogger = consoleLogger.extend('router')
export const authenticationLogger = consoleLogger.extend('authentication')

export const integrationLogger = consoleLogger.extend('integration')
export const kinesisIntegrationLogger =
  isDevelop || isStaging ? integrationLogger.extend('kinesis') : () => void 0
export const gtmLogger = integrationLogger.extend('gtm')

export const httpLogger = consoleLogger.extend('http')
export const httpV1Logger = httpLogger.extend('v1')
export const httpV2Logger = httpLogger.extend('v2')
