import { ZoneContextManager } from '@opentelemetry/context-zone'
import {
  CompositePropagator,
  W3CBaggagePropagator,
  W3CTraceContextPropagator,
} from '@opentelemetry/core'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { registerInstrumentations } from '@opentelemetry/instrumentation'
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request'
import { Resource } from '@opentelemetry/resources'
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base'
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web'
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions'

function main() {
  if (!window.hmqcStaticConfig) {
    return
  }

  const { API_BASE_URL, TRACING_OTLP_URL } = window.hmqcStaticConfig

  if (!API_BASE_URL || !TRACING_OTLP_URL) {
    return
  }

  const API_URL = new URL(API_BASE_URL!)
  const HTTPS_PORT = 443
  const provider = new WebTracerProvider({
    resource: new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: 'console-frontend',
    }),
  })

  provider.addSpanProcessor(
    new BatchSpanProcessor(
      new OTLPTraceExporter({
        url: TRACING_OTLP_URL,
      }),
      {
        maxQueueSize: 1024,
        maxExportBatchSize: 20,
        scheduledDelayMillis: 5000,
        exportTimeoutMillis: 30000,
      }
    )
  )

  const contextManager = new ZoneContextManager()

  provider.register({
    contextManager,
    propagator: new CompositePropagator({
      propagators: [
        new W3CBaggagePropagator(),
        new W3CTraceContextPropagator(),
      ],
    }),
  })

  registerInstrumentations({
    tracerProvider: provider,
    instrumentations: [
      new XMLHttpRequestInstrumentation({
        ignoreUrls: [new RegExp(`https?://(?!${API_URL.host})([-\\w]*\\.?)+`)],
        propagateTraceHeaderCorsUrls: [new RegExp(`https://${API_URL.host}.*`)],
        applyCustomAttributesOnSpan(span) {
          span.setAttribute('server.address', API_URL.host)
          span.setAttribute('net.peer.name', API_URL.host)
          span.setAttribute('server.port', HTTPS_PORT)
        },
      }),
    ],
  })
}

main()
