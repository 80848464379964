export enum Status {
  Rest = 'rest',
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
}

export type PasswordVisible = {
  icon: 'mdi-eye-off'
  type: 'password'
}

export type PasswordInVisible = {
  icon: 'mdi-eye'
  type: 'text'
}

export type PasswordStatus = PasswordVisible | PasswordInVisible

export enum PasswordOption {
  Password = 'password',
  ConfirmPassword = 'confirmPassword',
}
