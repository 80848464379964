import { isProduction, isTesting } from '@/utils/environments'

export function trackPageView(pathname: string): void {
  trackHubSpotPageView(pathname)
}

function trackHubSpotPageView(pathname: string): void {
  if (isProduction || isTesting) {
    window._hsq.push(['setPath', pathname])
    window._hsq.push(['trackPageView'])
  }
}
