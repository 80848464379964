export const themeColorMap = {
  primary: 'primary',
  secondary: 'secondary',
  accent: 'accent',
  error: 'error',
  warning: 'warning',
  info: 'info',
  'info-text': 'info-text',
  success: 'success',
} as const

export type ThemeColors = typeof themeColorMap
export type ThemeColorName = keyof ThemeColors
export type ThemeColor = ThemeColors[ThemeColorName]
