<template>
  <VuetifyApp v-if="setupCompleted" data-testid="app-page">
    <MigrationWarning />
    <template v-if="!pageLayout.isLandingPage">
      <TopBarNavigation v-model="sidebarCollapsed" />
      <SideBarNavigation
        v-if="pageSettings.hasNavigationDrawer"
        :sidebar-collapsed="sidebarCollapsed"
      />
    </template>
    <v-main
      :class="{
        'is-sidebar-open': onboardingStore.isOpen,
      }"
    >
      <div v-if="pageLayout.isLandingPage">
        <router-view />
      </div>
      <v-container fluid>
        <v-row>
          <v-col
            v-if="pageLayout.isDefault"
            sm="12"
            md="10"
            lg="8"
            xl="7"
            xxl="6"
            class="mx-auto pt-8"
          >
            <router-view />
          </v-col>
          <v-col
            v-else-if="pageLayout.isDashboard"
            sm="12"
            md="11"
            class="mx-auto pt-8"
          >
            <router-view />
          </v-col>
        </v-row>
      </v-container>
      <VueAnnouncer class="d-sr-only" />
    </v-main>

    <SnackbarNotifications />
    <OnboardingSidebar
      :isOpen="onboardingStore.isOpen"
      :onClose="onboardingStore.onClose"
      :handleEvent="onboardingStore.handleEvent"
    />
  </VuetifyApp>
</template>

<script lang="ts">
import '@fontsource/roboto'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/900.css'
import '@fontsource/roboto-mono/400.css'
import '@fontsource/raleway/400.css'
import '@fontsource/raleway/500.css'
import '@fontsource/raleway/600.css'
import '@fontsource/raleway/700.css'
import '@fontsource/raleway/800.css'

import { useStorage, useTimeoutFn } from '@vueuse/core'
import { defineComponent, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { VApp as VuetifyApp } from 'vuetify/components/VApp'

import { useOnboardingStore } from '@/store/onboardingStore'

import { setupJiraWidget } from '@/utils/external/jiraWidget'
import { usePageLayout } from '@/utils/hooks/usePageLayout'
import { usePageSettings } from '@/utils/hooks/usePageSettings'

import SideBarNavigation from '@/components/navigation/SideBarNavigation.vue'
import TopBarNavigation from '@/components/navigation/TopBarNavigation.vue'
import SnackbarNotifications from '@/components/notifications/SnackbarNotifications.vue'

import OnboardingSidebar from '../app/organisms/OnboardingSidebar/OnboardingSidebar'

import { SUPPORT_LOCALES } from './i18n'

import MigrationWarning from '@/modules/warning/MigrationWarning.vue'

export default defineComponent({
  name: 'App',
  components: {
    MigrationWarning,
    SideBarNavigation,
    SnackbarNotifications,
    TopBarNavigation,
    VuetifyApp,
    OnboardingSidebar,
  },
  setup() {
    const pageLayout = usePageLayout()
    const pageSettings = usePageSettings()
    const onboardingStore = useOnboardingStore()
    const sidebarCollapsed = useStorage('hmq-sidebar-collapsed', false)

    const router = useRouter()
    const { t, locale } = useI18n({})
    const currentLocale = ref(locale.value)

    /**
     * This allows us to remove the setup flickering (navigation),
     * when users are trying to access private routes or their session ran out.
     */
    const setupCompleted = ref(false)
    const suspenseApplicationSetupTime = 500

    onMounted(() => {
      useTimeoutFn(
        () => {
          setupCompleted.value = true
        },
        suspenseApplicationSetupTime,
        { immediate: true }
      )

      setupJiraWidget()
    })

    watch(router.currentRoute, (route) => {
      currentLocale.value = route.params.locale as string
    })
    watch(currentLocale, (val) => {
      router.push({
        name: router.currentRoute.value.name!,
        params: { locale: val as any },
      })
    })

    return {
      pageLayout,
      pageSettings,
      sidebarCollapsed,
      setupCompleted,
      t,
      locale,
      currentLocale,
      onboardingStore,
      supportLocales: SUPPORT_LOCALES,
    }
  },
})
</script>

<style lang="scss">
@import './styles/gapSizes.scss';
@import './styles/variables.scss';
@import './styles/spacing.css';
@import './styles/utils.scss';
@import './styles/globals.scss';
@import './styles/document';
@import './styles/typography';

$lightBorder: 1px solid #c2c2c2;

.is-sidebar-open {
  margin-right: calc(var(--chakra-sizes-md) * 0.5);
  margin-left: calc(var(--chakra-sizes-md) * -0.5);
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
