const CUSTOM_STYLE_ID = 'support-custom-style'
const WIDGET_THEME = 'widget-theme'
const CUSTOM_STYLE_RETRY_INTERVAL_MS = 50
const CUSTOM_STYLE_MAX_TRIES = 40
const SUPPORT_WIDGET_IFRAME_ZINDEX = 1000

function addCustomStyleToIFrameHeader(widget: HTMLObjectElement) {
  const css = `
#react-root, #react-root>div {width: auto; pointer-events: auto;}
#button-container #help-button{background-color: #ffc000 !important;}
#button-container #help-button .help-button-contents {display: none;}
#button-container #help-button:before {content: url(${window.location.origin}/assets/icon-customer-support.svg); width: 30px; align-content: center;}
#button-container #help-button.text{padding: 0 10px; display: flex; gap: 1rem;}
#button-container #help-button.text>.help-button-contents{padding: 0;}
#search-container {width: auto; min-width: 300px;}
#search-container .form-container {width: auto;}
html, body {pointer-events: none;}
body {width:auto;height:auto;position:fixed;right:10;bottom:10;}
`

  // add the custom CSS to the iframe's header
  const style = widget.contentDocument!.createElement('style')
  style.type = 'text/css'
  style.id = CUSTOM_STYLE_ID
  style.append(css)
  widget.contentDocument!.head.appendChild(style)
}

function getIframe(): HTMLObjectElement {
  return document.getElementById('jsd-widget') as HTMLObjectElement
}

function getIframeDoc(): Document | undefined {
  const iframe = getIframe()
  return iframe.contentDocument || iframe.contentWindow?.document
}

function addIframeEventListener() {
  type NodeWithId = Node & {
    id: string
  }

  // Callback function to execute when mutations are observed
  function callback(
    mutationsList: MutationRecord[],
    _observer: MutationObserver
  ) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach((node: Node) => {
          if (
            ['button-container', 'search-container'].includes(
              (node as NodeWithId).id
            )
          ) {
            getIframe().classList.toggle('iframe-open')
          }
        })
      }
    }
  }

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback)

  // Configuration of the observer:
  const config = { childList: true, subtree: true }

  // Start observing the target node for configured mutations
  observer.observe(getIframeDoc()!.body, config)
}

function customizeSupportWidgetStyle() {
  let intervalId: ReturnType<typeof setInterval>
  let tryCounter = 0
  function addCustomCssIfReady() {
    const widget = document.getElementById('jsd-widget') as HTMLObjectElement
    widget.style.zIndex = `${SUPPORT_WIDGET_IFRAME_ZINDEX}`

    const children = widget.contentDocument!.head.children
    // if we're tried enough times, something went wrong.
    // Stop trying to apply the custom style
    if (tryCounter >= CUSTOM_STYLE_MAX_TRIES) {
      clearInterval(intervalId)
      return
    }

    if (children.length > 1) {
      const lastStyleId = children.item(children.length - 1)!.id
      // if the last style applied is already our custom style,
      // no need to reapply the style or keep trying
      if (lastStyleId === CUSTOM_STYLE_ID) {
        clearInterval(intervalId)
      }
      // if the last style applied is the widget theme,
      // then it's a good time to apply our custom CSS on top
      else if (lastStyleId === WIDGET_THEME) {
        addCustomStyleToIFrameHeader(widget)
        addIframeEventListener()
        clearInterval(intervalId)
      }
      // otherwise the page is not quite ready yet, some styles are still being loaded
      else {
        tryCounter++
      }
    } else {
      tryCounter++
    }
  }

  intervalId = setInterval(addCustomCssIfReady, CUSTOM_STYLE_RETRY_INTERVAL_MS)
}

export function setupJiraWidget() {
  const jiraScriptElement = window.document.createElement('script')
  jiraScriptElement.setAttribute('data-jsd-embedded', '')
  jiraScriptElement.setAttribute(
    'data-key',
    '9fa7ff87-5e09-40c9-b3ed-6e8e47d9bb5b'
  )
  jiraScriptElement.setAttribute(
    'data-base-url',
    'https://jsd-widget.atlassian.com'
  )
  jiraScriptElement.setAttribute(
    'src',
    'https://jsd-widget.atlassian.com/assets/embed.js'
  )
  jiraScriptElement.onload = () => {
    window.document.dispatchEvent(
      new Event('DOMContentLoaded', {
        bubbles: true,
        cancelable: true,
      })
    )

    customizeSupportWidgetStyle()
  }

  // load the script by adding it to the body
  window.document.body.appendChild(jiraScriptElement)
}
