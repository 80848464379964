<template>
  <v-navigation-drawer
    color="grey-lighten-4"
    :rail="sidebarCollapsed"
    aria-label="Main Menu"
    permanent
  >
    <div
      v-if="!sidebarCollapsed"
      class="organization_header app__bar_organisation"
    >
      Organizations
    </div>
    <v-tooltip :disabled="!sidebarCollapsed" anchor="end">
      <template #activator="{ props }">
        <v-list fill-height nav v-bind="{ ...props }">
          <OrganizationSelect
            :isCollapsed="sidebarCollapsed"
            :handleIconAction="onClickOrgs"
            :onActiveOrganisationChange="
              organizationStore.onActiveOrganisationChange
            "
            :organizations="organizationStore.state.orgs.list"
            :activeOrganization="organizationStore.state.activeOrg.data"
          />
        </v-list>
      </template>
      <span>Organizations</span>
    </v-tooltip>

    <v-list v-if="sidebarCollapsed" fill-height nav>
      <NavigationGroup
        :sidebar-collapsed="sidebarCollapsed"
        :items="[...navItems, ...billingItems]"
      />
    </v-list>
    <template v-else>
      <v-list fill-height nav>
        <v-list-subheader v-if="!sidebarCollapsed">Data</v-list-subheader>
        <NavigationGroup
          :sidebar-collapsed="sidebarCollapsed"
          :items="navItems"
        />
      </v-list>
      <v-list fill-height nav>
        <v-list-subheader v-if="!sidebarCollapsed">Billing</v-list-subheader>
        <NavigationGroup
          :sidebar-collapsed="sidebarCollapsed"
          :items="billingItems"
        />
      </v-list>
    </template>
    <template #append>
      <div class="mb-3 sidenav_lower" data-testid="sidenav-lower">
        <ReleaseNotification v-if="!sidebarCollapsed" />
        <v-list bottom nav class="sidenav_lower__list">
          <NavigationGroup
            :sidebar-collapsed="sidebarCollapsed"
            :items="
              !sidebarCollapsed && isVersionDifferent
                ? bottomActionItems
                : bottomActionItemsWithRelease
            "
            @click-feedback="handleShowFeedbackForm"
            @click-documentation="routeToDocumentation"
            @click-account="onProfileClick"
            @click-logout="onLogoutClick"
            @click-release="
              trackEvent(events.hiveMQCloud.navigation.releaseNotesVisited)
            "
            @click-onboarding="onOpen"
          />
        </v-list>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
import OrganizationSelect from '@app/molecules/OrganizationSelect/OrganizationSelect.tsx'
import { whenever } from '@vueuse/core'
import { CreditCard } from 'lucide-vue-next'
import { computed, defineComponent } from 'vue'
import { useRouter } from 'vue-router'

import { useAccountStore } from '@/store/accountStore'
import { useFeatureFlagStore } from '@/store/featureFlagStore'
import { useOnboardingStore } from '@/store/onboardingStore'
import { useOrganizationStore } from '@/store/organizationStore'

import { useRefiner } from '@/utils/hooks/useRefiner'
import { isActiveRoute } from '@/utils/location'

import NavigationGroup from '@/components/navigation/NavigationGroup.vue'
import {
  navigationActionItems,
  navigationActionItemsWithRelease,
  navigationClusterItems,
  NavigationItem,
  onboardingActionItems,
  organizationSidebarItems,
} from '@/components/navigation/NavigationItems'

import * as Data from '../../../app/pages/ReleaseNotes/ReleaseTemplate.md'

import { usePayment } from '@/modules/billing/deprecated/usePayment'
import ReleaseNotification from '@/modules/release/components/ReleaseNotification.vue'
import { isVersionDifferent } from '@/modules/release/utils/storage'
import { EventType, useTracking } from '@/modules/tracking/hooks/useTracking'
import { logoutAuth0User } from '@/plugins/auth0'
import routeNames from '@/router/routeNames'

export default defineComponent({
  name: 'SideBarNavigation',
  components: {
    ReleaseNotification,
    NavigationGroup,
    OrganizationSelect,
  },
  props: {
    sidebarCollapsed: {
      type: Boolean,
    },
  },
  setup() {
    const { trackEvent, events } = useTracking()
    const router = useRouter()
    const { showFeedbackForm } = useRefiner()
    const featureFlagStore = useFeatureFlagStore()
    const organizationStore = useOrganizationStore()
    const { postCostAndBilling } = usePayment()
    const { accountOrgId } = useAccountStore()
    const onboardingStore = useOnboardingStore()

    const routeToDocumentation = () => {
      trackEvent(events.hiveMQCloud.navigation.documentationViewVisited, {
        type: EventType.userAction,
      })
      window.open(
        'https://docs.hivemq.com/hivemq-cloud/introduction.html',
        '_blank'
      )
    }

    const onProfileClick = () => {
      return router.push({
        name: routeNames.authenticated.profile.index,
      })
    }

    const onLogoutClick = () => {
      trackEvent(events.hiveMQCloud.navigation.logout, {
        type: EventType.userAction,
      })
      logoutAuth0User()
    }

    const onClickOrgs = () => {
      return router.push({
        name: routeNames.authenticated.organisation.settings,
      })
    }

    function handleShowFeedbackForm() {
      trackEvent(events.hiveMQCloud.navigation.feedbackVisited, {
        type: EventType.userAction,
      })
      showFeedbackForm()
    }

    // @ts-expect-error - import does not provide types for the frontmatter elements
    const latestFeature = Data.latestFeature
    // @ts-expect-error - import does not provide types for the frontmatter elements
    const latestFeatureTags = Data.latestFeatureTags

    const releaseEventProperties = {
      latestFeature,
      latestFeatureTags,
    }

    const _navigationClusterItems = computed(() => {
      if (featureFlagStore.isRestApiEnabled) {
        return navigationClusterItems.filter(
          (item) => item.to?.name !== routeNames.authenticated.apiToken.index
        )
      }
      return navigationClusterItems
    })

    const navigationBillingItems: ReadonlyArray<NavigationItem> = [
      {
        icon: CreditCard,
        title: 'Billing & Payment',
        onClickEmitName: 'click-payment-history',
        'data-testid': 'sidebar-goto-billing',
        onClick: () => {
          const response = postCostAndBilling(accountOrgId!)
          whenever(response.isReady, () => {
            trackEvent(
              events.hiveMQCloud.navigation.paymentAndBillingViewVisited,
              {
                type: EventType.pageView,
              }
            )
            window.location.href = response.state.value.url!
          })
        },
      },
    ]

    const updatedBottomActionItemsWithRelease = computed(() => {
      if (featureFlagStore.isOrganisationManagementEnabled) {
        if (featureFlagStore.isOnboardingSidebarEnabled) {
          return [
            // ...onboardingActionItems,
            ...navigationActionItemsWithRelease,
          ]
        }
        return [...navigationActionItemsWithRelease]
      }
      return navigationActionItemsWithRelease
    })

    const updatedBottomActionItems = computed(() => {
      if (featureFlagStore.isOrganisationManagementEnabled) {
        if (featureFlagStore.isOnboardingSidebarEnabled) {
          return [
            // ...onboardingActionItems,
            ...navigationActionItems,
          ]
        }
        return [...navigationActionItems]
      }
      return navigationActionItems
    })

    return {
      onOpen: onboardingStore.onOpen,
      navItems: _navigationClusterItems,
      billingItems: navigationBillingItems,
      bottomActionItems: updatedBottomActionItems,
      bottomActionItemsWithRelease: updatedBottomActionItemsWithRelease,
      isVersionDifferent,
      isActiveRoute,
      showFeedbackForm,
      handleShowFeedbackForm,
      routeToDocumentation,
      trackEvent,
      events,
      releaseEventProperties,
      onProfileClick,
      onClickOrgs,
      onLogoutClick,
      organizationSidebarItems,
      organizationStore,
    }
  },
})
</script>

<style lang="scss">
.app__bar_organisation {
  padding-left: 1.1rem;
  padding-right: 1.1rem;
  padding-top: 1.1rem;
}

.organization_header {
  font-size: 0.85rem;
  color: rgba(var(--v-theme-on-surface), var(--v-medium-emphasis-opacity));
}

.v-navigation-drawer__append {
  height: 40%;
}

.v-list--nav .v-list-subheader {
  font-size: 0.85rem;
}

.sidenav_lower {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sidenav_lower__list {
  margin-top: auto;
}
</style>
