<template>
  <v-app-bar
    id="top-bar-navigation"
    v-if="navigationStore.showNavigation"
    color="grey-lighten-2"
    elevation="0"
    border
  >
    <div class="d-flex align-center ml-1">
      <v-btn
        v-if="pageSettings.hasNavigationDrawer"
        @click.stop="sidebarCollapsed = !sidebarCollapsed"
        :aria-expanded="!sidebarCollapsed"
        variant="plain"
        icon
      >
        <span class="d-sr-only">Toggle main menu</span>
        <v-icon v-if="sidebarCollapsed">mdi-menu</v-icon>
        <v-icon v-else>mdi-menu-open</v-icon>
      </v-btn>

      <a href="/" aria-label="Home">
        <img
          src="@/assets/05-hivemq-cloud-icon.svg"
          alt=""
          width="36"
          height="36"
        />
      </a>
    </div>

    <v-app-bar-title class="app__bar-title">
      <div class="d-inline-flex align-center justify-start navigation-title">
        <h1
          id="nav-page-title"
          class="text-h5"
          data-testid="top-bar-navigation-title"
        >
          {{ navigationStore.title }}
        </h1>
      </div>
    </v-app-bar-title>

    <nav
      v-if="navigationStore.items.length > 0"
      aria-label="Secondary Menu for selected Cluster"
    >
      <ul class="d-inline-block">
        <li
          v-for="{
            partiallyMatchRoutePath = '',
            ...item
          } in filteredNavigation"
          :key="item.title"
          class="mx-2 d-inline-block"
          :data-testid="item.to.name"
        >
          <v-btn
            v-if="
              item.meta?.externalLink &&
              item.meta?.controlCenter &&
              accountStore.accountData.isOrgOwner
            "
            :href="item.meta.externalLink"
            variant="text"
            external
            target="_blank"
            rel="noopener noreferrer"
            @click="
              trackEvent(
                events.hiveMQCloud.navigation.controlCenterViewVisited,
                {
                  type: EventType.userAction,
                }
              )
            "
          >
            {{ t('cluster.overview.actions.controlCenter') }}
          </v-btn>
          <v-btn
            v-if="!(item.meta?.externalLink && item.meta?.controlCenter)"
            :to="{
              name: item.to.name,
            }"
            :title="item.title"
            :active="isActiveRoute(item.to.name, { partiallyMatchRoutePath })"
            :aria-current="
              isActiveRoute(item.to.name, { partiallyMatchRoutePath })
                ? 'page'
                : undefined
            "
            data-testid="top-bar-navigation-item"
            variant="text"
          >
            {{ item.title }}

            <template v-if="item.title === 'Integrations'">
              <v-chip color="success" variant="flat" size="x-small" class="ml-2"
                >New</v-chip
              >
            </template>
          </v-btn>
        </li>
      </ul>
    </nav>
  </v-app-bar>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { useAccountStore } from '@/store/accountStore'
import { useClusterStore } from '@/store/clusterStore'
import { useFeatureFlagStore } from '@/store/featureFlagStore'
import { useNavigationStore } from '@/store/navigationStore'

import { usePageSettings } from '@/utils/hooks/usePageSettings'
import { isActiveRoute } from '@/utils/location'
import { isRunning } from '@/utils/packages'

import { Cluster } from '@/@types/consoleApi.types'
import { EventType, useTracking } from '@/modules/tracking/hooks/useTracking'
import routeNames from '@/router/routeNames'

type Props = { modelValue: boolean }

const props = defineProps<Props>()

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()
const { events, trackEvent } = useTracking()
const navigationStore = useNavigationStore()
const router = useRouter()
const route = useRoute()
const clusterStore = useClusterStore()
const filterNavigation = ref(true)
const pageSettings = usePageSettings()
const featureFlagStore = useFeatureFlagStore()
const accountStore = useAccountStore()
// TODO: utilize specific cluster store
const cluster = ref<Cluster | null>(null)

const sidebarCollapsed = computed({
  get(): boolean {
    return props.modelValue
  },
  set(visible: boolean | undefined) {
    emit('update:modelValue', visible)
  },
})

type NestedValues<T> = T extends object ? NestedValues<T[keyof T]> : T
type RouteNames = NestedValues<typeof routeNames>

/**
 * Recalculate the navigation based on the current status of the cluster
 */
function handleRouteChange() {
  filterNavigation.value = true

  const clusterUuid = router?.currentRoute?.value?.params?.['clusterUuid']

  if (!clusterUuid) {
    filterNavigation.value = false
    return
  }

  cluster.value =
    clusterStore.clusters.find((cluster) => cluster.uuid === clusterUuid) ??
    null

  if (cluster.value) {
    filterNavigation.value = !isRunning(cluster.value.state)
  }
}

const filteredNavigation = computed(() => {
  if (!filterNavigation.value) {
    return navigationStore.items
  }

  return navigationStore.items.filter((item) => {
    return item.to.name === routeNames.authenticated.clusters.details.index
  })
})

/**
 * Update the top level navigation if the customer jumps to another section of the application
 */
watch(router.currentRoute, handleRouteChange)
/**
 * Update the top level navigation if the status of a given cluster changes
 * E.g. from starting -> running
 */
watch(() => cluster.value?.state, handleRouteChange)
onMounted(handleRouteChange)
</script>

<style lang="scss" scoped>
.navigation-title {
  vertical-align: middle;
}

.app__bar-title {
  margin-right: 1.5rem;
}

h1 {
  margin: 0;
}
</style>
