import { useAsyncState } from '@vueuse/core'
import { reactive, ref, Ref } from 'vue'

import { useAccountStore } from '@/store/accountStore'
import { useOrganizationStore } from '@/store/organizationStore'

import {
  HmqErrorResponse,
  HmqListResponseData,
  HmqResponse,
  toHmqResponseError,
  toInitialHmqResponseState,
} from '@/utils/http/httpResponseUtils'

import type { paths } from '@/generated/openapi/apiary'

import { Cluster } from '@/@types/consoleApi.types'
import { $http, $httpV2 } from '@/clients/consoleAxiosClient'
import { apiV2ClusterTransformer } from '@/modules/cluster/adapters/paasAdapter'

type V2ClustersResponse =
  paths['/api/v2/orgs/{orgId}/clusters']['get']['responses']['200']['content']['application/json']

export function useGetClusters(initialState: Ref<Cluster[]> = ref([])) {
  const accountStore = useAccountStore()

  return useAsyncState<
    | HmqResponse<HmqListResponseData<Cluster>>
    | HmqErrorResponse<HmqListResponseData<Cluster>>
  >(
    async () => {
      const organizationStore = useOrganizationStore()
      const activeOrgId = organizationStore.state.activeOrg.data.id

      const v1Request = $http.get<HmqListResponseData<Cluster>>('clusters')
      const v2Request = $httpV2.get<V2ClustersResponse>(
        `orgs/${accountStore.accountOrgId}/clusters`
      )

      const combinedResponse: HmqResponse<HmqListResponseData<Cluster>> =
        reactive({
          data: { items: [] as Cluster[] },
          status: 0,
          statusText: '',
        })

      function populateResponseStatus(
        response: HmqResponse<HmqListResponseData<Cluster> | V2ClustersResponse>
      ) {
        if (combinedResponse.status === 0) {
          combinedResponse.status = response.status
        }

        if (combinedResponse.statusText === '') {
          combinedResponse.statusText = response.statusText
        }
      }

      /**
       * IMPORTANT
       * ---
       * We need to handle the requests individually as both are running in parallel and
       * the V2 cluster items need to be transformed to the V1 data signature.
       *
       * We add the V1 cluster data to the bottom part of the returned items.
       * The V2 cluster data will be added to the first half of combinedResponse.
       *
       * The outcome is prevention of jumping clusters in the overview page,
       * since response items are placed in the same pattern.
       */

      v1Request.then((response) => {
        combinedResponse.data.items = [
          ...combinedResponse.data.items,
          ...response.data.items.map((item) => ({
            ...item,
            urls: { clusterUrl: item.url },
          })),
        ]

        populateResponseStatus(response)
      })

      v2Request.then((response) => {
        const transformedClusterItems = response.data.map(
          apiV2ClusterTransformer
        )

        combinedResponse.data.items = [
          ...transformedClusterItems,
          ...combinedResponse.data.items,
        ]

        populateResponseStatus(response)
      })

      return Promise.allSettled([v1Request, v2Request]).then(
        () => combinedResponse
      )
    },
    toInitialHmqResponseState({ items: initialState.value }),
    { resetOnExecute: false }
  )
}
