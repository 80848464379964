const STORE_KEY_PREPEND = 'UTM_STORE'
const STORE_KEY_DELIMITER = '__'

export const UTMParameter = {
  SOURCE: 'utm_source',
  MEDIUM: 'utm_medium',
  CAMPAIGN: 'utm_campaign',
  CONTENT: 'utm_content',
} as const

type UTMParameterValue = typeof UTMParameter[keyof typeof UTMParameter]

const allowedKeys = Object.values(UTMParameter)

export function getStorageKey(key: UTMParameterValue): string {
  return STORE_KEY_PREPEND + STORE_KEY_DELIMITER + key
}

export function saveUTMParameters(url: URL) {
  allowedKeys.forEach((key) => {
    const keyValue = url.searchParams.get(key)

    if (keyValue === null) {
      return
    }

    window.localStorage.setItem(getStorageKey(key), keyValue)
  })
}

export function flushUTMParameters() {
  allowedKeys.forEach((key) => {
    window.localStorage.removeItem(getStorageKey(key))
  })
}

export function getUTMParameter(key: UTMParameterValue) {
  return window.localStorage.getItem(getStorageKey(key))
}
